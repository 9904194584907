<div class="col-12 pt-8">
  <section class="voucher">
    <div class="px-3">
      <back-router></back-router>
    </div>

    <div class="lg:col-11 col-12 mx-auto conteudo-principal">
      <div class="lg:pt-6 col-12 mx-auto">
        <h2 class="border-bottom-1 border-400 text-center xl:text-left">
          Recomendados para você
        </h2>
        <div class="lg:col-12">
          <div
            class="grid lg:gap-4 flex-column md:flex-row mt-3 justify-content-center lg:px-0 lg:mx-6 px-5 lg:px-0"
          >
            @if(recommendedCupons?.recommendedVoucher?.length > 0){ @for (item
            of recommendedCupons?.recommendedVoucher.slice(0, 2); track $index)
            {
            <div class="col-12 lg:col-3">
              <div
                class="mb-3 md:mb-0 mr-0 md:mr-2 md:pr-5 flex flex-column flex-grow-1"
                (click)="validateClickAndNavigate(item?.id)"
              >
                <div class="bg-card-header text-center p-3 cursor-pointer">
                  <div
                    class="flex align-items-center surface-border cursor-pointer py-3 gap-2"
                  >
                    <div
                      class="ml-3 align-content-center"
                      style="width: 75px; height: 65px"
                    >
                      <img
                        [src]="item?.category?.urlImage"
                        style="width: 60px; height: 60px"
                      />
                    </div>
                    <div class="flex flex-column align-items-start">
                      <span class="text-xl text-white font-medium mb-1">{{
                        item?.category?.name
                      }}</span>
                    </div>
                  </div>
                </div>
                <div
                  class="p-4 flex flex-column flex-grow-1 shadow-1 border-round-bottom-3xl shadow-none border-none card-content cursor-pointer area-card-branco hover:shadow-5"
                  style="min-height: 188px"
                >
                  <div
                    class="font-medium text-base font-light line-height-2 mb-4 pr-1 text-black-alpha-70"
                  >
                    {{ item?.description | slice : 0 : 75
                    }}{{ item?.description?.length > 75 ? "..." : "" }}
                  </div>

                  <ul class="list-none p-0 m-0 mb-5 text-bluegray-600">
                    <li class="flex align-items-center mb-1">
                      <span>Valor:</span>
                      <span class="font-bold"
                        >&nbsp;{{ formatCurrency(item?.value) }}</span
                      >
                    </li>
                    <li class="flex align-items-center">
                      <span>Válido até:&nbsp;</span>
                      <span class="font-bold">{{
                        calculateFutureDate(item?.validity)
                      }}</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            } }@else {
            @if(!isMobile){
            <div class="col-12 lg:col-6 text-center align-content-center">
              <p class="text-xl font-medium text-center">
                Faça um exame para recomendarmos cupons para você! 😉
              </p>
            </div>
            } } @if(sponsoredCupons.length > 0 && !isMobile){
            <div class="col-12 lg:col-4">
              <p-carousel
                [value]="sponsoredCupons"
                [numVisible]="1"
                [numScroll]="1"
                [circular]="true"
                [responsiveOptions]="responsiveOptions"
              >
                <ng-template let-sponsor pTemplate="item">
                  <div
                    class="mb-3 p-1 md:mb-0 mr-0 md:mr-2 md:pr-5 flex flex-column flex-grow-1"
                    (click)="validateClickAndNavigate(sponsor?.id)"
                  >
                    <div class="bg-card-header text-center p-3 cursor-pointer">
                      <div
                        class="flex align-items-center surface-border cursor-pointer py-2"
                      >
                        <div class="round-black">
                          <img
                            [src]="sponsor?.category?.urlImage"
                            class="mx-3 my-1"
                            style="width: 60px; height: 60px"
                          />
                        </div>
                        <div class="flex flex-column align-items-start">
                          <span class="text-xl text-white font-medium mb-1">{{
                            sponsor?.partner?.category?.name
                          }}</span>
                          <span
                            class="text-xs text-white font-medium mb-1 text-left"
                            >{{ sponsor?.partner?.name }}</span
                          >
                        </div>
                      </div>
                    </div>
                    <div
                      class="p-4 flex flex-column flex-grow-1 justify-content-between shadow-1 border-round-bottom-3xl shadow-none border-none card-content cursor-pointer area-card-branco hover:shadow-5"
                      style="min-height: 190px"
                    >
                      <div
                        class="font-medium text-base font-light line-height-2 mb-4 pr-1 text-black-alpha-70"
                      >
                        {{  sponsor?.description | slice : 0 : 140}}{{ sponsor?.description?.length > 140 ? "..." : "" }}
                      </div>

                      <ul class="list-none p-0 m-0 mb-5 text-bluegray-600">
                        <li class="flex align-items-center mb-1">
                          <span>Valor:</span>
                          <span class="font-bold"
                            >&nbsp;{{ formatCurrency(sponsor?.value) }}</span
                          >
                        </li>
                        <li class="flex align-items-center">
                          <span>Válido até:&nbsp;</span>
                          <span class="font-bold">{{
                            calculateFutureDate(sponsor?.validity)
                          }}</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </ng-template>
              </p-carousel>
            </div>
            }
          </div>
          @if(isMobile && sponsoredCupons.length > 0){
          <div class="col-12 lg:col-4">
            <p-carousel
              [value]="sponsoredCupons"
              [numVisible]="1"
              [numScroll]="1"
              [circular]="true"
              [responsiveOptions]="responsiveOptions"
            >
              <ng-template let-sponsor pTemplate="item">
                <div
                  class="mb-3 p-1 md:mb-0 mr-0 md:mr-2 md:pr-5 flex flex-column flex-grow-1"
                  (click)="validateClickAndNavigate(sponsor?.id)"
                >
                  <div class="bg-card-header text-center p-3 cursor-pointer">
                    <div
                      class="flex align-items-center surface-border cursor-pointer py-2"
                    >
                      <div class="round-black">
                        <img
                          [src]="sponsor?.category?.urlImage"
                          class="mx-3 my-1"
                          style="width: 60px; height: 60px"
                        />
                      </div>
                      <div class="flex flex-column align-items-start">
                        <span class="text-xl text-white font-medium mb-1">{{
                          sponsor?.partner?.category?.name
                        }}</span>
                        <span
                          class="text-xs text-white font-medium mb-1 text-left"
                          >{{ sponsor?.partner?.name }}</span
                        >
                      </div>
                    </div>
                  </div>
                  <div
                    class="p-4 flex flex-column flex-grow-1 justify-content-between shadow-1 border-round-bottom-3xl shadow-none border-none card-content cursor-pointer area-card-branco hover:shadow-5"
                    style="min-height: 190px"
                  >
                    <div
                      class="font-medium text-base font-light line-height-2 mb-4 pr-1 text-black-alpha-70"
                    >
                      {{ sponsor?.description | slice : 0 : 80
                      }}{{ sponsor?.description?.length > 80 ? "..." : "" }}
                    </div>

                    <ul class="list-none p-0 m-0 mb-5 text-bluegray-600">
                      <li class="flex align-items-center mb-1">
                        <span>Valor:</span>
                        <span class="font-bold"
                          >&nbsp;{{ formatCurrency(sponsor?.value) }}</span
                        >
                      </li>
                      <li class="flex align-items-center">
                        <span>Válido até:&nbsp;</span>
                        <span class="font-bold">{{
                          calculateFutureDate(sponsor?.validity)
                        }}</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </ng-template>
            </p-carousel>
          </div>
          }
        </div>
      </div>
      <h2 class="border-bottom-1 border-400 text-center xl:text-left">
        Todos os cupons
      </h2>
      <div
        class="grid lg:gap-6 flex-column md:flex-row mt-3 justify-content-center lg:px-0 lg:mx-6 px-5 lg:px-0"
      >
        @for (item of vouchers.slice(0, 3); track $index) {
        <div class="col-12 lg:col-3">
          <div
            class="mb-3 md:mb-0 mr-0 md:mr-2 md:pr-5 flex flex-column flex-grow-1"
            (click)="validateClickAndNavigate(item?.id)"
          >
            <div class="bg-card-header text-center p-3 cursor-pointer">
              <div
                class="flex align-items-center surface-border cursor-pointer py-3 gap-2"
              >
                <div
                  class="ml-3 align-content-center"
                  style="width: 75px; height: 65px"
                >
                  <img
                    [src]="item?.category?.urlImage"
                    style="width: 60px; height: 60px"
                  />
                </div>
                <div class="flex flex-column align-items-start">
                  <span class="text-xl text-white font-medium mb-1">{{
                    item?.category?.name
                  }}</span>
                </div>
              </div>
            </div>
            <div
              class="p-4 flex flex-column flex-grow-1 shadow-1 border-round-bottom-3xl shadow-none border-none card-content cursor-pointer area-card-branco hover:shadow-5"
            >
              <div
                class="font-medium text-base font-light line-height-2 mb-4 pr-1 text-black-alpha-70"
              >
                {{ item?.description | slice : 0 : 75
                }}{{ item?.description?.length > 75 ? "..." : "" }}
              </div>

              <ul class="list-none p-0 m-0 mb-5 text-bluegray-600">
                <li class="flex align-items-center mb-1">
                  <span>Valor:</span>
                  <span class="font-bold"
                    >&nbsp;{{ formatCurrency(item?.value) }}</span
                  >
                </li>
                <li class="flex align-items-center">
                  <span>Válido até:&nbsp;</span>
                  <span class="font-bold">{{
                    calculateFutureDate(item?.validity)
                  }}</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
        }
      </div>
      <div class="col-10 lg:col-3 mx-auto my-6 lg:my-8">
        <p-button
          label="Veja todos os cupons"
          severity="success"
          styleClass="p-button-save lg:mr-3"
          (onClick)="showingAllCupons = true"
          routerLink="/cupons/todos-cupons"
        ></p-button>
      </div>
    </div>
  </section>
</div>
