<!-- <router-outlet ></router-outlet>  -->
<div [@triggerName]="prepareRoute(outlet)">
  <router-outlet #outlet="outlet"></router-outlet>
  <div *ngIf="isLoading" class="loading">
    <p-progressSpinner styleClass="w-4rem h-4rem" strokeWidth="8" animationDuration=".5s"></p-progressSpinner>
  </div>

  <p-toast key="block2" [preventOpenDuplicates]="true" [position]="'center'" class="p-button-danger" [life]="99999">
    <ng-template let-message pTemplate="message">
      <div class="flex align-items-start flex-1 align-self-center bg-white p-3">
        <div class="mx-3 bg-white w-full">
          <span class="text-xl font-medium text-900">{{message.summary}}</span>
          <p class="text-600 mt-2 mb-3">{{message.detail}}</p>
          @if (message.redirect) {
          <button type="button" pButton pRipple class="p-button-sm mr-2 w-full" label="OK" (click)="clear('block2')"
            routerLink="{{message.redirect || ''}}"></button>
          }
          @else {
          <button type="button" pButton pRipple class="p-button-sm mr-2 w-full" label="OK" (click)="clear('block2')"></button>
          }
        </div>
      </div>
    </ng-template>
  </p-toast>

  <p-toast key="feedback" [style]="{width: '30rem'}" [preventOpenDuplicates]="true" class="p-button-danger"></p-toast>
  <img src="" alt="">
</div>

