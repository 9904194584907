<div
  class="col-12 flex flex-column align-items-center justify-content-start pt-8 bg-cadastro"
  [ngClass]="{ '': !isEdit, 'h-screen': isEdit }"
>
 <p-scrollPanel styleClass="custombar2">
  <div class="lg:col-5 mx-auto py-5">
    <div class="text-center mb-3">
      @if(!isEdit){
      <div class="text-title mb-3 font-light font-normal">
        Complete seu cadastro
      </div>
      }@else {
      <div class="text-title mb-3 font-light font-normal">
        Edite seu cadastro
      </div>
      }
    </div>
    <form [formGroup]="validateForm">
      <div class="col-12 mx-auto grid">
        <div class="field col-12">
          <label aria-required="true" class="block mb-2">Nome</label>
          <input
            id="name"
            type="text"
            placeholder="Nome"
            pInputText
            class="w-full md:w-full"
            formControlName="name"
          />
          <div
            *ngIf="
              validateForm.get('name')?.invalid &&
              validateForm.get('name')?.touched
            "
            class="alert alert-danger"
          >
            Campo obrigatório.
          </div>
        </div>

        <div class="field col-12">
          <label class="block mb-2">Email</label>
          <input
            id="email"
            type="text"
            placeholder="email@email.com"
            pInputText
            class="w-full md:w-full"
            formControlName="email"
            [disabled]="isEdit"
            (input)="toLowerCase($event)"
          />
          <div
            *ngIf="
              validateForm.get('email')?.invalid &&
              validateForm.get('email')?.touched
            "
            class="alert alert-danger"
          >
            E-mail incorreto.
          </div>
        </div>
        <div class="field col-12 lg:col-12">
          <label class="block mb-2">CPF</label>
          <p-inputMask
            mask="999.999.999-99"
            id="cpf"
            formControlName="cpf"
            placeholder="000.000.000-00"
            type="text"
            class="w-full md:w-full mb-3"
          ></p-inputMask>
          <div
            *ngIf="
              validateForm.get('cpf')?.invalid &&
              validateForm.get('cpf')?.touched
            "
            class="alert alert-danger"
          >
            CPF inválido.
          </div>
        </div>
        <div class="field col-12 lg:col-6">
          <label class="block mb-2">Celular</label>
          <p-inputMask
            mask="(99)99999-9999"
            id="phoneNumber"
            formControlName="phoneNumber"
            placeholder="(00)00000-0000"
            type="text"
            class="w-full md:w-full mb-3"
          ></p-inputMask>
          <div
            *ngIf="
              validateForm.get('phoneNumber')?.invalid &&
              validateForm.get('phoneNumber')?.touched
            "
            class="alert alert-danger"
          >
            Campo obrigatório.
          </div>
        </div>
        <div class="field col-12 lg:col-6">
          <label class="block mb-2">Data de nascimento</label>
          <p-inputMask
            mask="99/99/9999"
            id="birthDate"
            formControlName="birthDate"
            placeholder="dd/mm/yyyy"
            type="text"
            class="w-full md:w-full mb-3"
          ></p-inputMask>
          <div
            *ngIf="
              validateForm.get('birthDate')?.invalid &&
              validateForm.get('birthDate')?.touched
            "
            class="alert alert-danger"
          >
            Data inválida
          </div>
        </div>

        <div class="field col-12" *ngIf="!isEdit">
          <label class="block mb-2">Digite uma senha</label>
          <p-password
            id="password1"
            formControlName="password"
            placeholder="*****"
            [toggleMask]="true"
            [feedback]="false"
            inputStyleClass="w-full md:w-full"
          ></p-password>
          <div
            *ngIf="
              validateForm.get('password')?.invalid &&
              validateForm.get('password')?.touched
            "
            class="alert alert-danger"
          >
            A senha deve ter no mínimo 6 caracteres, pelo menos uma letra
            maiúscula, uma letra minúscula e um número e um símbolo.
          </div>
        </div>

        <div class="field col-12" *ngIf="!isEdit">
          <label class="block mb-2"
            >Repetir senha (As senhas devem ser iguais)
          </label>
          <p-password
            id="password1"
            formControlName="confirmarSenha"
            placeholder="*****"
            [toggleMask]="true"
            styleClass="mb-5"
            [feedback]="false"
            inputStyleClass="w-full md:w-full"
          ></p-password>
          <div
            *ngIf="
              validateForm.get('confirmarSenha')?.value !=
                validateForm.get('password')?.value &&
              validateForm.get('confirmarSenha')?.touched
            "
            class="alert alert-danger"
          >
            As senhas devem ser iguais
          </div>
        </div>

        <div
          class="flex align-items-center justify-content-end mb-3"
          *ngIf="!isEdit"
        >
          <p-checkbox
            formControlName="termsAndConditions"
            id="rememberme1"
            [binary]="true"
            styleClass="mr-2"
          ></p-checkbox>
          <label class="text-policy"
            >Ao se cadastrar, você declara que aceita nossa
            <span class="cursor-pointer"><a class="link_politicas" href="/assets/file/Política de Privacidade.pdf" target="_blank">Política de Privacidade</a> e <a class="link_politicas" href="/assets/file/Termos de Uso.pdf" target="_blank">Termos de Uso</a></span></label
          >
        </div>

        <div class="col-12 text-center gap-3 lg:gap-6 flex flex-column md:flex-row lg:pt-6">
          <p-button
            label="Voltar"
            severity="success"
            styleClass="p-button-save lg:ml-3"
            icon="pi pi-angle-double-left"
            *ngIf="!isEdit"
            [routerLink]="['/login']"
          ></p-button>
          <p-button
            [disabled]="validateForm.invalid"
            type="submit"
            label="Salvar"
            severity="help"
            styleClass="p-button-after my-3-3 lg:my-0"
            *ngIf="!isEdit"
            (onClick)="submitForm()"
          ></p-button>
          @if(isEdit){
          <div class="pt-4 gap-3 w-full flex flex-column-reverse md:flex-row">
            <p-button
              label="Voltar"
              severity="success"
              styleClass="p-button-save lg:mr-3"
              icon="pi pi-angle-double-left"
              [routerLink]="['/minha-conta']"
            ></p-button>
            <p-button
              type="button"
              label="Salvar edições"
              severity="success"
              styleClass="p-button-after w-full"
              (onClick)="submitForm()"
            ></p-button>
          </div>
          }
        </div>
      </div>
    </form>
  </div>
 </p-scrollPanel>
</div>
