@if (!isMobile) {
<div class="col-12 pt-8 overflow-hidden">
  <div
    class="bg-cover bg-center bg-no-repeat border-round h-20rem w-full"
    style="background-image: url('/assets/img/bg/im_painel_cupom.jpg')"
  >
    <div class="backRouter p-4 flex vertical-align-baseline">
      <i class="pi pi-chevron-circle-left"> </i>
      <a [routerLink]="['/cupons']">
        <span>Voltar</span>
      </a>
    </div>
    <div class="col-8 mx-auto">
      <h1 class="text-white text-center text-7xl">
        {{ detalheCupom?.category?.name }}
      </h1>
    </div>
  </div>
</div>
}
<div class="lg:col-12 lg:pt-0 pt-8">
  <div class="backRouter lg:hidden p-4 py-2 flex vertical-align-baseline">
    <i class="pi pi-chevron-circle-left"> </i>
    <a [routerLink]="['/cupons']">
      <span>Voltar</span>
    </a>
  </div>
  <section class="voucher">
    <div class="col-12 lg:col-9 px-4 pr-4 lg:px-6 pt-0 pb-8 voucher-consulta">
      <div class="border-round-3xl bg-white shadow-3 p-0 lg:p-6">
        @if(detalheCupom?.partner){
        <div>
          <h2 class="border-bottom-1 border-400 py-2 text-center xl:text-left">
            Parceiro responsável
          </h2>
          <p class="text-left px-3">{{ detalheCupom.partner.name }}</p>
        </div>
        }
        <div>
          <h2
            class="border-bottom-1 border-400 pb-2 text-center pt-4 xl:text-left"
          >
            Tipo do serviço
          </h2>
          <p class="text-center lg:text-left px-4">
            {{ detalheCupom.serviceType }}
          </p>
        </div>

        <div>
          <h2 class="border-bottom-1 border-400 pb-2 text-center xl:text-left">
            Valores
          </h2>
          <ul class="pl-6">
            <li>
              Valor do cupom:&nbsp;{{ formatCurrency(detalheCupom?.value) }}
            </li>
            <li>
              Valor do serviço com o uso do cupom:&nbsp;
              {{ formatCurrency(detalheCupom?.valueServiceVoucher) }}
            </li>
          </ul>
        </div>

        <div class="pb-3 pt-2">
          <h2
            class="border-bottom-1 border-400 pb-2 px-4 mt-1 text-center xl:text-left"
          >
            Descrição
          </h2>
          <p class="px-4 lg:text-left text-center">{{ detalheCupom.description}}</p>
          <p class="px-4 lg:text-left text-center lg:hidden">{{ detalheCupom.description | slice : 0 : 80 }}...</p>
          <div class="col-10 mx-auto">
            <p-button
              label="Leia toda a descrição..."
              [outlined]="true"
              [rounded]="true"
              severity="secondary"
              styleClass="p-button-outlined p-button-strong w-full lg:hidden lg:w-auto"
              (onClick)="openDialog(0)"
            ></p-button>
          </div>

          <p-dialog
            header="Descrição do Cupom"
            [(visible)]="visibleDescrip"
            [modal]="true"
            [style]="{ width: '50vw' }"
            [breakpoints]="{ '960px': '75vw' }"
            [draggable]="false"
            [resizable]="false"
            class="col-12"
          >
            <p class="m-0">
              {{ detalheCupom.description }}
            </p>
          </p-dialog>
        </div>

        <div class="text-center">
          @if(detalheCupom?.partner){
          <h2
            class="border-bottom-1 px-4 border-400 pb-2 text-center xl:text-left"
          >
            Parceiros
          </h2>
          <div
            class="flex flex-column shadow-1 border-circle p-4 inline-flex justify-content-center align-items-center mb-4 cursor-pointer"
            [style]="{ background: '#78CDD1' }" (click)="goToCategory(detalheCupom.category)"
          >
            <img
              [src]="detalheCupom?.partner?.urlPhoto"
              alt="icone-parceiros"
              style="width: 100%; height: 75px;"
            />
            <p class="text-white font-semibold mb-0">ver parceiros</p>
          </div>
          }

          <h2 class="border-bottom-1 border-400 pb-2 px-4 mt-1 lg:text-left">
            Regras de utilização
          </h2>
          <p class="lg:text-left px-4 pb-1">
            {{ detalheCupom.utilizationRules }}
          </p>
          <p class="lg:text-left px-4 pb-1 lg:hidden">
            {{ detalheCupom.utilizationRules | slice : 0 : 80 }}...
          </p>
          <div class="col-10 mx-auto">
            <p-button
              label="Leia toda as regras de utilização..."
              [outlined]="true"
              [rounded]="true"
              severity="secondary"
              styleClass="p-button-outlined p-button-strong w-full lg:hidden lg:w-auto"
              (onClick)="openDialog(1)"
            ></p-button>
          </div>
          <p-dialog
            header="Regras de utilização"
            [(visible)]="VisibleUtilRules"
            [modal]="true"
            [style]="{ width: '50vw' }"
            [breakpoints]="{ '960px': '75vw' }"
            [draggable]="false"
            [resizable]="false"
            class="col-12"
          >
            <p class="m-0">
              {{ detalheCupom.utilizationRules }}
            </p>
          </p-dialog>

          <h2
            class="border-bottom-1 border-400 pb-2 pt-2 px-4 text-center xl:text-left"
          >
            Regras de cancelamento
          </h2>
          <p class="lg:text-left px-4 pb-1">
            {{ detalheCupom?.cancelRules}}
          </p>
          <p class="lg:text-left px-4 pb-1 lg:hidden">
            {{ detalheCupom?.cancelRules | slice : 0 : 80 }}...
          </p>
          <div class="col-10 mx-auto">
            <p-button
              label="Leia toda as regras de cancelamento..."
              [outlined]="true"
              [rounded]="true"
              severity="secondary"
              styleClass="p-button-outlined p-button-strong w-full lg:hidden lg:w-auto"
              (onClick)="openDialog(2)"
            ></p-button>
          </div>
          <p-dialog
            header="Regras de Cancelamento"
            [(visible)]="visibleCancRules"
            [modal]="true"
            [style]="{ width: '50vw' }"
            [breakpoints]="{ '960px': '75vw' }"
            [draggable]="false"
            [resizable]="false"
            class="col-12"
          >
            <p class="m-0">
              {{ detalheCupom.cancelRules }}
            </p>
          </p-dialog>
        </div>
      </div>
    </div>

    <div
      class="bottom-0 md:bottom-auto border-round-xl"
      [ngClass]="{ 'fixed-container': !isMobile, fixed: isMobile }"
    >
      <p-button
        label="Total"
        icon="pi pi-shopping-cart"
        iconPos="right"
        styleClass="w-full lg:border-round-top-xl btn-shopping"
      ></p-button>
      <p-card styleClass="card-carrinho">
        <div class="flex flex-column">
          <div class="grid align-items-baseline">
            <div class="mx-4 w-full">
            <div class="col-6 flex w-full pt-2 justify-content-between">
              <div>
                <span class="value-voucher">{{
                  formatCurrency(valorAtual)
                }}</span>
              </div>
              <div>
                <p-inputNumber
                  [(ngModel)]="quantidadeCupons"
                  [showButtons]="true"
                  buttonLayout="horizontal"
                  spinnerMode="vertical"
                  inputId="vertical"
                  [min]="1"
                  (onInput)="onChangeValue($event.value)"
                  decrementButtonClass="p-button-secondary"
                  incrementButtonClass="p-button-secondary"
                  incrementButtonIcon="pi pi-plus"
                  decrementButtonIcon="pi pi-minus"
                ></p-inputNumber>
              </div>
            </div>
          </div>

            <div class="col-8 fixed-box">
              <p class="font-medium inline-flex my-2 text-xl">
                Válido até:<span class="font-bold px-1">{{
                  calculateFutureDate(detalheCupom.validity)
                }}</span>
              </p>
            </div>

            <div class="col-12">
              <p-button
                label="Adquirir"
                severity="success"
                styleClass="p-button-save w-full border-round-3xl"
                [routerLink]="'/carrinho'"
                (onClick)="addCarrinho()"
              ></p-button>
            </div>
          </div>
        </div>
      </p-card>
    </div>
  </section>
</div>
