<div class="bg-login h-full lg:h-screen pt-8">
  <div
    class="col-12 lg:col-5 relative lg:absolute card-login py-8 px-3 lg:py-0"
  >
    <p-card>
      <div class="pb-3 px-1" style="border-radius: 53px">
        <div class="pt-3 pb-4">
          <p class="font-normal text-3xl lg:text-base text-white text-center">
            Digite sua nova senha
          </p>
        </div>
        <form [formGroup]="validateForm" (ngSubmit)="submitForm()">
          <div class="col-12" >
            <label class="block">Digite sua nova senha</label>
            <p-password
              id="password1"
              formControlName="password"
              placeholder="*****"
              [toggleMask]="true"
              styleClass="mb-1"
              [feedback]="false"
              inputStyleClass="w-full md:w-full"
              [style]="{ width: '100%' }"
            ></p-password>
            <div
              *ngIf="
                validateForm.get('password')?.invalid &&
                validateForm.get('password')?.touched
              "
              class="alert alert-danger"
            >
              A senha deve ter no mínimo 6 caracteres, pelo menos uma letra
              maiúscula, uma letra minúscula e um número e um símbolo.
            </div>
          </div>
          <div class="col-12">
            <label class="block mb-2">Repita sua nova senha </label>
            <p-password
              id="password1"
              formControlName="confirmarSenha"
              placeholder="*****"
              [toggleMask]="true"
              styleClass="mb-2"
              [feedback]="false"
              inputStyleClass=""
              [style]="{ width: '100%' }"
            ></p-password>
            <div
              *ngIf="
                validateForm.get('confirmarSenha')?.value !=
                  validateForm.get('password')?.value &&
                validateForm.get('confirmarSenha')?.touched
              "
              class="alert alert-danger"
            >
              As senhas devem ser iguais
            </div>
          </div>
          <div class="flex flex-column gap-2">
            <p-button
              [disabled]="validateForm.invalid"
              label="Salvar"
              styleClass="p-button-login"
              type="button"
              class="w-full pt-2"
              (onClick)="submitForm()"
              (keydown.enter)="submitForm()"
            ></p-button>
          </div>
        </form>
      </div>
    </p-card>
  </div>

  <div class="lg:hidden">
    <img
      src="assets/img/bg/diversidade.jpg"
      alt="grupo-pessoas"
      class="w-full"
    />
  </div>
</div>

<!-- Modal de confirmação -->
<p-dialog
  header="Nova senha cadastrada com sucesso!"
  [(visible)]="confirmModal"
  [modal]="true"
  [draggable]="false"
  [resizable]="false"
  [style]="{ width: '93%' }"
>
  <div class="flex flex-column pt-2">
    <div class="pb-4 pt-2">
      <p class="m-0 text-xl text-black-alpha-90 font-medium">
        Agora você pode fazer login novamente.
      </p>
    </div>

    <div class="col-12">
      <p-button
        label="Ir para a tela de login"
        (click)="confirmModal = false"
        severity="help"
        type="button"
        styleClass="p-button-save p-button-ok border-round-xl"
        routerLink="/login"
      ></p-button>
    </div>
  </div>
</p-dialog>
