import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AddressService {
  private apiUrl = environment.apiUrl;

  constructor(private http: HttpClient) { }

  postCustomerAddress(body: any): Promise<any> {
    return this.http.post<any>(`${this.apiUrl}/customer/address`, body).toPromise();
  }

  putCustomerAddress(body: any, id:string): Promise<any> {
    return this.http.put<any>(`${this.apiUrl}/customer/address/${id}`, body).toPromise();
  }

  getCustomerAddress(): Promise<any> {
    return this.http.get<any>(`${this.apiUrl}/customer/address`).toPromise();
  }

  public cep(cep: string) {
    return this.http.get(`https://viacep.com.br/ws/${cep}/json/`);
  }

}
