import { LoadingService } from './../../../services/loading.service';
import { CommonModule } from '@angular/common';
import { Component, ElementRef, Input, ViewChild } from '@angular/core';

// PrimeNG
import { ButtonModule } from 'primeng/button';
import { PasswordModule } from 'primeng/password';
import { CheckboxModule } from 'primeng/checkbox';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { DropdownModule } from 'primeng/dropdown';
import { InputMaskModule } from 'primeng/inputmask';
import { AddressService } from '../../../services/address.service';
import { Router, RouterModule } from '@angular/router';

@Component({
  selector: 'app-address',
  standalone: true,
  imports: [
    CommonModule,
    ButtonModule,
    CheckboxModule,
    FormsModule,
    PasswordModule,
    DropdownModule,
    ReactiveFormsModule,
    InputMaskModule,
    RouterModule,
  ],
  templateUrl: './address.component.html',
  styleUrl: './address.component.scss',
})
export class AddressComponent {
  password!: string;
  isEdit: boolean;
  idAddress: string = '';

  validateForm!: FormGroup;

  constructor(
    private addressService: AddressService,
    private fb: FormBuilder,
    private router: Router,
    private loadingService: LoadingService
  ) {
    this.createValidateForm();
  }
  @ViewChild('numeroInput') numeroInputElement!: ElementRef<HTMLInputElement>;

  async ngOnInit() {
    this.loadingService.show();
    try {
      if (typeof window !== 'undefined') {
        const url = window.location.href;
        const ad = await this.getCustomerAddress();
        if (ad.length > 0) {
          this.idAddress = ad[0].id;
          this.validateForm.patchValue(ad[0]);
        }
        if (url.includes('editar')) {
          this.isEdit = true;
        } else {
          this.isEdit = false;
        }
      }
    } catch (error) {
      console.error('Erro ao obter o endereço do cliente:', error);
      // Trate o erro conforme necessário (exibindo uma mensagem de erro, etc.)
    } finally {
      this.loadingService.hide();
    }
  }

  createValidateForm() {
    this.validateForm = this.fb.group({
      zipCode: ['', [Validators.required]],
      street: ['', [Validators.required]],
      number: ['', [Validators.required]],
      complement: [''],
      neighborhood: ['', [Validators.required]],
      city: ['', [Validators.required]],
      state: ['', [Validators.required]],
    });
  }

  async submitForm() {
    this.loadingService.show();
    this.validateForm
      .get('number')
      ?.patchValue(this.validateForm.get('number')?.value.toString());
    if (this.validateForm.valid && !this.isEdit) {
      try {
        const resp = await this.postCustomerAddress(this.validateForm.value);
        if (resp) {
          this.loadingService.hide();
          this.router.navigate(['/dados-complementares']);
        } else {
          this.loadingService.hide();
          this.loadingService.notify('Erro ao cadastrar', 'Erro interno');
        }
      } catch (error) {}

      Object.values(this.validateForm.controls).forEach((control) => {
        if (control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });
        }
      });
    } else if (this.isEdit) {
      try {
        await this.addressService
          .putCustomerAddress(this.validateForm.value, this.idAddress)
          .then((resp) => {
            this.loadingService.hide();
            this.router.navigate(['/minha-conta']);
          })
          .catch((error) => {
            this.loadingService.notify('Erro ao atualizar', 'Erro interno');
            this.loadingService.hide();
          });
      } catch (error) {
        this.loadingService.hide();
        console.log(error);
      }
    }
  }

  async getCustomerAddress() {
    try {
      return await this.addressService.getCustomerAddress();
    } catch (error) {
      this.loadingService.notify('Erro', 'Erro ao obter endereço do cliente.');
      this.router.navigate(['/minha-conta']);
    }
  }

  async postCustomerAddress(body: any) {
    try {
      return await this.addressService.postCustomerAddress({
        ...body,
        customerId: JSON.parse(sessionStorage.getItem('token') || '{}')
          ?.customer?.id,
      });
    } catch (error) {
      console.log(error);
    }
  }


  async getEnderecoCEP() {
    let cep = this.validateForm.get('zipCode')?.value;

    const res: any = await this.addressService.cep(cep).toPromise();
    this.validateForm.patchValue({
      state: res?.uf,
      city: res?.localidade,
      street: res?.logradouro,
      neighborhood: res?.bairro,
    });
    this.numeroInputElement.nativeElement.focus();
  }
}
