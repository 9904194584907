import { join } from 'node:path';
import { LoadingService } from './../../../services/loading.service';
import { AnamneseService } from './../../../services/anamnese.service';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

// PrimeNg
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { RadioButtonModule } from 'primeng/radiobutton';
import { ProgressBarModule } from 'primeng/progressbar';

import { Component, Input, input } from '@angular/core';
import { Router, RouterLink } from '@angular/router';
import { CustomerService } from '../../../services/customer.service';
import { CheckboxModule } from 'primeng/checkbox';
import { ScrollTopModule } from 'primeng/scrolltop';
import { ScrollerModule } from 'primeng/scroller';

@Component({
  selector: 'app-anamnese',
  standalone: true,
  imports: [
    CommonModule,
    DialogModule,
    ButtonModule,
    FormsModule,
    RadioButtonModule,
    RouterLink,
    ProgressBarModule,
    CheckboxModule,
    ScrollTopModule,
    ScrollerModule,
  ],
  templateUrl: './anamnese.component.html',
  styleUrl: './anamnese.component.scss',
})
export class AnamneseComponent {
  anamneses: any = [];
  respostasAnamneses: any = [];
  selectedItem: any = [];
  itens: any = [];
  nextItemValue: string | null | undefined = null;
  currentPage: number = 1;

  confirmModal: boolean = false;
  showModal: boolean = false;

  currentItemIndex: number = 0;
  bgColors: string[] = ['#7670B3', '#BFCD31', '#C77EB5'];
  currentBgColor: number = 0;
  bgImgClass: string = '';
  anamnesesPairs: any = [];
  currentItemPercentage: number = 0;
  isEdit: boolean = false;
  customer: any = {};
  isMobile: boolean = false;
  selectedItem2: any = [];

  constructor(
    private anamneseService: AnamneseService,
    private loadingService: LoadingService,
    private router: Router,
    private customerService: CustomerService
  ) {
    this.setBgImgClass();
    window.addEventListener('resize', () => {
      this.setBgImgClass();
    });
  }

  setBgImgClass() {
    if (window.innerWidth < 768) {
      this.bgImgClass = 'bg-img-mobile';
    } else {
      this.bgImgClass = 'bg-img-mobile';
    }
  }


  async ngOnInit() {
    this.loadingService.show();
    this.customer = await this.getCustomer();
    this.anamneses = await this.getAnamneseQuestionsV2(1);
    this.respostasAnamneses = await this.getAnamneseAtivas();
    this.formatAnswers();
    for (let index = 0; index < this.anamneses.items.length; index++) {
      let resp = this.findItem(this.anamneses.items[index])?.answer;
      this.selectedItem[index] = resp;
    }
    this.selectedItem[0] = this.findItem(this.anamneses.items[0])?.answer;
    const url = window.location.href;
    if (url.includes('editar')) {
      this.isEdit = true;
    } else {
      this.isEdit = false;
    }
    this.checkIfMobile();
    this.loadingService.hide();
  }

  formatAnswers() {
    this.respostasAnamneses.forEach((element) => {
      element.itemAnamnesisId = element.itemAnamnesis.id;
    });
  }

  validarBotao(items: any) {
    let isArray = Array.isArray(items[1]);
    if (!isArray) {
      return items.some(
        (item: any) => item == '' || item === undefined || item === null
      );
    } else {
      let item = items[1].join(',');
      if (item !== '' && items[0] !== '') {
        return false;
      } else {
        return true;
      }
    }
  }

  onCheckboxChange(index: number) {
    const selectedItems = this.selectedItem[index] || [];

    if (selectedItems.includes('Nenhuma')) {
      this.disableOtherItems(index);
    } else {
      this.enableAllItems(index);
    }
  }

  onRadioChange(categoria: any, index: number) {
    if (categoria == 'Já foi submetido a cirurgia de catarata?') {
      if (this.selectedItem[0] == 'Não') {
        this.selectedItem[1] = 'Nenhum';
      } else {
        this.selectedItem[1] = '';
      }
    } else if (categoria == 'Qual olho?') {
      if (this.selectedItem[1] !== 'Nenhum' && this.selectedItem[0] == 'Não') {
        this.selectedItem[0] = 'Sim';
      } else if (
        this.selectedItem[1] == 'Nenhum' &&
        this.selectedItem[0] == 'Sim'
      ) {
        this.selectedItem[0] = 'Não';
      }
    }
  }

  isDisabled(index: number, label: string): boolean {
    const selectedItems = this.selectedItem[index] || [];
    return selectedItems.includes('Nenhuma') && label !== 'Nenhuma';
  }

  disableOtherItems(selectedIndex: number) {
    this.selectedItem[selectedIndex] = this.selectedItem[selectedIndex].filter(
      (label: string) => label === 'Nenhuma'
    );
  }

  enableAllItems(selectedIndex: number) {
    this.selectedItem[selectedIndex] = this.selectedItem[selectedIndex].filter(
      (label: string) => label !== 'Nenhuma'
    );
  }

  trackByIndex(index: number): number {
    return index;
  }

  findItem(item: any) {
    return this.respostasAnamneses.find(
      (i: any) => i?.itemAnamnesis.id === item?.id
    );
  }

  findItemAnamnesisActive(item: any) {
    return this.respostasAnamneses.find(
      (i: any) => i?.itemAnamnesisId === item?.id
    );
  }

  verifyItemCount() {
    return this.anamneses.items.length > 1 ? 1 : 0;
  }

  async isArrayEditAndPostItem(
    currentQuestion: any,
    currentActiveAnamnese: any,
    index: number
  ) {
    if (!currentActiveAnamnese) {
      let strDoencas = this.selectedItem[index].join(',');
      this.createNewAnamnese(currentQuestion[index], strDoencas);
      let resp = await this.createNewAnamnese(
        currentQuestion[index],
        strDoencas
      );
      this.respostasAnamneses.push(resp);
    } else if (
      currentActiveAnamnese.answer !==
      this.joinArrayToString(this.selectedItem[index])
    ) {
      this.selectedItem[index] = this.joinArrayToString(
        this.selectedItem[index]
      );
      this.putAnswer(currentActiveAnamnese, this.selectedItem[index]);
    }
  }

  async isNotArrayEditAndPostItem(
    currentQuestion: any,
    currentActiveAnamnese: any,
    index: number
  ) {
    if (!currentActiveAnamnese) {
      let resp = await this.createNewAnamnese(
        currentQuestion[index],
        this.selectedItem[index]
      );
      this.respostasAnamneses.push(resp);
    } else if (currentActiveAnamnese.answer !== this.selectedItem[index]) {
      await this.putAnswer(currentActiveAnamnese, this.selectedItem[index]);
    } else {
      return;
    }
  }

  async verifyCurrentItem(currentQuestion: any) {
    for (let i = 0; i < currentQuestion.length; i++) {
      let currentActiveAnamnese: any = '';
      currentActiveAnamnese = this.findItemAnamnesisActive(currentQuestion[i]);
      let isArray = Array.isArray(this.selectedItem[i]);
      if (isArray) {
        this.isArrayEditAndPostItem(currentQuestion, currentActiveAnamnese, i);
      } else {
        this.isNotArrayEditAndPostItem(
          currentQuestion,
          currentActiveAnamnese,
          i
        );
      }
    }
  }

  async createNewAnamnese(currentQuestion: any, answer: string) {
    try {
      const data = this.createObjectPost(currentQuestion, answer);
      return await this.postCustomerAnamnesis(data);
    } catch (error: any) {
      this.loadingService.notify('Erro', error?.error.message);
      this.loadingService.hide();
      return;
    }
  }

  createObjectPost(item: any, answer: string) {
    const data = {
      itemAnamnesisId: item.id,
      answer: answer,
    };
    return data;
  }

  joinArrayToString(array: any) {
    return array.join(',');
  }

  transformStringToArray(string: string) {
    return string.split(',');
  }

  async showDialog(itens: any) {
    if (this.currentPage == this.anamneses.pages && !this.isEdit) {
      this.verifyCurrentItem(itens);
      this.confirmModal = true;
    } else {
      this.verifyCurrentItem(itens);
      this.router.navigate(['/minha-conta']);
    }
  }

  async putAnswer(currentActiveAnamnese: any, answer: string) {
    try {
      currentActiveAnamnese.answer = answer;
      await this.putCustomerAnamnesis(
        this.createObjectPut(currentActiveAnamnese, answer),
        currentActiveAnamnese.id
      );
      this.loadingService.hide();
    } catch (error: any) {
      this.loadingService.notify('Erro', error.error.message);
      this.loadingService.hide();
      return;
    }
  }

  createObjectPut(item: any, answer: string) {
    const data = {
      itemAnamnesisId: item?.itemAnamnesis?.id,
      answer: answer,
      customerId: this?.customer?.id,
    };
    return data;
  }

  async manageNextItem(currentQuestion: any) {
    this.loadingService.show();
    this.currentBgColor = (this.currentBgColor + 1) % this.bgColors.length;
    this.verifyCurrentItem(currentQuestion);
    this.verifyNextItem(1);
  }

  async verifyNextItem(ahedOrBack: number) {
    if (ahedOrBack === 0) {
      this.currentPage--;
    } else if (ahedOrBack === 1) {
      this.currentPage++;
    }
    this.currentItemPercentage = this.calculatePercentage();
    this.anamneses = [];
    await this.getAndSetNextItem();
    this.loadingService.hide();
  }

  async goToBack(i: number) {
    this.loadingService.show();
    if (this.currentPage === 1 && this.isEdit) {
      this.router.navigate(['/minha-conta']);
    } else if (this.currentPage === 1) {
      this.router.navigate(['/dados-complementares']);
    } else {
      this.verifyNextItem(0);
      this.loadingService.hide();
    }
  }

  async getAndSetNextItem() {
    this.anamneses = await this.getAnamneseQuestionsV2(this.currentPage);
    if (this.anamneses) {
      for (let i = 0; i < this.anamneses.items.length; i++) {
        this.selectedItem[i] = '';
        var resposta = this.findItemAnamnesisActive(
          this.anamneses.items[i]
        )?.answer;
        if (this.anamneses.items.length == 1) {
          this.selectedItem.pop();
        }
        if (resposta) {
          if (this.anamneses.items[i].type == 4) {
            if (resposta.includes(',')) {
              this.selectedItem[i] = resposta.split(',');
            } else {
              this.selectedItem[i] = [resposta];
            }
            this.loadingService.hide();
          } else {
            this.selectedItem[i] = resposta;
            this.loadingService.hide();
          }
        }
      }
    }
    this.loadingService.hide();
  }

  calculatePercentage() {
    return Math.round((this.currentPage / this.anamneses.pages) * 100);
  }

  getCurrentBgColor() {
    return this.bgColors[this.currentBgColor];
  }

  isBgColorCard(idx: number) {
    return idx === this.currentItemIndex;
  }

  async postItemAnamnese(item: any) {
    this.itens.push(item);
  }

  verifyIfNone(selectedItem: any, index: number, arr: any) {
    console.log(selectedItem);
  }

  checkIfMobile() {
    const screenWidth = window.innerWidth;
    this.isMobile = screenWidth <= 938;
  }

  async getAnamneseQuestionsV2(page: number) {
    try {
      return await this.anamneseService.getAnamnesisQuestionsV2(page);
    } catch (error) {
      console.log(error);
    }
  }

  async postCustomerAnamnesis(body: any) {
    try {
      return await this.anamneseService.postAnamnesis({
        ...body,
        customerId: JSON.parse(sessionStorage.getItem('token') || '{}')
          ?.customer?.id,
      });
    } catch (error) {
      console.log(error);
    }
  }

  async putCustomerAnamnesis(body: any, id: string) {
    try {
      return await this.anamneseService.putAnamnesis(body, id);
    } catch (error) {
      console.log(error);
    }
  }

  async getAnamneseAtivas() {
    try {
      return await this.anamneseService.getActiveAnamnesis();
    } catch (error) {
      console.log(error);
    }
  }

  async getCustomer() {
    try {
      return await this.customerService.getCustomerId();
    } catch (error) {
      this.loadingService.hide();
    }
  }
}
