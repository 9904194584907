<div class="lg:pt-7 main">
    <div class="overflow-hidden">
      <div
        class="flex lg:col-3 bg-cover bg-no-repeat bg-center w-full justify-content-end align-items-center p-0 fundo"
        style="background: url('/assets/img/bg/Homem-meia-idade-oculos.png') top"
      >
        <div class="flex align-items-center" [style]="{ width: '60%' }">
          <div class="w-full h-auto">
            <div class="" style="min-height: 160px !important">
              <h1
                class="text-center title-father line-height-2"
                style="min-height: 160px !important"
              >
                Bem vindo à<br />
                Estação do Olho!<br />
                Sua visão importa<br />
                para nós!
              </h1>
            </div>
          </div>
        </div>
      </div>
    </div>
    
    <div class="flex lg:col-12 fundo-grid">
      <div class="grid col-12 lg:col-10 mx-auto px-0 lg:pt-6 pb-0">
        <div
          class="md:col-6 mt-7 lg:col-4 lg:hidden bg-mobile-client flex pr-1 justify-content-end"
        >
          <div class="flex align-items-center" [style]="{ width: '70%' }">
            <div class="w-full relative">
             
              <p class="text-center font-bold text-xl">
                Bem vindo à<br />
                Estação do Olho!<br />
                Sua visão importa<br />
                para nós!
              </p>
              
            </div>
          </div>
        </div>
  
        <div class="md:col-6 lg:col-6">
          <div class="mx-auto">
            <div
              class="p-5 text-center border-round result"
              [ngStyle]="{ 'background-color': isMobile ? arrColors[0] : '' }"
            >
              <div
                class="shadow-2 border-circle bg-white p-4 inline-flex justify-content-center align-items-center mb-4"
              >
                <img
                  src="/assets/img/icons/user-plus-svgrepo-com.svg"
                  alt="icone-pacientes"
                />
              </div>
              <div class="font-bold mb-4" [style]="{ color: 'white' }">
                Cadastro de Pacientes
              </div>
              <!-- <div class="text-description mb-4 line-height-3">
                Duis aute irure dolor in reprehenderit in voluptate velit esse
                cillum dolore eu fugiat nulla pariatur.
              </div> -->
              <p-button
                label="Cadastrar Novo Paciente"
                [outlined]="true"
                [rounded]="true"
                severity="secondary"
                styleClass="p-button-outlined p-button-strong w-full lg:w-auto"
                [routerLink]="['/complete-seu-cadastro']"
              ></p-button>
            </div>
          </div>
        </div>
  
        <div class="md:col-6 lg:col-6">
          <div class="mx-auto">
            <div
              class="p-5 text-center border-round voucher"
              [ngStyle]="{ 'background-color': isMobile ? arrColors[1] : '' }"
            >
              <div
                class="shadow-2 border-circle bg-white p-4 inline-flex justify-content-center align-items-center mb-4"
              >
                <img
                  src="/assets/img/icons/medical-results-folders-svgrepo-com.svg"
                  alt="icone-cupons"
                />
              </div>
              <div
                class="font-bold mb-4"
                [ngStyle]="{ color: isMobile ? arrColors[0] : 'white' }"
              >
              Upload de Exames
              </div>
              <!-- <div
                class="text-description mb-4 line-height-3"
                [ngStyle]="{ color: isMobile ? arrColors[0] : 'white' }"
              >
                Duis aute irure dolor in reprehenderit in voluptate velit esse
                cillum dolore eu fugiat nulla pariatur.
              </div> -->
              <p-button
                label="Ir para Upload de Exames"
                [outlined]="true"
                [rounded]="true"
                severity="secondary"
                styleClass="p-button-outlined p-button-strong w-full lg:w-auto"
                routerLink="/upload-de-exames"
              ></p-button>
            </div>
          </div>
        </div>

      </div>
    </div>
  

  </div>
  