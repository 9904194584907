import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PartnerService {

  private apiUrl = environment.apiUrl;

  constructor(private http: HttpClient) { }

  getCategoriasParceiros(): Promise<any>  {
    return this.http.get<any>(`${this.apiUrl}/category`,).toPromise();
  }

  getSpecificCategory(id:string | null | undefined): Promise<any>  {
    return this.http.get<any>(`${this.apiUrl}/partner/category/${id}`,).toPromise();
  }

}
