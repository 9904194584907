import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { ButtonModule } from 'primeng/button';
import { InputMaskModule } from 'primeng/inputmask';
import { FileUploadModule } from 'primeng/fileupload';
import { ToastModule } from 'primeng/toast';
import { TableModule } from 'primeng/table';
import { Product } from '../../model/product';
import { CustomerService } from '../../services/customer.service';
import { CustomerExam } from '../../model/customerExam';
import { LoadingService } from '../../services/loading.service';

@Component({
  selector: 'app-area-upload',
  standalone: true,
  imports: [
    CommonModule,
    ButtonModule,
    FileUploadModule,
    FormsModule,
    ReactiveFormsModule,
    InputMaskModule,
    RouterLink,
    ToastModule,
    TableModule,
  ],
  templateUrl: './area-upload.component.html',
  styleUrl: './area-upload.component.scss',
})
export class AreaUploadComponent {
  customers: any = {};
  id?: string | null;
  products!: Product[];
  customerExam: CustomerExam;
  arquivoInserido: boolean;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private loadingService: LoadingService,
    private customerService: CustomerService
  ) {}


  onSelect(event: any) {
    if (!this.customerExam) {
      this.customerExam = {  CustomerId: this.customers.id, UrlImage: null, ImageFileName: '', ImageFileExtension: '', Base64Image: '', isExam: true };
    }
    const selectedFile = event.files[0]; // Obtém o arquivo selecionado
    this.customerExam.ImageFileName = this.getFileNameWithoutExtension(selectedFile.name); // Obtém o nome do arquivo
    this.customerExam.ImageFileExtension = this.getFileExtension(selectedFile.name); // Obtém a extensão do arquivo
    this.convertToBase64(selectedFile);
    this.arquivoInserido = true;
  }

  getFileNameWithoutExtension(fileName: string): string {
    const lastDotIndex = fileName.lastIndexOf('.');
    if (lastDotIndex === -1) {
      return fileName;
    }
    return fileName.substring(0, lastDotIndex);
  }

  getFileExtension(fileName: string): string {
    return fileName.split('.').pop()?.toLowerCase() || '';
  }

  convertToBase64(file: File) {
    const reader = new FileReader();
    reader.onload = () => {
      if (typeof reader.result === 'string') {
        this.customerExam.Base64Image = reader.result.split(',')[1];
        console.error('Erro ao ler arquivo como base64.');
      }
    };
    reader.readAsDataURL(file);
  }


  async ngOnInit() {
    this.id = this.route.snapshot.paramMap.get('id');
    if (this.id) {
      await this.customerService.getCustomerExam(this.id).then((data) => {
        this.customers = data;
        this.arquivoInserido = false;
      });
    }
  }

  async saveFile(){
    this.loadingService.show();
    await this.customerService.UploadFile(this.customerExam);
    this.loadingService.feedbackToast(
      'Sucesso!',
      'Exame cadastrado com sucesso!'
    );
    this.reloadPage();
    this.loadingService.hide();
  }

  reloadPage() {
    let currentUrl = this.router.url;
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate([currentUrl]);
    });
  }
}
