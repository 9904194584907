import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { RouterLink } from '@angular/router';
import { ButtonModule } from 'primeng/button';
import { LoadingService } from '../../services/loading.service';

@Component({
  selector: 'app-about-us',
  standalone: true,
  imports: [RouterLink,ButtonModule,
    CommonModule,],
  templateUrl: './about-us.component.html',
  styleUrl: './about-us.component.scss'
})
export class AboutUsComponent {
  constructor(
    private loadingService: LoadingService
  ) {}


  loading() {
    this.loadingService.show();
    setTimeout(() => {
      this.loadingService.hide();
    }, 3000);
  }
}
