import { LoadingService } from './../../services/loading.service';
import { AuthService } from './../../services/auth.service';
import { Router, RouterLink } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { CardModule } from 'primeng/card';
import { PasswordModule } from 'primeng/password';
import { ButtonModule } from 'primeng/button';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { TranslateService } from '@ngx-translate/core';
import { PrimeNGConfig } from 'primeng/api';
import { Subscription } from 'rxjs';
import { CustomerService } from '../../services/customer.service';

@Component({
  selector: 'app-login',
  standalone: true,
  imports: [
    CardModule,
    PasswordModule,
    ButtonModule,
    RouterLink,
    FormsModule,
    ReactiveFormsModule,
    ProgressSpinnerModule,
  ],
  templateUrl: './login.component.html',
  styleUrl: './login.component.scss',
})
export class LoginComponent implements OnInit {
  password!: string;
  validateForm: FormGroup;
  response: any;
  lang: string = 'pt-br';
  isQuiosque: boolean = false;
  subscription: Subscription;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private authService: AuthService,
    private translateService: TranslateService,
    public primeNGConfig: PrimeNGConfig,
    public translate: TranslateService,
    private loadingService: LoadingService,
    private customerService: CustomerService,
    private route: Router
  ) {
    this.createValidateForm();
    translate.addLangs(['pt-br', 'en']);
    translate.setDefaultLang('pt-br');

    const browserLang = translate.getBrowserLang();
    let lang =
      browserLang && browserLang.match(/pt-br|en/) ? browserLang : 'pt-br';
    this.changeLang(lang);

    this.subscription = this.translate.stream('primeng').subscribe((data) => {
      this.primeNGConfig.setTranslation(data);
    });
  }

  ngOnInit(): void {
    localStorage.clear();
    sessionStorage.clear();
    this.isQuiosque = this.route.url === '/login-quiosque';
    if(this.isQuiosque) {
      sessionStorage.setItem('quiosque', "true");
    }
  }

  toLowerCase(event: any) {
    const value = event.target.value.toLowerCase();
    this.validateForm.get('email')?.setValue(value, { emitEvent: false });
  }

  changeLang(lang: string) {
    this.translate.use(lang);
  }

  createValidateForm() {
    this.translateService.setDefaultLang('pt-br');
    this.validateForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required]],
    });
  }

  async loginQuiosque(){
    try {
      if (this.validateForm.valid) {
        this.loadingService.show();
        const resp = await this.authService.loginQuiosque(this.validateForm.get('email')?.value, this.validateForm.get('password')?.value);
        if (resp) {
          if (typeof window !== 'undefined') {
            sessionStorage.setItem('token', JSON.stringify(resp));
            sessionStorage.setItem('quiosque', "true");
          }
        }
        this.router.navigate(['/quiosque-area']);
        this.loadingService.hide();
      }
    }
    catch (error) {
      this.loadingService.notify('Erro ao realizar login');
      this.loadingService.hide();
    }
  }

  async submitForm() {
    if (this.validateForm.valid) {
      try {
        this.loadingService.show();
        this.response = await this.authService
          .login(
            this.validateForm.get('email')?.value,
            this.validateForm.get('password')?.value
          )
          .then(async (resp) => {
            if (resp) {
              if (typeof window !== 'undefined') {
                sessionStorage.setItem('token', JSON.stringify(resp));
                sessionStorage.setItem('user', JSON.stringify(resp.customer));
                const sub = await this.getSubs()
                sessionStorage.setItem('subscriptions', JSON.stringify(sub[0]));
              }
              this.verifyStep();
            }
            this.router.navigate(['/area-do-cliente']);
          })
          .catch((error: any) => {
            // Replace .catchError() with .catch()
            this.loadingService.notify('Erro ao realizar login!', 'E-mail ou senha inválidos.');
            this.loadingService.hide();
          });

        this.loadingService.hide();
      } catch (error: any) {
        if (error.error && error.error.status === 401) {
          this.loadingService.notify('Erro', 'E-mail ou senha inválidos.');
          return;
        }
        this.loadingService.hide();
        return;
      }
    } else {
      Object.values(this.validateForm.controls).forEach((control) => {
        if (control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });
        }
      });
    }
  }

  async getSubs() {
    try {
      return await this.customerService.getSubscriptions();
    } catch (error) {
      this.loadingService.hide();
    }
  }

  async getLoggedUser() {
    try {
      return this.authService.getLoggedUser({
        email: this.validateForm.get('email')?.value,
        password: this.validateForm.get('password')?.value,
      });
    } catch (error) {
      console.log(error);
    }
    return;
  }

  async verifyStep() {
    const step = await this.authService.getStep();
    switch (step) {
      case 0:
        this.router.navigate(['/endereco']);
        break;
      case 1:
        this.router.navigate(['/dados-complementares']);
        break;
      case 2:
        this.router.navigate(['/anamnese']);
        break;
      case 3:
        this.router.navigate(['/area-do-cliente']);
        break;

      default:
        this.router.navigate(['/area-do-cliente']);
        break;
    }
  }
}
