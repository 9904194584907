import { Component } from '@angular/core';
import { FormsModule } from '@angular/forms';

// PrimeNG
import { ButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card';
import { InputNumberModule } from 'primeng/inputnumber';
import { ActivatedRoute, NavigationStart, Router, RouterLink } from '@angular/router';
import { CommonModule } from '@angular/common';
import { DialogModule } from 'primeng/dialog';
import { AvatarModule } from 'primeng/avatar';
import { AvatarGroupModule } from 'primeng/avatargroup';
import { LoadingService } from '../../services/loading.service';
import { CuponsService } from '../../services/cupons.service';
import { BackRouterComponent } from '../../components/back-router/back-router.component';

interface Carrinho {
  id: string;
  title: string;
  value: number;
  quantity: number;
  image: string;
}

@Component({
  selector: 'app-carrinho',
  standalone: true,
  imports: [
    ButtonModule,
    CardModule,
    InputNumberModule,
    FormsModule,
    CommonModule,
    RouterLink,
    DialogModule,
    AvatarModule,
    AvatarGroupModule,
    BackRouterComponent
  ],
  templateUrl: './carrinho.component.html',
  styleUrl: './carrinho.component.scss',
})
export class CarrinhoComponent {
  isMobile: boolean = false;
  dadosCarrinho: any = [];
  valorTotal: number = 0;
  prevRout: string = '';

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private loadingService: LoadingService,
    private cuponsService: CuponsService
  ) {}

  async ngOnInit() {
    this.loadingService.show();
    this.dadosCarrinho = JSON.parse(sessionStorage.getItem('carrinho') || '[]');
    this.valorTotal = this.dadosCarrinho.reduce(
      (acc: number, item: Carrinho) => acc += (item.value * item.quantity),
      0
    );
    if (typeof window !== 'undefined') {
      this.checkWidth();
      window.addEventListener('resize', () => {
        this.checkWidth();
      });
    }

    this.loadingService.hide();
  }

  checkWidth() {
    if (typeof window !== 'undefined') {
      this.isMobile = window.innerWidth < 992;
    }
  }

  checkIfMobile() {
    const screenWidth = window.innerWidth;
    this.isMobile = screenWidth <= 768;
  }

  formatCurrency(value: number | undefined) {
    return value?.toLocaleString('pt-br', {
      style: 'currency',
      currency: 'BRL',
    });
  }

  changeValue(item: Carrinho) {
    sessionStorage.setItem('carrinho', JSON.stringify(this.dadosCarrinho));
    this.valorTotal = this.dadosCarrinho.reduce(
      (acc: number, item: Carrinho) => acc + item.value * item.quantity,
      0
    );
  }

  onRemoveItem(item: Carrinho) {
    this.dadosCarrinho = this.dadosCarrinho.filter(
      (carrinho: Carrinho) => carrinho.id !== item.id
    );
    sessionStorage.setItem('carrinho', JSON.stringify(this.dadosCarrinho));
    this.valorTotal = this.dadosCarrinho.reduce(
      (acc: number, item: Carrinho) => acc + item.value * item.quantity,
      0
    );
  }

  replaceDot(value: number) {
    return value.toFixed(2).replace('.', ',');
  }

  async getCupom(id: string | null) {
    try {
      return await this.cuponsService.getSpecificCupom(id);
    } catch (error) {
      console.log(error);
    }
  }
}
