import { Component } from '@angular/core';

// PrimeNG
import { ButtonModule } from 'primeng/button';
import { CarouselModule } from 'primeng/carousel';
import { Cupom } from '../../model/product';
import {
  ActivatedRoute,
  NavigationEnd,
  Router,
  RouterLink,
  RouterOutlet,
} from '@angular/router';
import { Subscription } from 'rxjs';
import { CuponsService } from '../../services/cupons.service';
import { LoadingService } from '../../services/loading.service';
import { MyCouponService } from '../../services/my-coupon.service';

@Component({
  selector: 'app-my-coupon',
  standalone: true,
  imports: [
    ButtonModule,
    CarouselModule,
    RouterLink,
    RouterOutlet,
  ],
  templateUrl: './my-coupon.component.html',
  styleUrl: './my-coupon.component.scss',
})
export class MyCouponComponent {
  cupons: Cupom[] = [];
  path: string = 'assets/img/icons/';
  id: string | null | undefined;
  isDetalhe: boolean = false;

  private routerSubscription: Subscription;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private myCoupon: MyCouponService,
    private loadingService: LoadingService
  ) {
    this.routerSubscription = this.router.events.subscribe((event): any => {
      if (event instanceof NavigationEnd) {
        console.log('URL alterada para: ', event.url);
      }
    });
  }

  async ngOnInit() {
    this.loadingService.show();
    this.cupons = await this.getMyCoupons();

    if (this.route.firstChild) {
      this.isDetalhe = true;
    }
    this.loadingService.hide();
  }

  formatCurrency(value: number | undefined) {
    return value?.toLocaleString('pt-br', {
      style: 'currency',
      currency: 'BRL',
    });
  }

  calculateFutureDate(months: number) {
    const currentDate = new Date();
    const futureDate = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth() + months,
      currentDate.getDate()
    );
    const formattedDate = futureDate.toLocaleDateString('pt-BR');
    return formattedDate;
  }

  async getMyCoupons() {
    try {
      return await this.myCoupon.getVoucherByCustomer();
    } catch (error) {
      console.log(error);
    }
  }

  ngOnDestroy() {
    if (this.routerSubscription) {
      this.routerSubscription.unsubscribe();
    }
  }
}
