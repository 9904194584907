<div
  class="lg:col-12 pt-8"
  [ngStyle]="{ 'background-color': !isMobile ? arrColors[0] : '' }"
>
  <div class="pl-4">
    <back-router></back-router>
  </div>

  <section
    class="assinatura"
    [ngStyle]="{ 'background-color': !isMobile ? arrColors[0] : '' }"
  >
    <div
      class="col-12 px-4 voucher-consulta"
      [ngClass]="{ 'pt-8': !isMobile, 'pt-4': isMobile }"
    >
      <div class="col-12 m-0 p-0 pb-2 justify-content-center">
        <h2
          class="border-bottom-1 border-400"
          [ngClass]="{ '': !isMobile, 'text-center': isMobile }"
        >
          Opções de assinatura
        </h2>

        <div class="flex col-12 flex-wrap justify-content-start gap-3">
          <div class="card flex justify-content-center">
            <div class="flex flex-column gap-3">
              <div *ngFor="let category of categories" class="field-checkbox">
                <p-radioButton
                  [inputId]="category.id"
                  name="category"
                  [value]="category"
                  [(ngModel)]="selectedCategory"
                ></p-radioButton>
                <label [for]="category.id" class="ml-2 radio"
                  >{{ category.name }}: &nbsp;{{
                    formatValue(category?.value)
                  }}</label
                >
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 m-0 p-0 pb-2 justify-content-center">
        <h2
          class="border-bottom-1 border-400 lg:text-left text-center"

        >
          Como funciona
        </h2>
        <p class="text-left px-2 pb-1 conteudo">
          {{ selectedCategory?.functionality }}
        </p>
      </div>

      <div class="col-12 m-0 p-0 pb-2 justify-content-center">
        <h2
          class="border-bottom-1 border-400"
          [ngClass]="{ '': !isMobile, 'text-center': isMobile }"
        >
          Vantagens
        </h2>
        <p class="lg:text-left px-2 pb-1 conteudo">
          {{ selectedCategory?.advantages }}
        </p>
      </div>
      <div>
        <h2
          class="border-bottom-1 border-400 pb-2 pt-2 px-2 text-center xl:text-left"
        >
          Regras de cancelamento
        </h2>
        <p class="lg:text-left px-2 pb-1 conteudo">
          {{ selectedCategory?.cancelRules }}
        </p>
      </div>
    </div>

    <div
      class="fixed-container mb-8 col-12"
      [ngClass]="{
        'fixed-container': !isMobile,
        fixed: isMobile,
        'fixed-bottom': isMobile
      }"
    >
      <p-button
        label="Assine já"
        icon="pi pi-shopping-cart"
        iconPos="right"
        styleClass="w-full btn-shopping"
      ></p-button>
      <p-card styleClass="card-carrinho">
        <div class="flex flex-column px-4">
          <div class="grid align-items-baseline">
            <div class="col-8 pb-0">
              <span class="value-voucher">{{
                formatValue(selectedCategory?.value)
              }}</span>
            </div>

            <div class="col-8 col-12 fixed-box">
              <p class="font-medium inline-flex text-xl my-2">
                Válido até:&nbsp;<span class="font-bold">{{
                  calculateFutureDate(selectedCategory?.validity)
                }}</span>
              </p>
            </div>

            <div class="col-12">
              @if(!isSubscribed){
              <p-button
                label="Adquirir"
                severity="success"
                styleClass="p-button-save w-full border-round-2xl"
                [routerLink]="['/pagamento/assinatura/' + selectedCategory.id]"
              ></p-button>
              }@else{
              <p-button
                label="Já adquirido"
                severity="success"
                [disabled]="true"
                styleClass="p-button-save w-full border-round-2xl"
                [routerLink]="['/pagamento/assinatura/' + selectedCategory.id]"
              ></p-button>
              }
            </div>
          </div>
        </div>
      </p-card>
    </div>
  </section>
</div>
