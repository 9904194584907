<div
  class="lg:col-12 pt-8 h-screen"
  [ngClass]="{ '': !isMobile, 'h-screen': isMobile }"
>
  <div class="backRouter p-4 cursor-pointer flex" routerLink="/area-do-cliente">
    <a>
      <i class="pi pi-chevron-circle-left vertical-align-bottom"> </i>
      <span>Voltar</span>
    </a>
  </div>
  <section class="">
    <div
      class="col-12 px-4 lg:px-8 voucher-consulta lg:align-items-center assinatura"
      [ngClass]="{ 'pt-8': !isMobile, 'pt-0': isMobile }"
    >
      @if(isSubscribed){
      <div>
        <h2 class="title border-bottom-1 border-400 text-left xl:text-left">
          Minha assinatura
        </h2>
        <div
          class="lg:flex lg:justify-content-between lg:flex-wrap col-9 container-assinatura"
        >
          <div class="align-items-center justify-content-center">
            <h2 class="title-info text-left xl:text-left mb-1">
              Tipo da assinatura
            </h2>
            <span class="info text-left">
              {{ subscriptions?.subscription?.name }}</span
            >
          </div>
          <div class="align-items-center justify-content-center">
            <h2 class="title-info text-left xl:text-left mb-1">
              Valor da assinatura
            </h2>
            <span class="info text-left">
              {{ formatValue(subscriptions?.subscription?.value) }}
            </span>
          </div>
          <div class="align-items-center justify-content-center lg:pl-6">
            <h2 class="title-info sm:text-left mb-1">Data de validade</h2>
            <span class="info sm:text-left">
              Válido até {{ dayToDate(subscriptions?.subscription?.validity) }}
            </span>
          </div>
          <div class="align-items-center justify-content-center lg:pl-6">
            <h2 class="title-info text-left xl:text-left mb-1">
              Status de uso
            </h2>
            <span class="info text-left">
              {{ subscriptions?.subscription?.qtdVouchers }}&nbsp;cupons por mês
            </span>
          </div>
        </div>
      </div>
      }
      <div class="pt-3">
        <div
          class="redirect flex flex-wrap justify-content-between align-items-end border-bottom-1 border-400 pt-3"
          (click)="handleOpenSection(0)"
        >
          <div class="flex align-items-center justify-content-center">
            <h2 class="title text-left xl:text-left">Dados Pessoais</h2>
          </div>
          <div class="icone flex align-items-center justify-content-center">
            <i class="{{ sections[0].icon }}"></i>
          </div>
        </div>
        @if (!isMobile && sections[0].isOpen) {
        <div class="col-8">
          <form [formGroup]="formDadosPessoais">
            <div class="formgrid grid">
              <div class="field col-6">
                <label for="firstname6">Nome</label>
                <input
                  id="firstname6"
                  type="text"
                  class="text-base text-color surface-overlay border-1 border-solid border-round appearance-none outline-none focus:border-primary w-full"
                  formControlName="name"
                />
              </div>
              <div class="field col-6">
                <label for="lastname6">E-mail</label>
                <input
                  id="disabled-input"
                  type="text"
                  pInputText
                  class="text-base text-color border-1 border-solid border-round appearance-none outline-none focus:border-primary w-full"
                  formControlName="email"
                  [disabled]="true"
                />
              </div>
              <div class="field col-12 lg:col-4 mt-2">
                <label class="block mb-2">CPF</label>
                <p-inputMask
                  mask="999.999.999-99"
                  id="cpf"
                  formControlName="cpf"
                  placeholder="000.000.000-00"
                  type="text"
                  [disabled]="true"
                  class="w-full md:w-full mb-3"
                  [style]="{ width: '100%' }"
                ></p-inputMask>
                <div
                  *ngIf="
                    formDadosPessoais.get('cpf')?.invalid &&
                    formDadosPessoais.get('cpf')?.touched
                  "
                  class="alert alert-danger"
                >
                  CPF inválido.
                </div>
              </div>
              <div class="field col-3 mt-2">
                <label class="block mb-2">Celular</label>
                <p-inputMask
                  mask="(99)99999-9999"
                  id="cellphone"
                  formControlName="phoneNumber"
                  placeholder="(00)00000-0000"
                  type="text"
                  class="w-full md:w-full mb-3"
                  [style]="{ width: '100%' }"
                ></p-inputMask>
              </div>
              <div class="field lg:col-5 md:col-5 py-0 mt-2">
                <label class="block">Data de nascimento</label>
                <p-inputMask
                  mask="99/99/9999"
                  id="birthDate"
                  formControlName="birthDate"
                  placeholder="dd/mm/yyyy"
                  type="text"
                  class="w-full md:w-full"
                  [style]="{ width: '100%' }"
                ></p-inputMask>
              </div>
            </div>
          </form>
          <div class="col-12 flex text-center justify-content-center">
            <div class="col-4 mt-2">
              <p-button
                type="button"
                type="button"
                label="Salvar edições"
                severity="success"
                styleClass="p-button-after w-full"
                (onClick)="submitFormRegister()"
              ></p-button>
            </div>
          </div>
        </div>
        }
        <div
          class="redirect flex flex-wrap justify-content-between align-items-end border-bottom-1 border-400 pt-3"
          (click)="handleOpenSection(1)"
        >
          <div class="flex align-items-center justify-content-center">
            <h2 class="title text-left xl:text-left">Endereço</h2>
          </div>
          <div class="icone flex align-items-center justify-content-center">
            <i class="{{ sections[1].icon }}"></i>
          </div>
        </div>
        @if (!isMobile && sections[1].isOpen) {
        <div class="col-8">
          <form [formGroup]="formEndereco">
            <div class="formgrid grid">
              <div class="field col-3 pb-2">
                <label class="block mb-2">CEP</label>
                <p-inputMask
                  mask="99999-999"
                  formControlName="zipCode"
                  (onComplete)="getEnderecoCEP()"
                  placeholder="00000-000"
                  type="text"
                  class="w-full md:w-full"
                  id="cep"
                  [style]="{ width: '100%' }"
                ></p-inputMask>
              </div>
              <div class="field col-7">
                <label class="block mb-2">Endereço (Rua, Av. etc.)</label>
                <input
                  id="address"
                  type="text"
                  formControlName="street"
                  placeholder="Digite"
                  pInputText
                  class="w-full md:w-full mb-3"
                />
              </div>
              <div class="field col-12 lg:col-2">
                <label class="block mb-2">Número</label>
                <input
                  id="numberAddress"
                  #numeroInput
                  type="text"
                  formControlName="number"
                  placeholder="Digite"
                  pInputText
                  class="w-full md:w-full mb-3"
                />
              </div>
              <div class="field col-3">
                <label class="block mb-2">Complemento</label>
                <input
                  id="complement"
                  type="text"
                  placeholder="Digite"
                  formControlName="complement"
                  pInputText
                  class="w-full md:w-full mb-3"
                />
              </div>

              <div class="field col-3">
                <label class="block mb-2">Bairro</label>
                <input
                  id="address"
                  type="text"
                  placeholder="Digite"
                  pInputText
                  formControlName="neighborhood"
                  class="w-full md:w-full mb-3"
                />
              </div>

              <div class="field col-4">
                <label class="block mb-2">Cidade</label>
                <input
                  id="city"
                  type="text"
                  placeholder="Digite"
                  pInputText
                  formControlName="city"
                  class="w-full md:w-full mb-3"
                />
              </div>

              <div class="field col-2">
                <label class="block mb-2">UF</label>
                <input
                  id="uf"
                  type="text"
                  placeholder="Digite"
                  pInputText
                  formControlName="state"
                  class="w-full md:w-full mb-3"
                />
              </div>
            </div>
          </form>
          <div class="col-12 flex text-center justify-content-center">
            <div class="col-2 lg:col-4 mt-2">
              <p-button
                type="button"
                type="button"
                label="Salvar edições"
                severity="success"
                styleClass="p-button-after w-full"
                (onClick)="SubmitFormAddress()"
              ></p-button>
            </div>
          </div>
        </div>
        }
        <div
          class="redirect flex flex-wrap justify-content-between align-items-end border-bottom-1 border-400 pt-3"
          (click)="handleOpenSection(2)"
        >
          <div class="flex align-items-center justify-content-center">
            <h2 class="title text-left xl:text-left">Dados Complementares</h2>
          </div>
          <div class="icone flex align-items-center justify-content-center">
            <i class="{{ sections[2].icon }}"></i>
          </div>
        </div>
        @if (!isMobile && sections[2].isOpen) {
        <div class="col-8">
          <form [formGroup]="formComplementares">
            <div class="formgrid grid">
              <div class="field col-12 lg:col-6">
                <label class="block mb-2">Sexo biológico</label>
                <p-dropdown
                  [options]="sexos"
                  formControlName="sex"
                  optionLabel="description"
                  optionValue="id"
                  placeholder="Selecione"
                  autoWidth="false"
                  [style]="{ width: '100%' }"
                ></p-dropdown>
              </div>
              <div class="field col-12 lg:col-6">
                <label class="block mb-2">Raça</label>
                <p-dropdown
                  [options]="etnias"
                  formControlName="ethnicity"
                  optionLabel="description"
                  optionValue="id"
                  placeholder="Selecione"
                  autoWidth="false"
                  [style]="{ width: '100%' }"
                ></p-dropdown>
              </div>

              <div class="field col-4 mt-2">
                <label class="block mb-2">Renda Familiar mensal</label>
                <p-dropdown
                  [options]="salary"
                  formControlName="householdIncome"
                  optionLabel="salario"
                  optionValue="value"
                  [showClear]="true"
                  placeholder="Selecione"
                ></p-dropdown>
              </div>

              <div class="field col-4 pt-4">
                <label class="block mb-2">Possui plano de saúde?</label>
                <div class="flex flex-wrap gap-3">
                  <div class="flex align-items-center">
                    @for (category of radioForm; track $index) {
                    <div class="field-checkbox">
                      <p-radioButton
                        [inputId]="category.value"
                        [value]="category.value"
                        formControlName="hasHealthInsurance"
                      ></p-radioButton>
                      <label [for]="category.value" class="ml-2">{{
                        category.name
                      }}</label>
                    </div>
                    }
                  </div>
                </div>
              </div>
              @if (formComplementares.value.hasHealthInsurance) {
              <div class="field col-12">
                <label class="block mb-2">Qual?</label>
                <p-dropdown
                  [options]="plan"
                  formControlName="healthInsuranceId"
                  optionLabel="name"
                  optionValue="id"
                  [showClear]="true"
                  placeholder="Selecione"
                ></p-dropdown>
              </div>
              }
            </div>
          </form>
          <div class="col-12 flex text-center justify-content-center">
            <div class="col-2 lg:col-4 mt-2">
              <p-button
                type="button"
                type="button"
                label="Salvar edições"
                severity="success"
                styleClass="p-button-after w-full"
                (onClick)="submitFormIncome()"
              ></p-button>
            </div>
          </div>
        </div>
        }
        <div
          class="redirect flex flex-wrap justify-content-between align-items-end border-bottom-1 border-400 pt-3"
          (click)="handleOpenSection(3)"
        >
          <div class="flex align-items-center justify-content-center">
            <h2 class="title text-left xl:text-left">Anamnese</h2>
          </div>
          <div class="icone flex align-items-center justify-content-center">
            <i class="{{ sections[3].icon }}"></i>
          </div>
        </div>
        @if (!isMobile && sections[3].isOpen) {

        <!-- <div class="flex justify-content-between flex-wrap  col-9">
          @for (item of anamnese; track i; let i = $index) {
          <div class="col-4 mb-7">
            <div class="align-items-center">
              <label class="block mb-2 pgt-anamnese"
                >{{ item?.itemAnamnesis?.itemOrder }} -
                {{ item?.itemAnamnesis?.question }}</label
              >
              @for (pergunta of item?.itemAnamnesis?.multipleChoiceTextsArray;
              track j; let j = $index) {
              <div class="field-checkbox">
                <p-radioButton
                  [inputId]="item.id"
                  [value]="item.answer"
                  name="item"
                  [ngModel]="answerAnamnese[i] = pergunta === item.answer ? pergunta : answerAnamnese[i]"
                ></p-radioButton>
                <label [for]="item.id" class="ml-2">{{ pergunta }}</label>
              </div>
              }
            </div>
          </div>
          }
        </div> -->
        }
      </div>

      @if(isSubscribed){
      <div
        class="flex p-4 py-6 align-items-center justify-content-center lg:col-9"
      >
        <span
          (click)="
            cancelButtonClick ? (confirmModal = true) : (confirmModal = false)
          "
          class="cancelar font-semibold cursor-pointer"
          >{{ labelCancelSubs }}</span
        >
      </div>
      }
    </div>
  </section>
</div>

<p-dialog
  header="Tem certeza que deseja cancelar?"
  [(visible)]="confirmModal"
  [modal]="true"
  [draggable]="false"
  [resizable]="false"
  [breakpoints]="{ '960px': '30%', '640px': '93%' }"
>
  <div class="flex flex-column p-2 pt-5">
    <div>
      <p class="m-0 text-lg text-black-alpha-90 font-medium line-height-2">
        Caso cancele a assinatura,<br />
        não será possível reativá-lo.<br />
        Deseja continuar?
      </p>
    </div>
    <div class="py-4">
      <p class="m-0 text-sm text-black-alpha-90 font-medium line-height-2">
        <strong>Atenção: </strong> O valor do cupom será devolvido<br />
        em forma de crédito.
      </p>
    </div>

    <div class="col-12 flex-column">
      <div class="w-full">
        <p-button
          label="Não, prefiro continuar"
          (click)="modalDesistencia = true"
          severity="help"
          type="button"
          styleClass="p-button-save p-button-ok w-full border-round-xl"
        ></p-button>
      </div>
      <div
        class="flex align-items-center text-center justify-content-center my-4"
      >
        <span (click)="cancelarAssinatura()" class="cancelar cursor-pointer"
          >Sim, prefiro cancelar</span
        >
      </div>
    </div>
  </div>
</p-dialog>

<p-dialog
  header="Sua solicitação foi enviada."
  [(visible)]="modalSolicitacao"
  [modal]="true"
  [draggable]="false"
  [resizable]="false"
  [breakpoints]="{ '960px': '30%', '640px': '93%' }"
>
  <div class="flex flex-column p-2 pt-5">
    <div>
      <p
        class="m-0 text-lg lg:text-xl text-black-alpha-90 font-medium line-height-2"
      >
        Analisaremos sua solicitação e <br />
        entraremos em contato pelo <br />
        seu e-mail cadastrado em até <br />
        5 dias úteis.
      </p>
    </div>
    <div class="py-4">
      <p
        class="m-0 text-sm lg:text-base text-black-alpha-90 font-medium line-height-2"
      >
        <strong>Atenção: </strong> verifique sua caixa de spam ou <br />
        lixo eletrônico caso não encontre o link.
      </p>
    </div>

    <div class="col-12 mb-5 flex-column">
      <div class="w-full">
        <p-button
          label="OK"
          (click)="closeAllModals()"
          severity="help"
          type="button"
          styleClass="p-button-save p-button-ok w-full border-round-xl"
        ></p-button>
      </div>
    </div>
  </div>
</p-dialog>

<p-dialog
  header="Desistência de cancelamento:"
  [(visible)]="modalDesistencia"
  [modal]="true"
  [draggable]="false"
  [resizable]="false"
  [breakpoints]="{ '960px': '20%', '640px': '93%' }"
>
  <div class="flex flex-column p-5">
    <div>
      <p class="m-0 text-xl text-black-alpha-90 font-medium">
        <strong
          >Sua desistência de cancelamento foi <br />
          efetuada com sucesso!</strong
        >
      </p>
    </div>
    <div>
      <p class="m-0 text-xl text-black-alpha-90 my-5 font-medium">
        Obrigado por continuar <br />
        utilizando nossos serviços.
      </p>
    </div>

    <div class="col-12 flex-column">
      <div class="w-full">
        <p-button
          label="OK"
          (click)="closeAllModals()"
          severity="help"
          type="button"
          styleClass="p-button-save p-button-ok w-full lg:w-6 border-round-xl"
        ></p-button>
      </div>
    </div>
  </div>
</p-dialog>
