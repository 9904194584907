import { LoadingService } from './../../../services/loading.service';
import { PartnerService } from './../../../services/partner.service';
import { CardModule } from 'primeng/card';
import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { ButtonModule } from 'primeng/button';
import { Oticas } from '../../../model/medicos';
import { CarouselModule } from 'primeng/carousel';
import { DialogModule } from 'primeng/dialog';
import { AvatarGroupModule } from 'primeng/avatargroup';
import { AvatarModule } from 'primeng/avatar';

@Component({
  selector: 'app-category-partner',
  standalone: true,
  imports: [ButtonModule, CommonModule, RouterLink, CardModule, CarouselModule,DialogModule,
    AvatarModule,
    AvatarGroupModule,],
  templateUrl: './category-partner.component.html',
  styleUrl: './category-partner.component.scss',
})
export class CategoryPartnerComponent {
  isMobile: boolean = false;
  isDesktop: boolean;
  arrColors: string[] = ['#78CDD1', '#E4E6E7', '#78CDD1'];
  layoutType: number = 1;
  optics: Oticas[] = [];
  responsiveOptions: any[] | undefined;
  categoryId: string | null | undefined;
  selectedItem: any;
  listPartner: any = [
    {}
  ];
  categoriaParceiros: any[] = [];
  visible: boolean = false;

  constructor(private route: ActivatedRoute, private partnerService: PartnerService, private loadingService: LoadingService) {}

  async ngOnInit() {
    this.loadingService.show();
    this.categoryId = this.route.snapshot.paramMap.get('idCategoria');
    this.categoriaParceiros = await this.getCategorias();
    this.getTypeLayout();
    if(this.categoryId){
      this.listPartner = await this.getListCategory(this.categoryId);
    }
    if (typeof window !== 'undefined') {
      this.checkWidth();
      window.addEventListener('resize', () => {
        this.checkWidth();
      });
    }
    this.loadingService.hide();
  }

  getTypeLayout() {
    this.layoutType = this.categoriaParceiros.find((c) => c.id === this.categoryId).type;
  }

  sanitizePhoneNumber(phoneNumber: string | null): string {
    if (phoneNumber) {
      // Remove special characters and spaces
      const sanitizedNumber = phoneNumber.replace(/[^\d]/g, '');

      return sanitizedNumber;
    }

    return '';
  }

  redirectLink(link: string) {
    window.open(link, '_blank');
  }
  
  async getListCategory(id:string | null | undefined) {
    try {
      return await this.partnerService.getSpecificCategory(id);
    } catch (error) {
      console.log(error);
      return []; // Add a return statement to handle the error case
    }
  }

  async getCategorias() {
    try {
      return await this.partnerService.getCategoriasParceiros();
    } catch (error) {
      console.log(error);
    }
  }

  checkWidth() {
    if (typeof window !== 'undefined') {
      this.isMobile = window.innerWidth < 768;
    }
  }

  checkIfMobile() {
    const screenWidth = window.innerWidth;
    this.isMobile = screenWidth <= 768;
    this.isDesktop = !this.isMobile;
  }
}
