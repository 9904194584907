import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { Router, RouterLink, RouterOutlet } from '@angular/router';

// PrimeNG
import { ButtonModule } from 'primeng/button';


@Component({
  selector: 'app-question',
  standalone: true,
  imports: [
    CommonModule,
    ButtonModule,
    RouterOutlet,
    RouterLink,
  ],
  templateUrl: './question.component.html',
  styleUrl: './question.component.scss',
})
export class QuestionComponent {

  constructor(private router: Router) { }

  questionsVisible = false;

  toggleQuestions() {
    this.router.navigate(['/endereco']);
  }

  prepareRoute(outlet: RouterOutlet) {
    return outlet && 
      outlet.activatedRouteData && 
      outlet.activatedRouteData['animationState'];
   }
}
