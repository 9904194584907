import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Injectable } from '@angular/core';


@Injectable({
  providedIn: 'root'
})
export class CuponsService {
  private apiUrl = environment.apiUrl;

  constructor(private http: HttpClient) { }

  getNormalCoupons(): Promise<any> {
    return this.http.get<any>(`${this.apiUrl}/voucher`).toPromise();
  }

  getAllActiveCupons(): Promise<any> {
    return this.http.get<any>(`${this.apiUrl}/voucher/active`).toPromise();
  }

  getSponsorshipCupons(): Promise<any> {
    return this.http.get<any>(`${this.apiUrl}/voucher/partners/sponsors/list`).toPromise();
  }

  getRecommendedCupons(): Promise<any> {
    return this.http.get<any>(`${this.apiUrl}/voucher/recommended`).toPromise();
  }

  getSpecificCupom(id:string | null | undefined): Promise<any>  {
    return this.http.get<any>(`${this.apiUrl}/voucher/${id}`,).toPromise();
  }

  getVoucherByCustomer(): Promise<any> {
    return this.http.get<any>(`${this.apiUrl}/customer/voucher`).toPromise();
  }

  postVouchertoCustomer(body:any): Promise<any> {
    return this.http.post<any>(`${this.apiUrl}/customer/voucher`, body).toPromise();
  }

  postVoucherView(id:string): Promise<any> {
    return this.http.post<any>(`${this.apiUrl}/voucher/view/${id}`, {}).toPromise();
  }



}
