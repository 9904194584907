<div class="col-12 pt-8">
  <div class="backRouter p-4 cursor-pointer flex" routerLink="/area-do-cliente">
    <a>
      <i class="pi pi-chevron-circle-left vertical-align-bottom"> </i>
      <span>Voltar</span>
    </a>
  </div>
  <div class="text-center mt-5 lg:col-3 mx-auto">
    <p-button
      styleClass="btn-carteirinha"
      (click)="visible = true"
      styleClass="lg:col-2"
      label="Abrir carteirinha"
    >
    </p-button>
  </div>
</div>

<!--modal -->
<p-dialog [modal]="true" [(visible)]="visible" [breakpoints]="{ '960px': '30%', '640px': '93%' }">
  <div class="col-12 pt-2 main">
    <div class="text-right px-2 pt-2">
      <i
        class="pi pi-times"
        (click)="visible = false"
        style="
          font-size: 1.5rem;
          color: #9e9e9e;
          font-weight: 700;
          cursor: pointer;
        "
      >
      </i>
    </div>
    <div class="flex flex-column p-3 pt-0">
      <div class="justify-content-center text-center">
        <img
          src="/assets/img/bg/logo_carteirinha.png"
          alt="logo_carteirinha"
          style="max-width: 70%; max-height: 80px"
          class="mr-3"
        />
      </div>
      <!-- <div class="border-bottom-2 text-center pb-3 p-4 mt-3 titulo-acima-div">
        <span>Carteira digital</span>
      </div> -->
      <div class="mt-3 info">
        <div class="flex flex-column text-left">
          <span class="">Nome:</span>
          <p>{{ user?.name }}</p>
        </div>
        <div class="flex flex-column text-left">
          <span class="">CPF:</span>
          <p>{{formatCPF(user?.cpf)}}</p>
        </div>
        @if(subscriptions){
          <div class="flex flex-column text-left">
            <span class="">Plano:</span>
            <p>{{subscriptions?.subscription?.name}}</p>
          </div>
        }@else {
          <div class="flex flex-column text-left">
            <span class="">Plano:</span>
            <p>Não assinante</p>
          </div>
        }
        <div class="flex flex-column text-left">
          <span class="">Validade:</span>
          <p>19/05/2025</p>
        </div>
      </div>

      <div class="flex justify-content-center flex-wrap mt-1">
        <div class="text-center mt-4 card-qr p-4 bg-white">
          <qrcode [qrdata]="user?.id" [width]="180" [errorCorrectionLevel]="'M'" [allowEmptyString]="true"></qrcode>
          <span class="font-bold text-lg mt-3" style="color: black"
            >{{user?.id}}</span
          >
        </div>
      </div>
    </div>
  </div>
</p-dialog>
