import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { CardModule } from 'primeng/card';
import { CustomerService } from '../../services/customer.service';
import { LoadingService } from '../../services/loading.service';
import { Router, RouterLink } from '@angular/router';
import { DialogModule } from 'primeng/dialog';
import { ButtonModule } from 'primeng/button';
import { QRCodeModule } from 'angularx-qrcode';
import { BackRouterComponent } from '../../components/back-router/back-router.component';

@Component({
  selector: 'app-carteirinha',
  standalone: true,
  imports: [
    CommonModule,
    RouterLink,
    DialogModule,
    CardModule,
    ButtonModule,
    QRCodeModule,
    BackRouterComponent
  ],
  templateUrl: './carteirinha.component.html',
  styleUrl: './carteirinha.component.scss'
})
export class CarteirinhaComponent {

  subscriptions: any;
  user:any;
  visible: boolean = false;
  isMobile: boolean = false;

  constructor(
    private router: Router,
    private loadingService: LoadingService,
    private customerService: CustomerService
  ) {}

  async ngOnInit() {
    this.loadingService.show();
    const sub = await this.getSubs();
    this.subscriptions = sub[0];
    this.user = JSON.parse(sessionStorage.getItem('user') || '{}')
    if (typeof window !== 'undefined') {
      this.checkWidth();
      window.addEventListener('resize', () => {
        this.checkWidth();
      });
    }
    this.loadingService.hide();
  }

  formatCPF(cpf: string): string {
    if(!cpf){
      return '';
    }
    const formattedCPF = cpf.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})$/, '$1.$2.$3-$4');
    return formattedCPF;
  }

  checkWidth() {
    if (typeof window !== 'undefined') {
      this.isMobile = window.innerWidth < 768;
    }
  }

  checkIfMobile(){
    const screenWidth = window.innerWidth;
    this.isMobile = screenWidth <= 768;
  }

  async getSubs() {
    try {
      return await this.customerService.getSubscriptions();
    } catch (error) {
      console.log(error);
    }
  }

}
