import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { Router, RouterModule } from '@angular/router';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { CheckboxModule } from 'primeng/checkbox';
import { InputMaskModule } from 'primeng/inputmask';
import { PasswordModule } from 'primeng/password';
import { AuthService } from '../../services/auth.service';
import { CustomerService } from '../../services/customer.service';
import { LoadingService } from '../../services/loading.service';
import { ValidatorComponent } from '../validator/validator.component';
import { CustomerKiosk } from '../../model/customerKiosk';
import { ScrollPanelModule } from 'primeng/scrollpanel';

@Component({
  selector: 'app-register-quiosque',
  standalone: true,
  imports: [
    CommonModule,
    ButtonModule,
    CheckboxModule,
    FormsModule,
    ReactiveFormsModule,
    PasswordModule,
    RouterModule,
    CalendarModule,
    InputMaskModule,
    ScrollPanelModule
  ],
  templateUrl: './register-quiosque.component.html',
  styleUrl: './register-quiosque.component.scss',
})
export class RegisterQuiosqueComponent {
  password!: string;
  validateForm: FormGroup;
  isEdit: boolean;
  user: any = {};

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private authService: AuthService,
    private loadingService: LoadingService,
    private customerService: CustomerService
  ) {
    this.createValidateForm();
  }

  async ngOnInit() {
    if (typeof window !== 'undefined') {
      const url = window.location.href;
      if (url.includes('editar')) {
        this.verifyIsEdit();
      } else {
        this.isEdit = false;
      }
    }
  }

  async verifyIsEdit() {
    this.isEdit = true;
    this.validateForm.removeControl('password');
    this.validateForm.removeControl('confirmarSenha');
    this.validateForm.removeControl('termsAndConditions');
    this.validateForm.get('email')?.disable();
    this.user = await this.getLoggedUser();
    this.validateForm.patchValue(this.user);
    if (this.user.birthDate) {
      this.validateForm.patchValue({
        birthDate: this.formatDate(this.user.birthDate),
      });
    }
  }

  createValidateForm() {
    this.validateForm = this.fb.group({
      name: ['', [Validators.required]],
      email: [
        '',
        [
          Validators.required,
          Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$'),
        ],
      ],
      phoneNumber: ['', [Validators.required]],
      cpf: ['', [Validators.required, ValidatorComponent.cpf]],
      birthDate: ['', [ValidatorComponent.ptDate]],
    });
  }

  verificaSenhasIguais(): boolean {
    return (
      this.validateForm.get('password')?.value ===
      this.validateForm.get('confirmarSenha')?.value
    );
  }

  async createUser() {
    if (!this.isEdit) {
      this.validateForm.get('email')?.enable();
    }
  }

  async loginAndSetToken(resp: any) {
    sessionStorage.setItem('user', JSON.stringify(resp));
    this.authService
      .login(
        this.validateForm.get('email')?.value,
        this.validateForm.get('password')?.value
      )
      .then((resp) => {
        if (resp) {
          sessionStorage.setItem('token', JSON.stringify(resp));
          this.loadingService.notify('Cadastro realizado com sucesso!');
          this.router.navigate(['/endereco']);
          this.loadingService.hide();
        }
      })
      .catch((error) => {
        this.loadingService.notify('Erro ao logar', 'error');
        this.loadingService.hide();
      });
  }

  unformatCpf(cpf: string) {
    return cpf.replace(/[^\d]+/g, '');
  }

  async submitForm() {
    this.loadingService.show();

    const formatBody = (): CustomerKiosk => {
      const birthDate = this.unformatDate(
        this.validateForm.get('birthDate')?.value
      );
      const cpf = this.unformatCpf(this.validateForm.get('cpf')?.value);
      const phoneNumber = this.unformatCpf(
        this.validateForm.get('phoneNumber')?.value
      );
      return {
        name: this.validateForm.get('name')?.value,
        email: this.validateForm.get('email')?.value,
        phoneNumber: phoneNumber,
        birthDate: birthDate,
        cpf: cpf,
      };
    };

    if (this.validateForm.valid) {
      try {
        const body = formatBody();
        const response = await this.customerService.createUserKiosk(body);
        if (response == null) {
          this.loadingService.hide();
          this.loadingService.feedbackToast(
            'Sucesso!',
            'Usuário cadastrado com sucesso'
          );
          this.reloadPage();
        } else {
          this.loadingService.notify(
            'Erro ao cadastrar',
            'Erro interno',
            '/login'
          );
          this.loadingService.hide();
          return;
        }
      } catch (error: any) {
        this.loadingService.notify('Erro ao cadastrar', error);
        this.loadingService.hide();
        return;
      }
    } else {
      Object.values(this.validateForm.controls).forEach((control) => {
        if (control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });
        }
      });
    }
  }

  reloadPage() {
    let currentUrl = this.router.url;
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate([currentUrl]);
    });
  }

  unformatDate(date: string) {
    const dateArray = date.split('/');
    return `${dateArray[2]}-${dateArray[1]}-${dateArray[0]}T00:00:00.000Z`;
  }

  formatDate(date: string) {
    const dateArray = date.split('-');
    const day = dateArray[2].substring(0, 2);
    const month = dateArray[1];
    const year = dateArray[0];
    const formattedDate = `${day}/${month}/${year}`;
    return formattedDate;
  }

  verifyBackEdit() {
    if (this.isEdit) {
      this.router.navigate(['/minha-conta']);
    } else {
      this.router.navigate(['/login']);
    }
  }

  async getLoggedUser() {
    try {
      return await this.customerService.getCustomerId();
    } catch (error) {
      console.log(error);
    }
  }

  async putRegisterUser(body: any) {
    try {
      return await this.authService.putUserInfo(body, this.user?.id || null);
    } catch (error) {
      console.log(error);
    }
  }
}
