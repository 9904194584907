// loading.service.ts
import { Injectable } from '@angular/core';
import { MessageService } from 'primeng/api';
import { BehaviorSubject, Observable } from 'rxjs';
import { Location } from '@angular/common';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {
  private _loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(private messageService: MessageService, private location: Location, private router: Router) { }

  show(): void {
    setTimeout(() => {
      this._loading.next(true);
    }, 100);
  }

  hide(): void {
    setTimeout(() => {
      this._loading.next(false);
    }, 100);
  }

  notify(titulo: string = "Aviso!", mensagem: string = "", redirect?: any) {
    this.messageService.clear();
    this.messageService.add({
      key: 'block2',
      severity: 'custom-2',
      summary: titulo,
      closable: false,
      detail: mensagem,
      contentStyleClass: 'p-0',
      redirect: redirect
    } as any);
  }

  feedbackToast(titulo: string = "Aviso!", mensagem: string = "Código enviado para o e-mail cadastrado.", severity: string = 'success') {
    this.messageService.clear();
    this.messageService.add({
      key: 'feedback',
      severity: severity,
      summary: titulo,
      closable: false,
      detail: mensagem,
      contentStyleClass: 'p-1',
    } as any);
  }

  feedbackSuccess(titulo: string = "Sucesso!", mensagem: string = "Operação realizada com sucesso.") {
    this.messageService.clear();
    this.messageService.add({
      key: 'feedback',
      summary: titulo,
      closable: true, // Permitir que a mensagem seja fechada
      detail: mensagem,
      contentStyleClass: 'p-1',
    } as any);
  }

  goBack(){
    const history = window.history;
    const rota = history.length > 1 ? document.referrer : '';

    if (rota.includes('parceiros/')) {
      this.router.navigate(['/area-do-cliente']);
    } else {
      this.location.back();
    }
  }
  


  get loading$(): Observable<boolean> {
    return this._loading.asObservable();
  }
}
