import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './components/header/header.component';

import { NavigationEnd, NavigationStart, Router, RouterLink, RouterOutlet } from '@angular/router';
import { StyleClassModule } from 'primeng/styleclass';
import { LoginComponent } from './components/login/login.component';
import { RegisterComponent } from './components/register/register.component';

import { routeTransitionAnimations } from './route-transition-animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LoadingService } from './services/loading.service';
import { MessageService } from 'primeng/api';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { ToastModule } from 'primeng/toast';
import { ButtonModule } from 'primeng/button';
import { TranslateModule } from '@ngx-translate/core';


@Component({
  selector: 'app-root',
  standalone: true,
  imports: [
    HeaderComponent,
    LoginComponent,
    RegisterComponent,
    RouterOutlet,
    StyleClassModule,
    FormsModule,
    ReactiveFormsModule,
    ProgressSpinnerModule,
    ToastModule,
    RouterLink,
    CommonModule,
    ButtonModule,
    TranslateModule
  ],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
  animations: [routeTransitionAnimations],
})
export class AppComponent {
  title = 'site-quiosque';
  isLoading: boolean = false;

  constructor(
    private loadingService: LoadingService,
    private messageService: MessageService,
    private route: Router
  ) {
    this.loadingService.loading$.subscribe((status) => {
      this.isLoading = status;
    });
    route.events.subscribe((val: any) => {
      if (val instanceof NavigationEnd) {
        window.scrollTo(0, 0);
      }
      if(val instanceof NavigationStart){
        if(val.url.includes('parceiros/')){
          console.log('parceiros');
        }
      }
    });
  }

  clear(key: string) {
    this.messageService.clear(key);
  }

  ngOnInit(){
    this.route.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        window.scrollTo(0, 0);
      }
    });
  }
  prepareRoute(outlet: RouterOutlet): boolean {
    return (
      outlet &&
      outlet.activatedRouteData &&
      outlet.activatedRouteData['animationState']
    );
  }

}
