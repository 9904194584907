import { Component } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-validator',
  standalone: true,
  imports: [],
  templateUrl: './validator.component.html',
  styleUrl: './validator.component.scss',
})
export class ValidatorComponent {
  static ptDate(control: FormControl): { [key: string]: any } {
    let ptDatePattern =
      /^(((0[1-9]|[12]\d|3[01])\/(0[13578]|1[02])\/((19|[2-9]\d)\d{2}))|((0[1-9]|[12]\d|30)\/(0[13456789]|1[012])\/((19|[2-9]\d)\d{2}))|((0[1-9]|1\d|2[0-8])\/02\/((19|[2-9]\d)\d{2}))|(29\/02\/((1[6-9]|[2-9]\d)(0[48]|[2468][048]|[13579][26])|((16|[2468][048]|[3579][26])00))))$/g;

    if (!control.value.match(ptDatePattern)) return { ptDate: true };

    let currentYear = new Date().getFullYear();
    let enteredYear = parseInt(control.value.split('/')[2]);
    let enteredMonth = parseInt(control.value.split('/')[1]);
    let enteredDay = parseInt(control.value.split('/')[0]);

    if (enteredYear > currentYear || enteredDay > 31 || enteredMonth > 12)
      return { ptDate: true };

    return {};
  }

  static cpf(control: FormControl): { [key: string]: any } {
    let cpfPattern = /^\d{3}\.\d{3}\.\d{3}-\d{2}$/;

    if (!control.value.match(cpfPattern)) return { cpf: true };

    let cpf = control.value.replace(/[^\d]+/g, '');

    if (cpf.length !== 11 || /^(\d)\1+$/.test(cpf)) return { cpf: true };

    let sum = 0;
    let remainder;

    for (let i = 1; i <= 9; i++) {
      sum = sum + parseInt(cpf.substring(i - 1, i)) * (11 - i);
    }

    remainder = (sum * 10) % 11;

    if (remainder === 10 || remainder === 11) remainder = 0;

    if (remainder !== parseInt(cpf.substring(9, 10))) return { cpf: true };

    sum = 0;

    for (let i = 1; i <= 10; i++) {
      sum = sum + parseInt(cpf.substring(i - 1, i)) * (12 - i);
    }

    remainder = (sum * 10) % 11;

    if (remainder === 10 || remainder === 11) remainder = 0;

    if (remainder !== parseInt(cpf.substring(10, 11))) return { cpf: true };

    return {};
  }

  static mmYYDate(control: FormControl): { [key: string]: any } {
    let mmYYDatePattern = /^(0[1-9]|1[0-2])\/\d{2}$/;

    if (!control.value.match(mmYYDatePattern)) return { mmYYDate: true };

    let currentYear = new Date().getFullYear();
    let currentMonth = new Date().getMonth() + 1;

    // Extrair o mês e o ano do valor do controle
    let [enteredMonth, enteredYear] = control.value.split('/').map(Number);

    // Se o ano for menor que o ano atual ou se o ano for igual e o mês for menor, retorne erro
    if (
      enteredYear + 2000 < currentYear ||
      (enteredYear + 2000 === currentYear && enteredMonth < currentMonth)
    ) {
      return { mmYYDate: true };
    }

    return {}; // Retorne um objeto vazio se a data for válida
  }
}
