<div class="col-12 pt-8">
  <section class="voucher">
    <div class="backRouter flex p-5 pb-2 cursor-pointer">
      <a [routerLink]="['/upload-de-exames']">
        <i class="pi pi-chevron-circle-left"> </i>
        <span>Voltar</span>
      </a>


    </div>


    <div class="lg:col-8 mx-auto py-5">
      <div class="grid pb-4">
        <div class="field mb-4 col-12 md:col-6">
          <label class="block mb-2">Nome</label>
          <input id="name" type="text" placeholder="Nome" pInputText class="w-full md:w-full"
            [(ngModel)]="customers.name" name="name" disabled />
        </div>

        <div class="field col-12 md:col-6">
          <label class="block mb-2">Email</label>
          <input id="email" type="text" placeholder="email@email.com" pInputText class="w-full md:w-full"
            [(ngModel)]="customers.email" name="email" disabled />
        </div>

        <div class="field">
          <p-fileUpload 
          styleClass="fileUpload" 
          name="demo[]" 
          accept="image/*, .pdf, .xlsx" 
          maxFileSize="10000000" 
          [showUploadButton]="false"
          chooseLabel="Selecionar Arquivo"
          cancelLabel="Cancelar"
          (onSelect)="onSelect($event)"
          (onClear)="arquivoInserido = false"
      >
      </p-fileUpload>
      
        </div>

      </div>

      <!-- Table para ser exibida quando clicar no botão de upload -->
      <p-table [value]="customers.examList" [tableStyle]="{ 'min-width': '50rem' }">
        <ng-template pTemplate="header">
          <tr>
            <th>Data</th>
            <th>Nome do Arquivo.extenção</th>
            <th>Arquivo</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-exam>
          <tr>
            <td>{{ exam.data }}</td>
            <td>{{ exam.fileName }}</td>
            <td>
              <a [href]="exam.urlImage" class="imgRedirect">
                  <img src="assets/img/icons/ic_pdf.png" alt="Image" height="40" class="mr-0 lg:mr-6" />
              </a>
            </td>
          </tr>
        </ng-template>
      </p-table>

      <div class="col-12 text-center mt-6">
        <p-button *ngIf="arquivoInserido" label="OK" severity="success" styleClass="p-button-save border-round-3xl"
          (onClick)="saveFile()"></p-button>
      </div>
    </div>
  </section>
</div>
