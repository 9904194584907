import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ResultadosService {

  private apiUrl = environment.apiUrl;

  constructor(private http: HttpClient) { }

  getResultados(): Promise<any>  {
    return this.http.get<any>(`${this.apiUrl}/resultado`,).toPromise();
  }
  
  getUserExams(){
    return this.http.get<any>(`${this.apiUrl}/customer/exams/user`).toPromise();
  }
}
