import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class CustomerService {
  private apiUrl = environment.apiUrl;

  constructor(private http: HttpClient) {}

  getCustomerDemographics(): Promise<any> {
    return this.http
      .get<any>(`${this.apiUrl}/customer/demographics`)
      .toPromise();
  }

  putCustomerDemographics(body: any, id: string): Promise<any> {
    return this.http
      .put<any>(`${this.apiUrl}/customer/demographics/${id}`, body)
      .toPromise();
  }

  postCustomerDemographics(body: any): Promise<any> {
    return this.http
      .post<any>(`${this.apiUrl}/customer/demographics`, body)
      .toPromise();
  }

  getAllEtnias(): Promise<any> {
    return this.http.get<any>(`${this.apiUrl}/list/ethnicity`).toPromise();
  }

  getSexos(): Promise<any> {
    return this.http.get<any>(`${this.apiUrl}/list/sex`).toPromise();
  }

  getSubscriptions(): Promise<any> {
    return this.http
      .get<any>(`${this.apiUrl}/subscriptions/customer`)
      .toPromise();
  }

  getInsuranceList(): Promise<any> {
    return this.http.get<any>(`${this.apiUrl}/health-insurance`).toPromise();
  }

  getCustomerId(): Promise<any> {
    return this.http.get<any>(`${this.apiUrl}/customer`).toPromise();
  }

  async createUserKiosk(body: any): Promise<any> {
    if (!body.birthDate || !body.email || !body.name || !body.phoneNumber) {
      throw new Error('Missing required fields');
    }

    try {
      const response: any = await this.http
        .post(`${this.apiUrl}/customer/quiosque`, body)
        .toPromise();
      return response;
    } catch (error) {
      console.error('Error making post request', error);
      throw error;
    }
  }

  async getCustomerExameByNameEmailCpf(searchTerm: string, pageNumber: number, pageSize: number): Promise<any> {
    return this.http.get(`${this.apiUrl}/customer/exam/term/${pageNumber}/${pageSize}?term=${searchTerm}`).toPromise();
    // return this.http.get(`/api/customer-exam?term=${searchTerm}`).toPromise();
  }

  async getCustomerExam(searchTerm: string): Promise<any> {
    return this.http.get(`${this.apiUrl}/customer/exam/user/${searchTerm}`).toPromise();
  }

  async getPaginatedCustomer(
    pageNumber: number,
    pageSize: number,
    searchTerm?: string
  ): Promise<any> {
    return this.http
      .get<any>(`${this.apiUrl}/customer/${pageNumber}/${pageSize}?term=${searchTerm}`)
      .toPromise();
  }

  async UploadFile(uploadData: any): Promise<any> {
    try {
      const response = await this.http.post<any>(`${this.apiUrl}/customer/uploadexame`, uploadData).toPromise();
      console.log('Arquivo enviado com sucesso!', response);
      return response; // Retornar a resposta do servidor para o componente (opcional)
    } catch (error) {
      console.error('Erro ao enviar arquivo:', error);
      throw error; // Lançar o erro para o componente que chamou a função
    }
  }
}
