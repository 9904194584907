import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class MyCouponService {
  private apiUrl = environment.apiUrl;

  constructor(private http: HttpClient) { }


  getVoucherByCustomer(): Promise<any> {
    return this.http.get<any>(`${this.apiUrl}/customer/voucher`).toPromise();
  }

  getOneCupomById(id:string | null | undefined): Promise<any> {
    return this.http.get<any>(`${this.apiUrl}/customer/voucher/${id}`).toPromise();
  }

  postVouchertoCustomer(body:any): Promise<any> {
    return this.http.post<any>(`${this.apiUrl}/customer/voucher`, body).toPromise();
  }


  cancelCupomRequest(body:any): Promise<any> {
    return this.http.post<any>(`${this.apiUrl}/cancelSubscriptionVoucher`, body).toPromise();
  }
}
