<div class="col-12 lg:col-6 mx-auto px-5">
    <div class="text-center my-5">
        <div class="text-title mb-3 pb-6">Agora, precisaremos que nos responda a 
            algumas perguntas para que possamos uma melhor 
            triagem oftalmológica:
        </div>

        <div class="flex justify-content-center ">
            <p-button label="Prefiro responder depois" severity="success" styleClass="p-button-after "></p-button>

            <p-button label="Responder agora" severity="help" styleClass="p-button-save ml-3" routerLink="/endereco"></p-button>
        </div>
    </div>
</div>

<!-- <div [@slideInOut]="questionsVisible ? 'in' : 'out'" class="slide-container">
    
    @if(questionsVisible){
        <address></address>
    }
</div> -->
