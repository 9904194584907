import { CommonModule } from '@angular/common';
import { CustomerService } from './../../../services/customer.service';
import { Component, Input } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { Router, RouterLink } from '@angular/router';

// PrimeNG
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { RadioButtonModule } from 'primeng/radiobutton';
import { CheckboxModule } from 'primeng/checkbox';
import { PasswordModule } from 'primeng/password';
import { LoadingService } from '../../../services/loading.service';

@Component({
  selector: 'app-income',
  standalone: true,
  imports: [
    CommonModule,
    ButtonModule,
    CheckboxModule,
    DialogModule,
    FormsModule,
    PasswordModule,
    RouterLink,
    DropdownModule,
    ReactiveFormsModule,
    RadioButtonModule,
  ],
  templateUrl: './income.component.html',
  styleUrl: './income.component.scss',
})
export class IncomeComponent {
  salary = [
    { salario: 'Até R$ 1.000,00', value: 1 },
    { salario: 'Até R$ 3.000,00', value: 2 },
    { salario: 'Até R$ 5.000,00', value: 3 },
    { salario: 'Acima de R$ 5.000,00', value: 4 }
  ];

  radioForm: any = [
    { name: 'Sim', value: true },
    { name: 'Não', value: false },
  ];

  validateForm!: FormGroup;
  demography: any = {};

  etnias: any = [];
  sexos: any = [];
  isEdit: boolean;

  healthInsurance!: string;

  plan: any = []

  visible: boolean = false;
  isMobile: boolean = false;

  showDialog() {
    this.visible = true;
  }

  constructor(
    private customerService: CustomerService,
    private fb: FormBuilder,
    private router: Router,
    private loadingService: LoadingService
  ) {
    this.createValidateForm();
  }

  async ngOnInit() {
    this.loadingService.show();
    this.etnias = await this.getEtnias();
    this.sexos = await this.getListaSexos();
    const income = await this.getCustomerIncome();
    this.plan = await this.getInsuranceList();
    const sortedPlans = this.plan
      .filter(plan => plan.name !== "Outros")
      .sort((a, b) => a.name.localeCompare(b.name));

    const outrosPlan = this.plan.find(plan => plan.name === "Outros");

    if (outrosPlan) {
      sortedPlans.push(outrosPlan);
    }

    this.plan = sortedPlans;

    this.demography = income[0];
    if(income[0]){
      this.isEdit = true;
      this.validateForm.patchValue(this.demography);
      this.validateForm.patchValue({ healthInsuranceId: this.demography.healthInsurance?.id });
    }
    // const url = window.location.href;
    // if (url.includes('editar')) {
    //   this.isEdit = true;
    // } else {
    //   this.isEdit = false;
    // }
    this.loadingService.hide();
  }

  createValidateForm() {
    this.validateForm = this.fb.group({
      sex: ['', [Validators.required]],
      ethnicity: ['', [Validators.required]],
      householdIncome: ['', [Validators.required]],
      hasHealthInsurance: [false, [Validators.required]],
      healthInsuranceId: [''],
    });
  }

  verifyIsMobile() {
    if (typeof window !== 'undefined') {
      this.checkWidth();
      window.addEventListener('resize', () => {
        this.checkWidth();
      });
    }
  }

  onChangeRadio(event: any) {
    if (event === true) {
      this.validateForm.get('healthInsuranceId')?.setValidators([Validators.required]);
    } else {
      this.validateForm.get('healthInsuranceId')?.clearValidators();
    }
    this.validateForm.get('healthInsuranceId')?.updateValueAndValidity();
  }

  checkWidth() {
    if (typeof window !== 'undefined') {
      this.isMobile = window.innerWidth < 992;
    }
  }

  async getCustomerIncome() {
    try {
      return await this.customerService.getCustomerDemographics();
    } catch (error) {
      console.log(error);
    }
  }

  async submitForm() {
    this.loadingService.show();
    if (this.validateForm.valid && !this.isEdit) {
      try {
        const resp = await this.customerService.postCustomerDemographics({
          ...this.validateForm.value,
          customerId: JSON.parse(sessionStorage.getItem('token') || '{}')
            ?.customer?.id,
        });
        if (resp) {
          this.router.navigate(['/anamnese']);
          this.loadingService.hide();
        } else {
        }
      } catch (error) { }

      Object.values(this.validateForm.controls).forEach((control) => {
        if (control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });
        }
      });
    } else if (this.isEdit && this.validateForm.valid) {
      try {
        const l = JSON.parse(sessionStorage.getItem('user') || '{}');
        this.customerService
          .putCustomerDemographics(
            { ...this.validateForm.value, customerId: l.id },
            this.demography.id
          )
          .then((resp) => {
            if (window.location.href.includes('editar')) {
              this.router.navigate(['/minha-conta']);
            } else {
              this.router.navigate(['/anamnese']);
            }
            this.loadingService.hide();
          })
          .catch((error) => {
            this.loadingService.hide();
            this.loadingService.notify('Erro ao atualizar', error);
          });
      } catch (error) { }
      this.loadingService.hide();
    }
  }

  async getEtnias() {
    try {
      return await this.customerService.getAllEtnias();
    } catch (error) {
      console.log(error);
    }
  }

  async getInsuranceList() {
    try {
      return await this.customerService.getInsuranceList();
    } catch (error) {
      console.log(error);
    }
  }

  async getListaSexos() {
    try {
      return await this.customerService.getSexos();
    } catch (error) {
      console.log(error);
    }
  }
}
