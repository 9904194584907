<div class="main" [ngStyle]="{ height: !isMobile && !selectedPayment ? '100vh' : '' }"
  [ngStyle]="{ height: isMobile ? '100vh' : '' }">
  <div class="flex justify-content-between py-5 px-7">
    <div class="flex align-items-center">
      <i class="pi pi-chevron-circle-left inline-block "></i>
      <span class="ml-3">Voltar</span>
    </div>

    <div class="flex">
      <i class="pi pi-sign-out inline-block"></i>
      <span class="ml-2">Sair</span>
    </div>
  </div>
  <div class="col-12">
    <!-- <div class="ml-3">
    <back-router></back-router>
  </div> -->
    <section id="pagamento">
      @if(!isCupom){
      <div class="col-10 mx-auto mx-2">
        <h2 class="border-bottom-1 border-400" [ngClass]="{ '': !isMobile, 'text-center': isMobile }">
          Opções de assinatura
        </h2>

        <div class="flex col-12 flex-wrap gap-3">
          <div class="card flex justify-content-center">
            <div class="flex flex-column gap-3">
              <div *ngFor="let item of subscriptions" class="field-checkbox">
                <p-radioButton [inputId]="item.id" name="item" [value]="item" [(ngModel)]="selectedItem"
                  (onClick)="mudarValorParcelasPorCategoria()"></p-radioButton>
                <label [for]="item.id" class="ml-2 radio">{{ item.name }}:&nbsp;{{ formatValue(item.value) }} /
                  mês</label>
              </div>
            </div>
          </div>
        </div>
      </div>
      }
      @if(valorTotalCarrinho !== 0 || !isCupom){
      <div class="col-10 mx-auto">
        <h2 class="border-bottom-1 border-400" [ngClass]="{ '': !isMobile, 'text-center': isMobile }">
          Forma de pagamento
        </h2>

        <div class="col-12 lg:col-10 mx-auto">
          <label>Selecione a forma de pagamento</label>
          <p-dropdown [options]="payments" [(ngModel)]="selectedPayment" optionLabel="name" optionValue="value"
            [showClear]="true" placeholder="Selecione"></p-dropdown>
        </div>
      </div>
      }
      @else {
      <div class="col-10 mx-auto">
        <h2 class="border-bottom-1 border-400" [ngClass]="{ '': !isMobile, 'text-center': isMobile }">
          Confira o resumo do pedido
        </h2>

        <div class="col-12 lg:col-8 mx-auto">
          <p-card>
            <ng-template pTemplate="header">
              <div class="overflow-hidden">
                <div class="title-card py-3 text-center">
                  <h3 class="m-0">Resumo do pedido</h3>
                </div>
              </div>
            </ng-template>
            <div class="grid pt-4">
              @if(isCupom){
              <div class="w-full">
                @for (item of cupons; track $index) {
                <div class="col-12 flex justify-content-between align-items-center flex-wrap pt-1">
                  <div class="justify-content-start texto-card">
                    <p class="m-0 item-card">
                      {{
                      item.voucherType?.name ??
                      "Cupom " + item.voucherType.name
                      }}&nbsp; x{{ item.quantity }}
                    </p>
                  </div>
                  <div class="flex justify-content-end align-items-center texto-card">
                    <p class="m-0 item-card">
                      {{ item.quantity }}x&nbsp;{{ formatValue(valorTotalCarrinho) }}
                    </p>
                  </div>
                </div>
                }
              </div>
              }@else {
              <div class="w-full">
                <div class="col-12 flex justify-content-between align-items-center flex-wrap pt-1">
                  <div class="justify-content-start texto-card">
                    <p class="m-0 item-card">
                      {{ selectedItem?.name }}
                    </p>
                  </div>
                  <div class="flex justify-content-end align-items-center texto-card">
                    <p class="m-0 item-card">
                      {{ formatValue(selectedItem?.value) }}
                    </p>
                  </div>
                </div>
              </div>
              }

              <div class="col-12 border-bottom-1 border-300"></div>
              <div class="col-12 flex justify-content-between align-items-center flex-wrap pt-4">
                <div class="flex justify-content-start align-items-center texto-card">
                  <p class="m-0 item-card text-lg">Total a pagar</p>
                </div>
                @if(isCupom){
                <div class="flex justify-content-end align-items-center texto-card">
                  <p class="m-0 item-card text-lg">
                    {{ formatValue(valorTotalCarrinho) }}
                  </p>
                </div>
                }@else {
                <div class="flex justify-content-end align-items-center texto-card">
                  <p class="m-0 item-card text-lg">
                    {{ formatValue(valorTotalCarrinho) }}
                  </p>
                </div>
                }
              </div>
            </div>
          </p-card>
        </div>
        <div class="text-center mx-auto col-8 py-6">
          <p-button label="Finalizar pedido" [outlined]="false" [rounded]="true" severity="secondary"
            styleClass="p-button-outlined white p-button-strong w-full lg:w-auto button-banner px-4"
            (onClick)="submitForm()"></p-button>
        </div>
      </div>
      }
      @if(selectedPayment && convertToNumber(selectedPayment) == 1){
      <div class="col-10 mx-auto">
        <form [formGroup]="cartaoCreditoForm">
          <h2 class="border-bottom-1 border-400">Dados do cartão</h2>
          <div class="col-12 lg:col-10 mx-auto">
            <div class="formgrid grid">
              <div class="field col-12 lg:col-3">
                <label class="block mb-2">CPF</label>
                <p-inputMask mask="999.999.999-99" formControlName="cpf" placeholder="000.000.000-00" type="text"
                  class="w-full md:w-full lg:w-full mb-3" id="cep"></p-inputMask>
              </div>
              <div class="field col-12 lg:col-4 align-content-center">
                <label class="block mb-2">Número do cartão</label>
                <span class="p-input-icon-right w-full">
                  <i class="custom-icon" style="
                      background-image: url({{currentIcon}});
                      width: 42px;
                      height: 21px;
                      background-size: cover;
                      background-position: center;
                    "></i>
                  <input id="card" #card type="text" formControlName="cardNumber" pInputText
                    class="w-full md:w-full h-100% mb-3" (input)="onInputChange($event)" [placeholder]="isAmex(card.value) ? '0000 000000 00000' : '0000 0000 0000 0000'" />
                </span>
              </div>
              <div class="field col-6 lg:col-2">
                <label class="block mb-2">Validade</label>
                <p-inputMask mask="99/99" formControlName="cardExpiration" placeholder="mm/yy" type="text"
                  class="w-full md:w-full mb-3" id="cep"></p-inputMask>
                @if(cartaoCreditoForm.get('cardExpiration')?.invalid &&
                cartaoCreditoForm.get('cardExpiration')?.touched){
                <div class="alert alert-danger">Data inválida</div>
                }
              </div>
              <div class="field col-6 lg:col-3">
                <label class="block mb-2">CVV/CVC</label>
                <p-inputMask [mask]="isAmex(card.value) ? '9999' : '999'" formControlName="cvv" [placeholder]="isAmex(card.value) ? '0000' : '000'" type="text"
                  class="w-full md:w-full mb-3" id="cep"></p-inputMask>
              </div>
              <div class="field col-12 lg:col-6">
                <label class="block mb-2">Nome do titular do cartão</label>
                <input id="address" type="text" formControlName="name" placeholder="Digite" pInputText
                  (onComplete)="getCardToken($event)" class="w-full md:w-full h-100% mb-3" />
              </div>
              <div class="field col-12 lg:col-6">
                <label>Quantidade de parcelas</label>
                <p-dropdown [options]="parcelas" formControlName="parcelas" optionLabel="name" optionValue="value"
                  [showClear]="true" placeholder="Parcelas" (onChange)="mudarValorParcela($event)">
                  <ng-template pTemplate="selectedItem">
                    @if(cartaoCreditoForm.value.parcelas){
                    <div class="flex align-items-center gap-2">
                      <div class="col-12 flex justify-content-between align-items-center flex-wrap">
                        <div class="flex justify-content-start align-items-center texto-card">
                          <p class="m-0 item-card">
                            {{ cartaoCreditoForm.value.parcelas }}x
                          </p>
                        </div>
                        <div class="flex justify-content-end align-items-center texto-card">
                          <p class="m-0 item-card">
                            {{
                            formatValue(
                            valorTotalItens
                            )
                            }}
                          </p>
                        </div>
                      </div>
                    </div>
                    }
                  </ng-template>
                  <ng-template let-parcela pTemplate="item">
                    <div class="flex align-items-center gap-2">
                      <div class="col-12 flex justify-content-between align-items-center flex-wrap pt-1">
                        <div class="flex justify-content-start align-items-center texto-card">
                          <p class="m-0 item-card">{{ parcela.value }}x</p>
                        </div>
                        <div class="flex justify-content-end align-items-center texto-card">
                          <p class="m-0 item-card">
                            {{ formatValue(valorTotalItens/parcela.value) }}
                          </p>
                        </div>
                      </div>
                    </div>
                  </ng-template>
                </p-dropdown>
              </div>
            </div>
          </div>
        </form>
      </div>
      @if (cartaoCreditoForm.valid) {
      <div class="col-10 mx-auto">
        <div class="col-12 lg:col-8 mx-auto">
          <p-card>
            <ng-template pTemplate="header">
              <div class="overflow-hidden">
                <div class="title-card py-3 text-center">
                  <h3 class="m-0">Resumo do pedido</h3>
                </div>
              </div>
            </ng-template>
            <div class="grid pt-4">
              @if(isCupom){
              <div class="w-full">
                @for (item of cupons; track $index) {
                <div class="col-12 flex justify-content-between align-items-center flex-wrap pt-1">
                  <div class="justify-content-start texto-card">
                    <p class="m-0 item-card">
                      {{
                      item.voucherType?.name ??
                      "Cupom " + item.voucherType.name
                      }}&nbsp; x{{ item.quantity }}
                    </p>
                  </div>
                  <div class="flex justify-content-end align-items-center texto-card">
                    <p class="m-0 item-card">
                      {{ item.quantity }}x&nbsp;{{ formatValue(item.value) }}
                      <!-- {{ formatValue(item.value) || formatValue(item.price) }} -->
                    </p>
                  </div>
                </div>
                }
              </div>
              }@else {
              <div class="w-full">
                <div class="col-12 flex justify-content-between align-items-center flex-wrap pt-1">
                  <div class="justify-content-start texto-card">
                    <p class="m-0 item-card">
                      {{ selectedItem?.name }}
                    </p>
                  </div>
                  <div class="flex justify-content-end align-items-center texto-card">
                    <p class="m-0 item-card">
                      {{ formatValue(selectedItem?.value) }}
                    </p>
                  </div>
                </div>
              </div>
              }

              <div class="col-12 border-bottom-1 border-300"></div>
              <div class="col-12 flex justify-content-end flex-wrap">
                <div class="col-6 lg:col lg:text-center py-2 texto-card">
                  <p class="m-0 font-light text-left pb-2">
                    Forma de pagamento:
                  </p>
                  <p class="m-0 py-2 text-base font-light text-left">
                    Cartão de crédito
                  </p>
                </div>

                <div
                  class="col-6 lg:col text-center align-items-center align-content-center lg:justify-content-end texto-card">
                  <div class="flex justify-content-end align-items-center pb-2">
                    <i class="pi pi-credit-card pr-2" style="color: #4c4892"></i>
                    <p class="m-0">{{ cartaoCreditoForm.value.parcelas }}x</p>
                  </div>
                  <div class="flex justify-content-end align-items-center">
                    <p class="m-0">
                      {{
                      formatValue(
                      valorTotalItens / cartaoCreditoForm.value.parcelas
                      )
                      }}
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-12 border-bottom-1 border-300"></div>
              <div class="col-12 flex justify-content-between align-items-center flex-wrap pt-4">
                <div class="flex justify-content-start align-items-center texto-card">
                  <p class="m-0 item-card text-lg">Total a pagar</p>
                </div>
                @if(isCupom){
                <div class="flex justify-content-end align-items-center texto-card">
                  <p class="m-0 item-card text-lg">
                    {{ formatValue(valorTotalItens) }}
                  </p>
                </div>
                }@else {
                <div class="flex justify-content-end align-items-center texto-card">
                  <p class="m-0 item-card text-lg">
                    {{ formatValue(selectedItem?.value) }}
                  </p>
                </div>
                }
              </div>
            </div>
          </p-card>
        </div>
      </div>
      }@if (cartaoCreditoForm.valid) {
      <div class="text-center mx-auto col-8 py-6">
        <p-button label="Finalizar pagamento" [outlined]="false" [rounded]="true" severity="secondary"
          styleClass="p-button-outlined white p-button-strong w-full lg:w-auto button-banner px-4"
          (onClick)="submitForm()"></p-button>
      </div>
      } } @if(selectedPayment && convertToNumber(selectedPayment) == 2){ @if(pixGerado){
      <div class="col-10 mx-auto">
        <h2 class="border-bottom-1 border-400" [ngClass]="{ '': !isMobile, 'text-center': isMobile }">
          Dados do Pix
        </h2>
        <div class="col-12 pt-2">
          <div>
            <p class="mini-text my-0 font-semibold text-sm">
              Pagamento via pix:
            </p>
          </div>
          <div>
            <div class="flex padding-top justify-content-center align-items-center">
              <img src="/assets/img/bg/pix_logo.png" alt="icone-resultados" />
            </div>
          </div>
          <div class="pt-5">
            <p class="mini-text my-0 font-semibold text-sm">
              Pedido aguardando pagamento
            </p>
          </div>
          <div class="flex padding-top justify-content-center align-items-center">
            @if(pixGerado){
            <qrcode [qrdata]="pixGerado" [width]="180" [errorCorrectionLevel]="'M'" [allowEmptyString]="true">
            </qrcode>
            }
          </div>
          <div class="pt-5">
            <p class="text-explain px-2 my-0">
              Copie o código abaixo e utilize o Pix Copia e Cola no aplicativo
              que você vai fazer o pagamento:
            </p>
          </div>
          <div class="col-12 lg:col-6 lg:mx-auto">
            <div
              class="col-12 lg:col-6 lg:mx-auto align-items-center mt-2 border-round-sm border-2 cursor-pointer bg-white mx-2"
              (click)="copiarTexto()" [ngClass]="{
                'border-dashed border-gray-300': !isCopied,
                copied: isCopied
              }">
              <div class="p-1 pl-6 flex flex-wrap justify-content-between">
                <div class="flex justify-content-center flex-wrap align-items-center">
                  <div class="flex justify-content-center flex-wrap align-items-center" id="copyDiv">
                    <p class="m-0 text-xs lg:text-lg font-semibold" style="overflow-wrap: anywhere;">
                      {{pixGerado}}
                    </p>
                  </div>
                </div>
                <div class="flex justify-content-end align-items-center">
                  <i class="pi pi-copy pr-2 lg:text-2xl" style="color: #47a09e"></i>
                </div>
              </div>
            </div>
            @if(isCopied){
            <div class="pl-2 pt-1 text-xs text-center" style="color: #47a09e">
              Copiado!
            </div>
            }
          </div>
        </div>
      </div>
      <div class="col-10 mx-auto">
        <div class="col-12 lg:col-8 mx-auto">
          <p-card>
            <ng-template pTemplate="header">
              <div class="overflow-hidden">
                <div class="title-card py-3 text-center">
                  <h3 class="m-0">Resumo do pedido</h3>
                </div>
              </div>
            </ng-template>
            <div class="grid">
              <div class="w-full">
                @for (item of cupons; track $index) {
                <div class="col-12 flex justify-content-between align-items-center flex-wrap pt-3">
                  <div class="justify-content-start texto-card">
                    <p class="m-0 item-card">
                      {{
                      item.voucherType?.name ??
                      "Cupom " + item.voucherType.name
                      }}&nbsp; x{{ item.quantity }}
                    </p>
                  </div>
                  <div class="flex justify-content-end align-items-center texto-card">
                    <p class="m-0 item-card">
                      {{ item.quantity }}x&nbsp;{{ formatValue(item.value) }}
                      <!-- {{ formatValue(item.value) || formatValue(item.price) }} -->
                    </p>
                  </div>
                </div>
                }
              </div>
              <div class="col-12 border-bottom-1 border-300"></div>
              <div class="col-12 flex justify-content-end flex-wrap">
                <div class="col-6 lg:col lg:text-center py-2 texto-card">
                  <p class="m-0 font-light text-left">Forma de pagamento:</p>
                  <p class="m-0 py-2 font-light text-left">Pix</p>
                </div>

                <div
                  class="flex col-6 lg:col text-center align-items-center align-content-center justify-content-end texto-card">
                  <div class="flex flex-column justify-content-end align-items-center pr-2">
                    <img src="/assets/img/icons/ic_pix_detalhe.svg" style="width: 16px; height: 16px" />
                  </div>
                  <div class="flex justify-content-end align-items-center">
                    <p class="m-0">à vista {{ formatValue(valorTotalItens) }}</p>
                  </div>
                </div>
              </div>
              <div class="col-12 border-bottom-1 border-300"></div>
              <div class="col-12 flex justify-content-between align-items-center flex-wrap pt-4">
                <div class="flex justify-content-start align-items-center texto-card">
                  <p class="m-0 item-card text-lg">Total a pagar</p>
                </div>
                @if(isCupom){
                <div class="flex justify-content-end align-items-center texto-card">
                  <p class="m-0 item-card text-lg">
                    {{ formatValue(valorTotalItens) }}
                  </p>
                </div>
                }@else {
                <div class="flex justify-content-end align-items-center texto-card">
                  <p class="m-0 item-card text-lg">
                    {{ formatValue(selectedItem?.value) }}
                  </p>
                </div>
                }
              </div>
            </div>
          </p-card>
        </div>
      </div>
      }@else{
      <div class="col-8 mx-auto pt-4 text-center">
        <p-button label="Gerar QR Code" [outlined]="false" [rounded]="true" severity="secondary"
          styleClass="p-button-outlined white p-button-strong w-full lg:w-auto button-banner px-4"
          (onClick)="submitPix()"></p-button>
      </div>
      } }
    </section>
  </div>
</div>
