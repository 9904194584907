<!-- @if(layoutType == 0){
<div class="pt-8 main" style="background-color: #78cdd1">
  <div
    class="col-12 lg:col-10 mx-auto px-0 lg:pt-6 pb-4 lg:align-items-center lg:justify-content-center lg:grid"
    [ngStyle]="{ height: listPartner.length <= 0 ? '57vh' : '' }"
  >
    <div class="md:col-6 lg:col-8">
      <div
        class="backRouter p-4 pt-6 cursor-pointer vertical-align-center flex"
      >
        <i class="pi pi-chevron-circle-left"> </i>
        <a [routerLink]="['/parceiros']">
          <span>Voltar</span>
        </a>
      </div>
      <div class="p-0 result">
        @if(listPartner.length >= 1){
        <div class="grid gap-3 justify-content-center">
          @for (item of listPartner; track $index) {
          <div class="col-5 lg:col-3 overflow-hidden p-1">
            <div
              class="shadow-2 bg-contain bg-center bg-no-repeat border-circle"
              style="
                  background-image: url({{item.urlPhoto}});"
            ></div>
          </div>
          }
        </div>
        }@else {
        <div class="col-8 py-6 mx-auto vertical-align-center mt-8 text-center">
          <span class="font-normal text-white text-xl"
            >Não há itens cadastrados</span
          >
        </div>
        }
      </div>
    </div>
  </div>
  <div
    class="md:col-6 lg:col-4 lg:hidden bg-mobile-client flex justify-content-end"
    style="width: 100%; height: 300px"
  >
    <div class="flex align-items-center" [style]="{ width: '70%' }">
      <div class="w-full relative"></div>
    </div>
  </div>
</div>
}@else { -->
<div class="flex justify-content-between py-4 px-7 pt-7" *ngIf="!isMobile">
  <div class="flex align-items-center">
    <i class="pi pi-chevron-circle-left inline-block"></i>
    <span class="ml-3">Voltar</span>
  </div>

  <div class="flex">
    <i class="pi pi-sign-out inline-block"></i>
    <span class="ml-2">Voltar</span>
  </div>
</div>

<!-- layout - 1 -->
<div class="lg:col-12 h-screen screen" [ngClass]="{ '': !isMobile, 'pt-8': isMobile }">
  <div class="backRouter p-4 pt-6 cursor-pointer flex">
    <i class="pi pi-chevron-circle-left"> </i>
    <a [routerLink]="['/parceiros']">
      <span>Voltar</span>
    </a>
  </div>
  @if(listPartner.length>0){
  <section class="voucher pb-5 lg:pb-0">
    <div class="col-10 lg:col-9 grid mb-8 mt-3 mx-auto gap-4 justify-content-center">
      @for (item of listPartner; track $index) {
      <div class="shadow-3 card-partner lg:col-3 col-12 p-0 cursor-pointer bg-white"
        (click)="visible = true; selectedItem = item">
        <div class="blue-side text-center flex-column flex py-4">
          <strong class="text-white text-xl">{{ item.name }}</strong>
          <span class="text-base mt-2 font-semibold text-white">
            {{ item?.specialty?.name ?? item?.category?.name }}
          </span>
          <span class="text-sm mt-2 font-medium text-white">
            {{ item?.subspecialty?.name ?? "" }}
          </span>
        </div>
        @if(item.address){
        <div class="bg-white py-4 text-center white-side">
          <p class="my-0">
            {{ item?.address[0]?.neighborhood }}<br />
            {{ item?.address[0]?.city }} - {{ item?.address[0]?.state }}
          </p>
        </div>
        } @else {
        <p class="line-height-3 text-center">Não há endereço cadastrado</p>
        }
      </div>
      }
    </div>
  </section>
  }@else {
  <div class="col-8 py-6 mx-auto pt-8 text-center semItens">
    <span class="font-normal text-black text-xl">Não há itens cadastrados</span>
  </div>
  }
</div>
<!-- } -->

<p-dialog header="header" [(visible)]="visible" [modal]="true" [breakpoints]="{ '960px': '85vw' }"
  [style]="{ width: '50vw' }" [draggable]="false" [resizable]="false">
  <ng-template pTemplate="header">
    <div class="inline-flex align-items-center justify-content-center gap-1">
      <img [src]="selectedItem?.urlPhoto" class="mr-3"
        style="width: 100px; height: 100px; border-radius: 50%; object-fit: cover;" alt="foto-parceiro" />
      <div class="text-white flex flex-column">
        <span class="mb-2 text-2xl font-bold">{{
          selectedItem?.doctorClinicName ?? selectedItem?.name
          }}</span>
        <span class="mb-2 text-lg">{{
          selectedItem?.specialty?.name ?? selectedItem?.category?.name
          }}</span>
        @if(selectedItem?.crm){
        <span class="text-sm"><strong>CRM: </strong>{{ selectedItem?.crm }}</span>
        }
      </div>
    </div>
  </ng-template>
  <div class="col-12 partner-popup">
    @if (selectedItem?.subspecialty || selectedItem?.subspecialty2) {
    <div class="mx-4">
      <h2 class="border-bottom-1 border-400 text-left text-lg lg:px-1">
        Áreas de atuação
      </h2>
      <p class="text-left text-base font-normal">
        {{ selectedItem?.subspecialty?.name }}
      </p>
      @if(selectedItem?.subspecialty2){
      <p class="text-left text-base font-normal">
        {{ selectedItem?.subspecialty2?.name }}
      </p>
      }
    </div>
    } @if (selectedItem?.category?.name) {
    <div class="mx-4">
      <h2 class="border-bottom-1 border-400 text-left text-lg lg:px-1">
        Tipo de serviço
      </h2>
      <p class="text-left font-normal text-base">
        {{ selectedItem?.category?.name }}
      </p>
    </div>
    }
    <div class="mx-4">
      <h2 class="border-bottom-1 border-400 text-left text-lg">Contatos</h2>
      <p class="text-base text-left font-normal">
        <strong class="subtl">celular: </strong>
        <a [href]="'tel:' + sanitizePhoneNumber(selectedItem?.phone1)">{{
          selectedItem?.phone1
          }}</a>
      </p>
      <p class="text-base text-left font-normal mt-0">
        <strong class="subtl">E-mail: </strong>
        <a [href]="'mailto:' + selectedItem?.email">{{
          selectedItem?.email
          }}</a>
      </p>
      @if(selectedItem?.site || selectedItem?.midia1 || selectedItem?.midia2){
      <p class="text-base text-left font-normal mt-0">
        <strong class="subtl">Web: </strong>
        <br />
        <a [href]="'https://' + selectedItem?.site">{{ selectedItem?.site }}</a>
        <br /><a [href]="'https://' + selectedItem?.midia1"> {{ selectedItem?.midia1 }}</a>
        <br />
        <a [href]="'https://' + selectedItem?.midia2">{{selectedItem?.midia2}}</a>
      </p>
      }
    </div>
    @if (selectedItem?.address?.length > 0) {
    <div class="mx-4">
      <h2 class="border-bottom-1 border-400 text-left text-lg">Localização</h2>
      <p class="text-left text-base font-normal" *ngIf="selectedItem?.address?.length > 0">
        {{ selectedItem?.address[0]?.street }},
        {{ selectedItem?.address[0]?.number }} -
        {{ selectedItem?.address[0]?.neighborhood }} -
        {{ selectedItem?.address[0]?.city }} 
        @if(selectedItem?.address[0]?.complement){
        <br />{{ selectedItem?.address[0]?.complement }}
        }
      </p>
    </div>
    } @if (selectedItem?.about) {
    <div class="pt-2 pb-2 mx-4">
      <h2 class="border-bottom-1 border-400 text-left text-lg">Descrição</h2>
      <p class="text-base text-left font-normal" style="word-wrap: break-word">
        {{ selectedItem?.about }}
      </p>
    </div>
    }
  </div>
</p-dialog>