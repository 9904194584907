<div class="col-12 pt-8">
  <section class="voucher">
    <div class="px-3">
      <back-router></back-router>
    </div>

    <div class="lg:col-11 col-12 mx-auto conteudo-principal">
      <h2 class="border-bottom-1 border-400 text-center xl:text-left">
        Todos os cupons
      </h2>
      <div
        class="grid lg:gap-6 flex-column md:flex-row mt-3 justify-content-center lg:px-0 lg:mx-6 px-5 lg:px-0"
      >
        @for (item of vouchers; track $index) {
        <div class="col-12 lg:col-3">
          <div
            class="mb-3 md:mb-0 mr-0 md:mr-2 md:pr-5 flex flex-column flex-grow-1"
            (click)="validateClickAndNavigate(item.id)"
          >
            <div class="bg-card-header text-center p-3 cursor-pointer">
              <div
                class="flex align-items-center surface-border cursor-pointer py-2"
              >
                <div class="round-black">
                  <img
                    [src]="item.category.urlImage"
                    class="mx-3 my-1"
                    style="width: 60px; height: 60px"
                  />
                </div>
                <div class="flex flex-column align-items-start">
                  <span class="text-xl text-white font-medium mb-1">{{
                    item?.category?.name
                  }}</span>
                  <span class="text-xs text-white font-medium mb-1 text-left">{{
                    item.partner ? item?.partner?.name : ""
                  }}</span>
                </div>
              </div>
            </div>
            <div
              class="p-4 flex flex-column flex-grow-1 justify-content-between shadow-2 border-round-bottom-3xl shadow-none border-none card-content cursor-pointer area-card-branco hover:shadow-5"
              style="min-height: 188px"
            >
              <div
                class="font-medium text-base font-light line-height-2 mb-4 pr-1 text-black-alpha-70"
              >
                {{ item.description | slice : 0 : 110
                }}{{ item.description.length > 110 ? "..." : "" }}
              </div>

              <ul class="list-none p-0 m-0 mb-5 text-bluegray-600">
                <li class="flex align-items-center mb-1">
                  <span>Valor:</span>
                  <span class="font-bold"
                    >&nbsp;{{ formatCurrency(item.value) }}</span
                  >
                </li>
                <li class="flex align-items-center">
                  <span>Válido até:&nbsp;</span>
                  <span class="font-bold">{{
                    calculateFutureDate(item.validity)
                  }}</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
        }
      </div>
    </div>
  </section>
</div>
