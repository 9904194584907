import { LoadingService } from './../../services/loading.service';
import { AuthService } from './../../services/auth.service';
import { Router, RouterLink } from '@angular/router';
import { Component } from '@angular/core';
import { CardModule } from 'primeng/card';
import { ButtonModule } from 'primeng/button';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { TranslateService } from '@ngx-translate/core';
import { PrimeNGConfig } from 'primeng/api';
import { Subscription } from 'rxjs';
import { DialogModule } from 'primeng/dialog';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-redefinir-senha',
  standalone: true,
  imports: [
    CommonModule,
    CardModule,
    ButtonModule,
    RouterLink,
    FormsModule,
    ReactiveFormsModule,
    ProgressSpinnerModule,
    DialogModule,
  ],
  templateUrl: './redefinir-senha.component.html',
  styleUrl: './redefinir-senha.component.scss',
})
export class RedefinirSenhaComponent {
  validateForm: FormGroup;
  lang: string = 'pt-br';
  subscription: Subscription;
  confirmModal: boolean = false;
  email: string = '';
  token: string = '';
  idUser: string = '';
  isMobile: boolean = false;
  isQuiosque: boolean = false;

  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private translateService: TranslateService,
    public primeNGConfig: PrimeNGConfig,
    public translate: TranslateService,
    private loadingService: LoadingService,
    private route: Router
  ) {
    this.createValidateForm();
    translate.addLangs(['pt-br', 'en']);
    translate.setDefaultLang('pt-br');

    const browserLang = translate.getBrowserLang();
    let lang =
      browserLang && browserLang.match(/pt-br|en/) ? browserLang : 'pt-br';
    this.changeLang(lang);

    this.subscription = this.translate.stream('primeng').subscribe((data) => {
      this.primeNGConfig.setTranslation(data);
    });
  }

  ngOnInit(): void {
    this.isQuiosque = this.route.url.includes('quiosque')
    if(this.isQuiosque) {
      sessionStorage.setItem('quiosque', "true");
    }
    this.checkWidth();
    window.addEventListener('resize', () => {
      this.checkWidth();
    });
  }

  changeLang(lang: string) {
    this.translate.use(lang);
  }

  createValidateForm() {
    this.translateService.setDefaultLang('pt-br');
    this.validateForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
    });
  }

  async submitForm() {
    if (this.validateForm.valid) {
      try {
        this.loadingService.show();
        await this.resetSenhaEmail(this.validateForm.get('email')?.value, this.isQuiosque ? 3 : 1);
        this.confirmModal = true;
        this.loadingService.hide();
        this.loadingService.hide();
      } catch (error: any) {
        if (error.error && error.error.status === 401) {
          this.loadingService.notify('Erro', 'E-mail inválido.');
          return;
        }
        this.loadingService.hide();
        return;
      }
    } else {
      Object.values(this.validateForm.controls).forEach((control) => {
        if (control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });
        }
      });
    }
  }


  checkWidth() {
    if (typeof window !== 'undefined') {
      this.isMobile = window.innerWidth < 992;
    }
  }

  async resetSenhaEmail(email: string, site:number) {
    try {
      return await this.authService.sendEmailResetPassword(email, site);
    } catch (error) {
      this.loadingService.notify('Erro', 'Erro ao enviar e-mail.');
    }
  }
}
