<div class="col-12 pt-8 main">
  <section class="voucher pt-8">
    <div class="backRouter px-5 pt-4 cursor-pointer">
      <a [routerLink]="['/meus-cupons']">
        <i class="pi pi-chevron-circle-left vertical-align-bottom"> </i>
        <span>Voltar</span>
      </a>
    </div>
    <div class="lg:col-11 mx-auto">
      <div class="flex-column md:flex-row mt-5 pb-5 px-5 lg:px-0">
        <div class="col-14 mx-auto lg:col-4">
          <div
            class="mb-6 md:mb-0 mr-0 md:mr-2 md:pr-5 flex flex-column flex-grow-1"
          >
            <div
              class="text-center p-3"
              [ngClass]="{
                'bg-card-header': detalheCupom?.isActive,
                'bg-card-header-dynamic': !detalheCupom?.isActive || detalheCupom?.statusCancel == 'Deferido'
              }"
            >
              <div class="flex align-items-center surface-border py-2">
                <div class="round-white">
                  <img
                    [src]="detalheCupom?.voucher?.category?.urlImage"
                    class="mr-3"
                    style="width: 60px; height: 60px; border-radius: 10px;"
                  />
                </div>
                <div class="flex flex-column align-items-start">
                  <!-- <span class="codigo">{{ detalheCupom?.id }}</span> -->
                  <span class="text-xl text-white font-semibold mb-1 pl-1"
                    >Consulta</span
                  >
                </div>
              </div>
            </div>
            <div
              class="p-4 flex flex-column flex-grow-1 shadow-3 border-round-bottom-3xl lg:shadow-none lg:shadow-none lg:border-none card-content"
            >
              <div
                class="font-medium text-base font-light line-height-2 mb-4 pr-1 text-black-alpha-70"
              >
                {{ detalheCupom?.voucher?.description?.slice(0, 75)
                }}{{
                  detalheCupom?.voucher?.description?.length &&
                  detalheCupom?.voucher?.description?.length! > 75
                    ? "..."
                    : ""
                }}
              </div>
              @if(detalheCupom?.isActive && detalheCupom?.statusCancel !== 'Deferido'){
              <ul class="list-none p-0 m-0 mb-3 text-bluegray-600">
                <li class="flex align-items-center mb-1">
                  <span class="text-black-alpha-70 font-medium text-base"
                    >Valor:&nbsp;</span
                  >
                  <span class="font-bold">{{
                    formatCurrency(detalheCupom?.voucher?.value!)
                  }}</span>
                </li>
                <li class="flex align-items-center mb-1">
                  <span class="text-black-alpha-70 font-medium text-base"
                    >Válido até:&nbsp;</span
                  >
                  <span class="font-bold">
                    {{
                      detalheCupom?.dateLimit | date: 'dd/MM/yyyy'
                    }}</span
                  >
                </li>
              </ul>
              }@else if(!detalheCupom?.isActive) {
              <div class="mb-4">
                <span class="font-medium">Cupom já utilizado</span>
              </div>
              }@else if(detalheCupom?.statusCancel == 'Deferido'){
                <div class="mb-4">
                  <span class="font-medium">Cupom Cancelado</span>
                </div>
              }
              <div
                class="mx-auto"
                [ngClass]="{
                  'my-0': detalheCupom?.isActive,
                  'my-4': !detalheCupom?.isActive || detalheCupom?.statusCancel == 'Deferido'
                }"
              >
                <p-button
                  label="Ver Detalhes"
                  [outlined]="true"
                  [rounded]="true"
                  severity="secondary"
                  styleClass="p-button-outlined p-button-strong w-full lg:w-auto px-6"
                  (click)="openDialog()"
                  size="small"
                ></p-button>
              </div>
              <p-dialog
                header="header"
                [(visible)]="visible"
                [modal]="true"
                [breakpoints]="{ '960px': '85vw' }"
                [style]="{ width: '50vw' }"
                [draggable]="false"
                [resizable]="false"
              >
                <ng-template pTemplate="header">
                  <div
                    class="inline-flex align-items-center justify-content-center gap-5"
                  >
                    <img
                      src="/assets/img/icons/ic_consulta.svg"
                      class="mr-3"
                      style="width: 32px; height: 32px"
                    />
                    <span class="white-space-nowrap titulo-modal"
                      >Consulta</span
                    >
                  </div>
                </ng-template>
                <div class="col-12 voucher-consulta">
                  @if(detalheCupom?.voucher?.partner){
                  <div>
                    <h2
                      class="border-bottom-1 border-400 text-center text-lg xl:text-left lg:px-4 pt-2"
                    >
                      Parceiro responsável
                    </h2>
                    <p class="text-center lg:text-left lg:px-4">
                      {{ detalheCupom?.voucher?.partner?.name }}
                    </p>
                  </div>
                  }

                  <div>
                    <h2
                      class="border-bottom-1 border-400 text-center text-lg xl:text-left lg:px-4 pt-2"
                    >
                      Tipo de serviço
                    </h2>
                    <p class="text-center lg:text-left px-2 lg:px-4">
                      {{ detalheCupom?.voucher?.serviceType }}
                    </p>
                  </div>

                  <div>
                    <h2
                      class="border-bottom-1 border-400 text-center text-lg lg:text-left lg:px-4 pt-2"
                    >
                      Valores
                    </h2>
                    <ul class="text-left mb-0">
                      <li>
                        Valor do cupom:&nbsp;{{
                          formatCurrency(detalheCupom?.voucher?.value!)
                        }}
                      </li>
                      <li>
                        Valor do serviço com o uso do cupom:&nbsp;{{
                          formatCurrency(detalheCupom?.voucher?.value!)
                        }}
                      </li>
                    </ul>
                  </div>

                  <div class="pt-2 pb-2">
                    <h2
                      class="border-bottom-1 border-400 text-center text-lg lg:text-left lg:px-4"
                    >
                      Descrição
                    </h2>
                    <p class="lg:px-5 px-4 text-left pt-3">
                      {{ detalheCupom?.voucher?.description }}
                    </p>
                  </div>
                  @if (detalheCupom?.voucher?.partner) {
                  <div class="pt-2 pb-2">
                    <h2
                      class="border-bottom-1 border-400 text-center text-lg lg:text-left lg:px-4"
                    >
                      Parceiros
                    </h2>
                    <p class="lg:px-5 px-4 text-left pt-3">
                      {{ detalheCupom?.voucher?.description }}
                    </p>
                  </div>
                  }
                  <div class="pt-2 pb-2">
                    <h2
                      class="border-bottom-1 border-400 text-center text-lg lg:text-left lg:px-4"
                    >
                      Regras de utilização do cupom
                    </h2>
                    <p class="lg:px-5 px-4 text-left pt-3">
                      {{ detalheCupom?.voucher?.utilizationRules }}
                    </p>
                  </div>
                  <div class="pb-2">
                    <h2
                      class="border-bottom-1 border-400 text-center text-lg lg:text-left lg:px-4"
                    >
                      Regras de cancelamento
                    </h2>
                    <p class="lg:px-5 px-4 text-left pt-3">
                      {{ detalheCupom?.voucher?.cancelRules }}
                    </p>
                  </div>
                </div>
              </p-dialog>
              @if(detalheCupom?.statusCancel !== 'Deferido' && detalheCupom?.isActive ){
              <div
                class="flex mt-5 mb-3 padding-top justify-content-center align-items-center"
                style="height: 110px"
              >
                <qrcode
                  [qrdata]="detalheCupom?.id!"
                  [width]="180"
                  [errorCorrectionLevel]="'M'"
                ></qrcode>
              </div>
              <div
                class="flex align-items-center justify-content-center my-2"
                style="height: 50px"
              >
                <span class="font-medium text-center text-sm px-2">{{
                  detalheCupom?.id
                }}</span>
              </div>

              <div
                class="flex align-items-center text-center justify-content-center mb-4"
              >
                <span
                  (click)="cancelButtonClick
                      ? (confirmModal = true): (confirmModal = false)"
                  class="cancelar font-semibold cursor-pointer"
                  >{{ labelCancelCupom }}</span
                >
              </div>
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>

<p-dialog
  header="Tem certeza que deseja cancelar?"
  [(visible)]="confirmModal"
  [modal]="true"
  [draggable]="false"
  [resizable]="false"
  [breakpoints]="{ '960px': '20%', '640px': '93%' }"
>
  <div class="flex flex-column p-5">
    <div>
      <p class="m-0 text-xl text-black-alpha-90 font-medium">
        Caso cancele o cupom, não será possível reativá-lo.<br />
        Deseja continuar?
      </p>
    </div>
    <div class="py-4">
      <p class="m-0 text-base text-black-alpha-90 font-medium">
        <strong>Atenção: </strong> O valor do cupom será devolvido em forma de
        crédito.
      </p>
    </div>

    <div class="col-12 flex-column">
      <div class="w-full">
        <p-button
          label="Não, prefiro continuar."
          (onClick)="lastModalConfirm = true"
          severity="help"
          type="button"
          styleClass="p-button-save p-button-ok w-full lg:w-6 border-round-xl"
        ></p-button>
      </div>
      <div
        class="flex align-items-center text-center justify-content-center my-4"
      >
        <span (click)="cancelarCupom()" class="cancelar cursor-pointer"
          >Sim, prefiro cancelar</span
        >
      </div>
    </div>
  </div>
</p-dialog>

<p-dialog
  header="Desistência de cancelamento:"
  [(visible)]="lastModalConfirm"
  [modal]="true"
  [draggable]="false"
  [resizable]="false"
  [breakpoints]="{ '960px': '20%', '640px': '93%' }"
>
  <div class="flex flex-column p-5">
    <div>
      <p class="m-0 text-xl text-black-alpha-90 font-medium">
        <strong
          >Sua desistência de cancelamento foi <br />
          efetuada com sucesso!</strong
        >
      </p>
    </div>
    <div>
      <p class="m-0 text-xl text-black-alpha-90 my-5 font-medium">
        Obrigado por continuar utilizando nossos serviços.
      </p>
    </div>

    <div class="col-12 flex-column">
      <div class="w-full">
        <p-button
          label="OK"
          (click)="cancelAllModals()"
          severity="help"
          type="button"
          styleClass="p-button-save p-button-ok w-full lg:w-6 border-round-xl"
        ></p-button>
      </div>
    </div>
  </div>
</p-dialog>

<p-dialog
  header="Sua solicitação foi enviada."
  [(visible)]="modalSolicitacao"
  [modal]="true"
  [draggable]="false"
  [resizable]="false"
  [breakpoints]="{ '960px': '30%', '640px': '93%' }"
>
  <div class="flex flex-column p-2 pt-5">
    <div>
      <p
        class="m-0 text-lg lg:text-xl text-black-alpha-90 font-medium line-height-2"
      >
        Analisaremos sua solicitação e <br />
        entraremos em contato pelo <br />
        seu e-mail cadastrado em até <br />
        xx dias úteis.
      </p>
    </div>
    <div class="py-4">
      <p
        class="m-0 text-sm lg:text-base text-black-alpha-90 font-medium line-height-2"
      >
        <strong>Atenção: </strong> verifique sua caixa de spam ou <br />
        lixo eletrônico caso não encontre o link.
      </p>
    </div>

    <div class="col-12 mb-5 flex-column">
      <div class="w-full">
        <p-button
          label="OK"
          (click)="closeAllModals()"
          severity="help"
          type="button"
          styleClass="p-button-save p-button-ok w-full border-round-xl"
        ></p-button>
      </div>
    </div>
  </div>
</p-dialog>
