<div class="lg:col-12 lg:pt-0 pt-6">
  <section class="voucher">
    <div class="col-12 lg:col-9 px-4 pr-4 lg:px-6 pt-4 lg:pt-8 voucher-consulta">
        <back-router></back-router>
      @if(dadosCarrinho.length === 0){
      <div class="flex justify-content-center align-items-center pt-7">
          <div class="flex justify-content-center align-items-center">
            <h2 class="text-2xl mt-0 mb-2 font-medium">
              Seu carrinho está vazio
            </h2>
          </div>
      </div>
      }@else {
      <div class="flex-column justify-content-center">
        @for (item of dadosCarrinho; track $index) {
        <div class="card-iten my-4 lg:my-6 lg:col-6 mx-auto">
          <div class="flex justify-content-between px-3 py-3">
            <div>
              <img
                src="/assets/img/icons/ic_consulta.svg"
                alt="icone"
                style="width: 55px; height: 55px"
              />
            </div>
            <div class="text-center">
              <div>
                <h2 class="text-2xl mt-0 mb-5 font-medium">
                  Cupom {{ item.title }}
                </h2>
              </div>
              <div>
                <p-inputNumber
                  [(ngModel)]="item.quantity"
                  [showButtons]="true"
                  buttonLayout="horizontal"
                  spinnerMode="horizontal"
                  inputId="horizontal"
                  [min]="0"
                  decrementButtonClass="p-button-secondary"
                  incrementButtonClass="p-button-secondary"
                  incrementButtonIcon="pi pi-plus"
                  decrementButtonIcon="pi pi-minus"
                  (onBlur)="changeValue(item)"
                />
              </div>
            </div>
            <div class="justify-content-between flex flex-column text-right">
              <i
                (click)="onRemoveItem(item)"
                class="pi pi-trash"
                style="color: #c77eb5; font-size: 27px; cursor: pointer"
              ></i>
              <div class="flex-column flex justify-content-end">
                <span class="valor-cifrao">{{item.quantity}}x&nbsp; R$</span>
                <span class="valor-card">{{
                  replaceDot(item.value)
                }}</span>
              </div>
            </div>
          </div>
        </div>
        }
      </div>
      }
    </div>

    <div
      class="bottom-0 md:bottom-auto border-round-xl w-full lg:w-2"
      [ngClass]="{ 'fixed-container': !isMobile, fixed: isMobile }"
    >
      <p-button
        label="Total"
        icon="pi pi-shopping-cart"
        iconPos="right"
        styleClass="w-full lg:border-round-top-xl btn-shopping"
      ></p-button>
      <p-card styleClass="card-carrinho">
        <div class="flex flex-column px-4">
          <div class="grid align-items-baseline">
            <div class="col-6"></div>
            <div class="col-12 text-center">
              <span class="valor-final">{{ formatCurrency(valorTotal) }}</span>
            </div>
            <div class="col-12">
              <p-button
                label="Efetuar pagamento"
                severity="success"
                [disabled]="dadosCarrinho.length === 0"
                styleClass="p-button-save w-full border-round-3xl"
                [routerLink]="['/pagamento/cupom']"
              ></p-button>
            </div>
          </div>
        </div>
      </p-card>
    </div>
  </section>
</div>
