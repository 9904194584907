import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { RouterLink } from '@angular/router';
import { ButtonModule } from 'primeng/button';

@Component({
  selector: 'app-quiosque-area',
  standalone: true,
  imports: [
    ButtonModule, 
    CommonModule, 
    RouterLink
  ],
  templateUrl: './quiosque-area.component.html',
  styleUrl: './quiosque-area.component.scss'
})
export class QuiosqueAreaComponent {
  isMobile: boolean = false;
  isDesktop: boolean;
  arrColors: string[] = ['#7570B4', '#E4E6E7', '#78CDD1'];
  isSubscribed: boolean = false;
}
