<div class="bg-login h-full lg:h-screen pt-8">
  <div class="col-12 md:col-6 xl:col-5 relative lg:absolute card-login py-8 px-3 lg:py-0">
    <p-card>
      <div class="py-3 px-1 lg:px-5" style="border-radius: 53px">
        <div class="text-center mb-4 hidden lg:block">
          <img src="../../../assets/img/icons/logo_topbar.png" alt="logo-estaca-do-olho" height="50" class="mb-3" />
        </div>
        <form [formGroup]="validateForm">
          <div>
            <label class="block text-white font-normal mb-2">Informe seu e-mail</label>
            <input id="email" (input)="toLowerCase($event)" type="text" placeholder="email@email.com.br" pInputText class="w-full md:w-full mb-3"
              style="padding: 1rem" formControlName="email" />

            <label class="block text-white font-normal mb-2">Senha</label>
            <p-password id="password1" placeholder="*****" [toggleMask]="true" styleClass="mb-5"
              inputStyleClass="w-full p-3 md:w-full" formControlName="password" [feedback]="false"></p-password>

            <div class="flex flex-column-reverse md:flex-row gap-2">
              @if(!isQuiosque){
              <p-button label="Cadastre-se" severity="help" styleClass="p-button-help p-button-register" class="w-full"
                routerLink="/cadastro"></p-button>
              }
              <p-button label="Entrar" type="button" [disabled]="validateForm.invalid" styleClass="p-button-login"
                type="submit" class="w-full" (onClick)="isQuiosque ? loginQuiosque() : submitForm()"></p-button>
            </div>
            <p [routerLink]="[isQuiosque ? '/email-redefinir-senha-quiosque' : '/email-redefinir-senha']"
              class="font-semibold text-xl lg:text-base text-white text-center cursor-pointer">Esqueci minha senha</p>
          </div>
        </form>
      </div>
    </p-card>
  </div>

  <div class="lg:hidden ">
    <img src="assets/img/bg/diversidade.jpg" alt="grupo-pessoas" class="w-full">
  </div>
</div>
