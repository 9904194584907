import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AnamneseService {

  private apiUrl = environment.apiUrl;

  constructor(private http: HttpClient) { }

  registrarAnamnese(body:{}): Promise<any> {
    return this.http.post<any>(`${this.apiUrl}/customer/anamnesis/answer`, body).toPromise();
  }

  getAnamnesisQuestionsV2(page:number): Promise<any>  {
    return this.http.get<any>(`${this.apiUrl}/v2/anamnesis/active/${page}`,).toPromise();
  }

  getActiveAnamnesis(): Promise<any>  {
    return this.http.get<any>(`${this.apiUrl}/customer/anamnesis/answer`,).toPromise();
  }

  putAnamnesis(body: any, id:string): Promise<any> {
    return this.http.put<any>(`${this.apiUrl}/customer/anamnesis/answer/${id}`, body).toPromise();
  }

  postAnamnesis(body: any): Promise<any> {
    return this.http.post<any>(`${this.apiUrl}/customer/anamnesis/answer`, body).toPromise();
  }
}
