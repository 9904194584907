<div class="bg-login h-full lg:h-screen pt-8">
  <div
    class="col-12 lg:col-4 relative lg:absolute card-login py-8 px-3 lg:py-0"
  >
    <p-card>
      <div class="pb-3 px-1" style="border-radius: 53px">
        <div class="pt-3 pb-4">
          <p class="font-normal text-3xl lg:text-base text-white text-center">
            Redefinir senha
          </p>
        </div>
        <form [formGroup]="validateForm" (ngSubmit)="submitForm()">
          <div>
            <label class="block text-white font-normal mb-2"
              >Informe seu e-mail</label
            >
            <input
              id="email"
              type="text"
              placeholder="email@email.com.br"
              pInputText
              class="w-full md:w-full mb-3"
              style="padding: 1rem"
              formControlName="email"
              (keydown.enter)="submitForm()"
            />
            <div
              *ngIf="
                validateForm.get('email')?.invalid &&
                validateForm.get('email')?.touched
              "
              class="alert alert-danger"
            >
              Campo obrigatório.
            </div>
            <div class="flex flex-column gap-2">
              <p-button
                [disabled]="validateForm.invalid"
                label="Enviar"
                styleClass="p-button-login"
                type="button"
                class="w-full pt-2"
                (onClick)="submitForm()"
                (keydown.enter)="submitForm()"
              ></p-button>
              <p-button
                label="Voltar para a tela de login"
                severity="help"
                styleClass="p-button-help p-button-register"
                class="w-full pt-3"
                [routerLink]="['/login']"
              ></p-button>
            </div>
          </div>
        </form>
      </div>
    </p-card>
  </div>

  <div class="lg:hidden">
    <img
      src="assets/img/bg/diversidade.jpg"
      alt="grupo-pessoas"
      class="w-full"
    />
  </div>
</div>

<!-- Modal de confirmação -->
<p-dialog
  header="Sucesso!"
  [(visible)]="confirmModal"
  [modal]="true"
  [draggable]="false"
  [resizable]="false"
  [breakpoints]="{ '960px': '20%', '640px': '93%' }"
>
  <div class="flex flex-column pt-5">
    <div>
      <p class="m-0 text-xl text-black-alpha-90 font-medium">
        Foi enviado um link para o e-mail <br> que você cadastrou.
        Utilize este link <br> para recuperar a sua senha.
      </p>
    </div>
    <div class="pt-6 pb-4">
      @if(!isMobile){
        <p class="m-0 text-base text-black-alpha-90 font-medium">
          <strong>Atenção: </strong> verifique sua caixa de spam ou <br> lixo
          eletrônico caso não encontre o link.
        </p>
      }@else {
        <p class="m-0 text-base text-black-alpha-90 font-medium">
          <strong>Atenção: </strong> verifique sua caixa de spam ou lixo
          eletrônico caso não encontre o link.
        </p>
      }

    </div>

    <div class="col-12">
      <p-button
        label="OK"
        (click)="confirmModal = false"
        severity="help"
        styleClass="p-button-save p-button-ok lg:w-6 border-round-xl"
        [routerLink]="['/login']"
      ></p-button>
    </div>
  </div>
</p-dialog>
