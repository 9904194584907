<div class="col-12 pt-8 lg:h-screen principal">
  <section class="result">
    <div class="px-4">
      <back-router></back-router>
    </div>

    <div class="p-5 flex flex-column flex-auto">
      <div>
        @if(resultados?.length > 0){
        <div class="grid gap-6 lg:gap-2">
          @for (item of resultados; track $index) {
          <div class="col-10 lg:col-3 surface-card pt-3 lg:pt-2 px-3 border-1 border-50 mx-auto text-center lg:mt-5 cursor-pointer">
            <a class="download" (click)="handleDownload(item)">
              @if(!isMobile){
              <div class="flex pl-4">
                <div>
                  <p class="font-bold text-xl text-purple text-left">
                    {{ item.isExam ? 'Exame' : 'Resultado/Laudo'}}
                  </p>
                  <p class="text-left">
                    <strong> Data:&nbsp;</strong>{{ item?.data }}
                  </p>
                </div>
                <div class="my-auto pl-8">
                  <img src="assets/img/icons/ic_pdf.svg" alt="icone-resultado" />
                </div>
              </div>
              } @if(isMobile){
              <img src="assets/img/icons/ic_pdf.svg" alt="icone-resultado" />
              <p class="font-bold text-xl text-purple">{{ item.isExam ? 'Exame' : 'Resultado/Laudo'}}</p>
              <p><strong> Data:&nbsp;</strong>{{ item?.data }}</p>
              }
            </a>
          </div>
          }
        </div>
        }@else {
        <div class="flex justify-content-center pt-8">
          <p class="text-center font-bold text-2xl ">
            Não há resultados disponíveis
          </p>
        </div>
        }
      </div>
    </div>
  </section>
</div>