

<div
  class="col-12 flex flex-column align-items-center justify-content-start pt-8 bg-cadastro"
  [ngClass]="{ '': !isEdit, 'h-screen': isEdit }"
>
<div class="backRouter p-4 cursor-pointer relative mr-auto">
    <a [routerLink]="['/quiosque-area']">
      <i class="pi pi-chevron-circle-left mr-3"> </i>
      <span>Voltar</span>
    </a>
  </div>
  <p-scrollPanel  styleClass="custombar2">
    <div class="lg:col-5 mx-auto py-5">
      <div class="text-center mb-3">
        @if(!isEdit){
        <div class="text-title mb-3 font-light font-normal">
          Complete seu cadastro
        </div>
        }@else {
        <div class="text-title mb-3 font-light font-normal">
          Edite seu cadastro
        </div>
        }
      </div>
      <form [formGroup]="validateForm">
        <div class="col-12 mx-auto grid">
          <div class="field col-12">
            <label aria-required="true" class="block mb-2">Nome</label>
            <input
              id="name"
              type="text"
              placeholder="Nome"
              pInputText
              class="w-full md:w-full"
              formControlName="name"
            />
            <div
              *ngIf="
                validateForm.get('name')?.invalid &&
                validateForm.get('name')?.touched
              "
              class="alert alert-danger"
            >
              Campo obrigatório.
            </div>
          </div>
  
          <div class="field col-12">
            <label class="block mb-2">Email</label>
            <input
              id="email"
              type="text"
              placeholder="email@email.com"
              pInputText
              class="w-full md:w-full"
              formControlName="email"
              [disabled]="isEdit"
            />
            <div
              *ngIf="
                validateForm.get('email')?.invalid &&
                validateForm.get('email')?.touched
              "
              class="alert alert-danger"
            >
              E-mail incorreto.
            </div>
          </div>
          <div class="field col-12 lg:col-12">
            <label class="block mb-2">CPF</label>
            <p-inputMask
              mask="999.999.999-99"
              id="cpf"
              formControlName="cpf"
              placeholder="000.000.000-00"
              type="text"
              class="w-full md:w-full mb-3"
            ></p-inputMask>
            <div
              *ngIf="
                validateForm.get('cpf')?.invalid &&
                validateForm.get('cpf')?.touched
              "
              class="alert alert-danger"
            >
              CPF inválido.
            </div>
          </div>
          <div class="field col-12 lg:col-6">
            <label class="block mb-2">Celular</label>
            <p-inputMask
              mask="(99)99999-9999"
              id="phoneNumber"
              formControlName="phoneNumber"
              placeholder="(00)00000-0000"
              type="text"
              class="w-full md:w-full mb-3"
            ></p-inputMask>
            <div
              *ngIf="
                validateForm.get('phoneNumber')?.invalid &&
                validateForm.get('phoneNumber')?.touched
              "
              class="alert alert-danger"
            >
              Campo obrigatório.
            </div>
          </div>
          <div class="field col-12 lg:col-6">
            <label class="block mb-2">Data de nascimento</label>
            <p-inputMask
              mask="99/99/9999"
              id="birthDate"
              formControlName="birthDate"
              placeholder="dd/mm/yyyy"
              type="text"
              class="w-full md:w-full mb-3"
            ></p-inputMask>
            <div
              *ngIf="
                validateForm.get('birthDate')?.invalid &&
                validateForm.get('birthDate')?.touched
              "
              class="alert alert-danger"
            >
              Data inválida
            </div>
          </div>
  
          <div class="col-12 text-center py-4">
              <p-button
              label="Salvar"
              [disabled]="validateForm.invalid"
              severity="success"
              styleClass="p-button-save border-round-3xl w-6"
              (onClick)="submitForm()"
            ></p-button>
          </div>
  
  
  
        </div>
      </form>
    </div>
</p-scrollPanel>



</div>
