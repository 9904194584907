import { Component, Input } from '@angular/core';
import { RouterLink } from '@angular/router';
import { Location } from '@angular/common';

@Component({
  selector: 'back-router',
  standalone: true,
  imports: [RouterLink],
  templateUrl: './back-router.component.html',
  styleUrl: './back-router.component.scss'
})
export class BackRouterComponent {
  previousUrl:string = ''

  constructor(private location: Location) {}
  ngOnInit() {
    // this.previousUrl = sessionStorage.getItem('previousUrl') || '';

  }

  goBack(): void {
    this.location.back();
  }
}
