import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { environment } from '../../environments/environment';
import { AddressService } from './address.service';
import { AnamneseService } from './anamnese.service';
import { CustomerService } from './customer.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private accessToken: string | null = null;
  // private refreshToken: string | null = null;
  private urlBase: string = environment.apiUrl;

  constructor(
    private http: HttpClient,
    private addressService: AddressService,
    private anamneseService: AnamneseService,
    private custumerService: CustomerService,
  ) {}

  async login(email: string, password: string): Promise<any> {
    const body = { email: email, password: password };

    const response: any = await this.http
      .post(`${this.urlBase}/auth/customer/login`, body)
      .toPromise();
    return response;
  }

  async loginQuiosque(email: string, password: string): Promise<any> {
    const body = { email: email, password: password };

    const response: any = await this.http
      .post(`${this.urlBase}/auth/login`, body)
      .toPromise();
    return response;
  }

  async sendEmailResetPassword(email: string, site:number): Promise<any> {
    const body = { email: email, site: site };
    const response: any = await this.http
      .post(`${this.urlBase}/auth/email/reset`, body)
      .toPromise();
    return response;
  }

  async resetPassword(body: any): Promise<any> {
    const response: any = await this.http
      .post(`${this.urlBase}/auth/password/reset`, body)
      .toPromise();
    return response;
  }

  async createUser(body: any): Promise<any> {
    if (
      !body.birthDate ||
      !body.email ||
      !body.name ||
      !body.password ||
      !body.phoneNumber
    ) {
      throw new Error('Missing required fields');
    }

    const response: any = await this.http
      .post(`${this.urlBase}/auth/customer/register`, body)
      .toPromise();
    return response;
  }

  refreshToken(token: string): Observable<any> {
    const body = { refreshToken: token };

    return this.http.post(`${this.urlBase}/auth/refresh`, body);
  }

  private renewAccessToken(): Observable<string> {
    const newAccessToken = 'new-access-token';

    return new Observable((observer) => {
      observer.next(newAccessToken);
      observer.complete();
    });
  }

  setToken(token: string): void {
    if (typeof window !== 'undefined') {
      sessionStorage.setItem('token', token);
    }
  }

  getToken(): string {
    if (typeof window !== 'undefined') {
      return sessionStorage.getItem('token') || '{}';
    } else {
      return '{}';
    }
  }

  getLoggedUser(body: any): Promise<any> {
    return this.http.get(`${this.urlBase}/auth/loggeduser`, body).toPromise();
  }

  putUserInfo(body: any, id:string): Promise<any> {
    return this.http.put(`${this.urlBase}/customer/${id}`, body).toPromise();
  }

  getAccessToken(): Observable<string> {
    if (this.accessToken) {
      // Simulando uma verificação arbitrária (por exemplo, menos de 5 minutos)
      const expiresIn = 5 * 60 * 1000; // 5 minutos em milissegundos

      if (new Date().getTime() + expiresIn >= new Date().getTime()) {
        return this.renewAccessToken();
      } else {
        return new Observable((observer) => {
          observer.next(this.accessToken!);
          observer.complete();
        });
      }
    } else {
      return throwError('Access Token not available');
    }
  }

  async getStep() {
    const address = await this.addressService.getCustomerAddress();
    const anamnese = await this.anamneseService.getActiveAnamnesis();
    const customer = await this.custumerService.getCustomerDemographics();

    if (address && anamnese?.length >= 10 && customer[0]) {
      return 3;
    } else if (address[0] && customer[0]) {
      return 2;
    } else if (address[0]) {
      return 1;
    } else {
      return 0;
    }
  }
}
