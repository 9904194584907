import { Component } from '@angular/core';
import { FormsModule } from '@angular/forms';

// PrimeNG
import { ButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card';
import { InputNumberModule } from 'primeng/inputnumber';
import { CuponsService } from '../../../services/cupons.service';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { CommonModule } from '@angular/common';
import { DialogModule } from 'primeng/dialog';
import { AvatarModule } from 'primeng/avatar';
import { AvatarGroupModule } from 'primeng/avatargroup';
import { LoadingService } from '../../../services/loading.service';

@Component({
  selector: 'app-detalhe-cupom',
  standalone: true,
  imports: [
    ButtonModule,
    CardModule,
    InputNumberModule,
    FormsModule,
    CommonModule,
    RouterLink,
    DialogModule,
    AvatarModule,
    AvatarGroupModule,
  ],
  templateUrl: './detalhe-cupom.component.html',
  styleUrl: './detalhe-cupom.component.scss',
})
export class DetalheCupomComponent {
  path: string = 'assets/img/icons/';
  detalheCupom: any = [];
  id: string | null | undefined;
  quantidadeCupons: number = 1;
  isMobile: boolean = false;
  isDesktop: boolean;
  visibleDescrip: boolean = false;
  visibleCancRules: boolean = false;
  VisibleUtilRules: boolean = false;
  lastIndex: number = 1;
  valorAtual: number = 0;
  user: any = {};
  carrinho: any = [];

  constructor(
    private cuponsService: CuponsService,
    private route: ActivatedRoute,
    private router: Router,
    private loadingService: LoadingService
  ) {}

  async ngOnInit() {
    this.loadingService.show();
    this.id = this.route.snapshot.paramMap.get('id');
    this.detalheCupom = await this.getCupom(this.id);
    this.user = JSON.parse(sessionStorage.getItem('user') || '{}');
    this.carrinho = JSON.parse(sessionStorage.getItem('carrinho') || '[]');
    this.valorAtual = this.detalheCupom.value;
    if (typeof window !== 'undefined') {
      this.checkWidth();
      window.addEventListener('resize', () => {
        this.checkWidth();
      });
    }
    this.loadingService.hide();
  }

  formatCurrency(value: number) {
    if (!value || value == 0)
       return 'R$ 0,00';
    return value.toLocaleString('pt-br', {
      style: 'currency',
      currency: 'BRL',
    });
  }

  calculateFutureDate(months: number) {
    const currentDate = new Date();
    const futureDate = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth() + months,
      currentDate.getDate()
    );
    const formattedDate = futureDate.toLocaleDateString('pt-BR');
    return formattedDate;
  }

  onChangeValue(event: any) {
    this.valorAtual = this.detalheCupom.value * event;
  }

  async submitForm() {}

  checkWidth() {
    if (typeof window !== 'undefined') {
      this.isMobile = window.innerWidth < 992;
    }
  }

  checkIfMobile() {
    const screenWidth = window.innerWidth;
    this.isMobile = screenWidth <= 768;
    this.isDesktop = !this.isMobile;
  }

  normalizeString(str: string): string {
    const normalizedStr = str
      .toLowerCase()
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '');
    return normalizedStr;
  }

  goToCategory(category: any) {
    const normalizedCategoryName = this.normalizeString(category.name);
    this.router.navigate(['/parceiros/' + normalizedCategoryName + '/' + category.id]);
  }

  openDialog(type: number) {
    switch (type) {
      case 0:
        this.visibleDescrip = true;
        break;
      case 1:
        this.VisibleUtilRules = true;
        break;
      case 2:
        this.visibleCancRules = true;
        break;
      default:
        break;
    }
  }

  addCarrinho(){
    this.loadingService.show();
    let carrinho = JSON.parse(sessionStorage.getItem('carrinho') || '[]');
    if(carrinho.length > 0){
      let index = carrinho.find((item: any) => item.id === this.detalheCupom.id);
      if(index){
        index.quantity += this.quantidadeCupons;
        sessionStorage.setItem('carrinho', JSON.stringify(carrinho));
        this.loadingService.hide();
      }else{
        carrinho.push(this.createObjectCarrinho())
        sessionStorage.setItem('carrinho', JSON.stringify(carrinho));
        this.loadingService.hide();
      }
    }else{
      carrinho.push(this.createObjectCarrinho())
      sessionStorage.setItem('carrinho', JSON.stringify(carrinho));
      this.loadingService.hide();
    }
    this.loadingService.hide();
  }

  createObjectCarrinho() {
    return {
      id: this.detalheCupom.id,
      title: this.detalheCupom.voucherType.name,
      value: this.detalheCupom.value,
      quantity: this.quantidadeCupons,
      image: this.detalheCupom.category.urlImage,
    }
  }

  async getCupom(id: string | null) {
    try {
      return await this.cuponsService.getSpecificCupom(id);
    } catch (error) {
      console.log(error);
    }
  }
}
