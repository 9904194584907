import { CuponsService } from '../../../services/cupons.service';
import { Component } from '@angular/core';

// PrimeNG
import { ButtonModule } from 'primeng/button';
import { CarouselModule } from 'primeng/carousel';
import { Product } from '../../../model/product';
import { ProductService } from '../../../services/productservice';
import { Router, RouterLink } from '@angular/router';
import { LoadingService } from '../../../services/loading.service';
import { BackRouterComponent } from '../../../components/back-router/back-router.component';


@Component({
  selector: 'app-all-cupons-list',
  standalone: true,
  imports: [ButtonModule, CarouselModule, RouterLink, BackRouterComponent],
  templateUrl: './all-cupons-list.component.html',
  styleUrl: './all-cupons-list.component.scss'
})
export class AllCuponsListComponent {
  products: Product[] = [];
  responsiveOptions: any[] | undefined;
  showingAllCupons = false;
  sponsoredCupons: any[] = [];
  isMobile = false;

  vouchers: any[] = [];

  constructor(
    private productService: ProductService,
    private cuponsService: CuponsService,
    private loadingService: LoadingService,
    private router: Router,
  ) {}

  async ngOnInit() {
    this.loadingService.show();
    this.vouchers = await this.getNormalCupons();
    this.sponsoredCupons = await this.getCuponsPatrocinados();

    this.vouchers = [...this.sponsoredCupons, ...this.vouchers];
    this.checkIfMobile();
    this.loadingService.hide();
  }

  verifyIsSponsor(item:any){
    if(item.partner){
      return 'Patrocinado'
    }else{
      return item.serviceType
    }
  }

  checkIfMobile() {
    const screenWidth = window.innerWidth;
    this.isMobile = screenWidth <= 998;
  }

  formatCurrency(value: number) {
    return value.toLocaleString('pt-br', {
      style: 'currency',
      currency: 'BRL',
    });
  }

  calculateFutureDate(months: number) {
    const currentDate = new Date();
    const futureDate = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth() + months,
      currentDate.getDate()
    );
    const formattedDate = futureDate.toLocaleDateString('pt-BR');
    return formattedDate;
  }

  async getNormalCupons() {
    try {
      return await this.cuponsService.getNormalCoupons();
    } catch (error) {
      console.log(error);
    }
  }

  async getCuponsPatrocinados() {
    try {
      return await this.cuponsService.getSponsorshipCupons();
    } catch (error) {
      console.log(error);
    }
  }

  async validateClickAndNavigate(id: string) {
    this.loadingService.show();
    await this.voucherView(id)
    this.loadingService.hide();
    this.router.navigate(['/cupons/' + id + '/detalhe'])
  }

  async voucherView(id: string) {
    try {
      return await this.cuponsService.postVoucherView(id)
    } catch (error) {
      this.loadingService.notify('Erro ao processar pagamento');
    }
  }

}
