import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { RouterLink } from '@angular/router';

// PrimeNG
import { ButtonModule } from 'primeng/button';
import { LoadingService } from '../../services/loading.service';
import { CustomerService } from '../../services/customer.service';

@Component({
  selector: 'app-client-area',
  standalone: true,
  imports: [ButtonModule, CommonModule, RouterLink],
  templateUrl: './client-area.component.html',
  styleUrl: './client-area.component.scss',
})
export class ClientAreaComponent {
  isMobile: boolean = false;
  isDesktop: boolean;
  arrColors: string[] = ['#7570B4', '#E4E6E7', '#78CDD1'];
  isSubscribed: boolean = false;

  constructor(
    private loadingService: LoadingService,
    private customerService: CustomerService
  ) {}

  async ngOnInit() {
    this.loadingService.show();
    const sub = await this.getSubs();
    if (sub[0]) {
      this.isSubscribed = true;
    } else {
      this.isSubscribed = false;
    }

    if (typeof window !== 'undefined') {
      this.checkWidth();
      window.addEventListener('resize', () => {
        this.checkWidth();
      });
    }
    this.loadingService.hide();
  }

  checkWidth() {
    if (typeof window !== 'undefined') {
      this.isMobile = window.innerWidth < 768;
    }
  }

  async getSubs() {
    try {
      return await this.customerService.getSubscriptions();
    } catch (error) {
      console.log(error);
      this.loadingService.hide();
    }
  }

  checkIfMobile() {
    const screenWidth = window.innerWidth;
    this.isMobile = screenWidth <= 768;
    this.isDesktop = !this.isMobile;
  }
}
