import { LoadingService } from './../../../services/loading.service';
import { AuthService } from './../../../services/auth.service';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { Component } from '@angular/core';
import { CardModule } from 'primeng/card';
import { PasswordModule } from 'primeng/password';
import { ButtonModule } from 'primeng/button';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { TranslateService } from '@ngx-translate/core';
import { PrimeNGConfig } from 'primeng/api';
import { Subscription } from 'rxjs';
import { DialogModule } from 'primeng/dialog';
import { CommonModule } from '@angular/common';
@Component({
  selector: 'app-nova-senha',
  standalone: true,
  imports: [
    CommonModule,
    CardModule,
    ButtonModule,
    RouterLink,
    FormsModule,
    ReactiveFormsModule,
    ProgressSpinnerModule,
    DialogModule,
    PasswordModule,
  ],
  templateUrl: './nova-senha.component.html',
  styleUrl: './nova-senha.component.scss',
})
export class NovaSenhaComponent {
  validateForm: FormGroup;
  lang: string = 'pt-br';
  subscription: Subscription;
  confirmModal: boolean = false;
  bodyResetSenha: any = {
    email: '',
    token: '',
    id: '',
    password: '',
  };

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthService,
    private translateService: TranslateService,
    public primeNGConfig: PrimeNGConfig,
    public translate: TranslateService,
    private loadingService: LoadingService
  ) {
    this.createValidateForm();
    translate.addLangs(['pt-br', 'en']);
    translate.setDefaultLang('pt-br');

    const browserLang = translate.getBrowserLang();
    let lang =
      browserLang && browserLang.match(/pt-br|en/) ? browserLang : 'pt-br';
    this.changeLang(lang);

    this.subscription = this.translate.stream('primeng').subscribe((data) => {
      this.primeNGConfig.setTranslation(data);
    });
  }

  ngOnInit() {
    this.loadingService.show();
    this.route.queryParams.subscribe((params) => {
      this.bodyResetSenha.id = params['iduser'];
      this.bodyResetSenha.token = params['token'];
      this.bodyResetSenha.email = params['email'];
    });
    this.loadingService.hide();
  }

  changeLang(lang: string) {
    this.translate.use(lang);
  }

  createValidateForm() {
    this.translateService.setDefaultLang('pt-br');
    this.validateForm = this.fb.group({
      password: [
        '',
        [
          Validators.required,
          Validators.pattern(
            '^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[@$!%*?&])[A-Za-z\\d@$!%*?&]{6,}$'
          ),
        ],
      ],
      confirmarSenha: ['', [Validators.required]],
    });
  }

  checkPasswordsMatch() {
    const password = this.validateForm.get('password')?.value;
    const confirmPassword = this.validateForm.get('confirmarSenha')?.value;
    return password === confirmPassword;
  }

  async submitForm() {
    this.confirmModal = true;
    if (this.validateForm.valid) {
      try {
        this.loadingService.show();
        const response = await this.authService.resetPassword({
          email: this.bodyResetSenha?.email,
          token: this.bodyResetSenha?.token,
          id: this.bodyResetSenha?.id,
          password: this.validateForm.get('password')?.value,
        });
        if (response) {
          this.confirmModal = true;
          this.loadingService.hide();
        }
        this.loadingService.hide();
      } catch (error: any) {
        if (error.error && error.error.status === 401) {
          this.loadingService.notify('Erro', 'E-mail inválido.');
          return;
        }
        this.loadingService.hide();
        return;
      }
    } else {
      Object.values(this.validateForm.controls).forEach((control) => {
        if (control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });
        }
      });
    }
  }
}
