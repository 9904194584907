<div class="col-12 pt-8 lg:h-screen principal">
  <section class="result">
    <div class="backRouter p-5">
      <a class="vertical-align-baseline" [routerLink]="['/area-do-cliente']">
        <i class="pi pi-chevron-circle-left"> </i>
        <span>Voltar</span>
      </a>
    </div>

    <div class="p-5 pt-3 lg:col-6 flex flex-column mx-auto flex-auto">
      <div>
        <p class="texto">
          Bem-vindos à Estação do Olho, uma iniciativa pioneira que pretende
          revolucionar o cenário da saúde ocular no Brasil! Fundada pelos
          visionários oftalmologistas Gustavo Novais e Bruno Fernandes, a
          Estação do Olho emerge como um farol de esperança e inovação, com a
          missão de democratizar o acesso à saúde ocular de qualidade para todos
          os brasileiros.<br /><br />
          A origem da Estação do Olho reside no espírito empático e inovador de
          seus fundadores, que identificaram uma disparidade alarmante no acesso
          à saúde ocular no país. Diante de uma realidade onde uma grande
          parcela da população estava desprovida de cuidados oftalmológicos
          básicos, Gustavo e Bruno foram movidos pela convicção de que a saúde
          ocular é um direito de todos, e não um privilégio de poucos.
        </p>
      </div>
      <div class="pt-6 mx-auto">
        <a href="https://estacaodoolho.com.br/quem-somos/">
          <p-button
            label="Clique aqui e conheça mais sobre nós"
            [outlined]="true"
            [rounded]="true"
            severity="secondary"
            styleClass="p-button-outlined p-button-strong w-full lg:w-auto"
            (onClick)="loading()"
          ></p-button>
        </a>
      </div>
    </div>
  </section>
</div>
