import { Injectable } from '@angular/core';

@Injectable()
export class ProductService {
  getProductsData() {
    return [
      {
        id: '1000',
        code: 'f230fh0g3',
        name: 'Bamboo Watch',
        description: 'Product Description',
        image: 'bamboo-watch.jpg',
        price: 65,
        category: 'Accessories',
        quantity: 24,
        inventoryStatus: 'INSTOCK',
        rating: 5,
      },
      {
        id: '1001',
        code: 'nvklal433',
        name: 'Black Watch',
        description: 'Product Description',
        image: 'black-watch.jpg',
        price: 72,
        category: 'Accessories',
        quantity: 61,
        inventoryStatus: 'OUTOFSTOCK',
        rating: 4,
      },
      {
        id: '1002',
        code: 'zz21cz3c1',
        name: 'Blue Band',
        description: 'Product Description',
        image: 'blue-band.jpg',
        price: 79,
        category: 'Fitness',
        quantity: 2,
        inventoryStatus: 'LOWSTOCK',
        rating: 3,
      },
      {
        id: '1003',
        code: '244wgerg2',
        name: 'Blue T-Shirt',
        description: 'Product Description',
        image: 'blue-t-shirt.jpg',
        price: 29,
        category: 'Clothing',
        quantity: 25,
        inventoryStatus: 'INSTOCK',
        rating: 5,
      },
      {
        id: '1004',
        code: 'h456wer53',
        name: 'Bracelet',
        description: 'Product Description',
        image: 'bracelet.jpg',
        price: 15,
        category: 'Accessories',
        quantity: 73,
        inventoryStatus: 'INSTOCK',
        rating: 4,
      },
    ];
  }

  getVouchersData() {
    return [
      {
        title: 'Consulta',
        description: 'Utilizado para consultas, consetetur sadipscing elitr.',
        value: 'R$00,00',
        validate: 'dd/mm/aaaa',
      },
    ];
  }

  getProductsWithOrdersData() {
    return [
      {
        id: '1000',
        code: 'f230fh0g3',
        name: 'Bamboo Watch',
        description: 'Product Description',
        image: 'bamboo-watch.jpg',
        price: 65,
        category: 'Accessories',
        quantity: 24,
        inventoryStatus: 'INSTOCK',
        rating: 5,
        orders: [
          {
            id: '1000-0',
            productCode: 'f230fh0g3',
            date: '2020-09-13',
            amount: 65,
            quantity: 1,
            customer: 'David James',
            status: 'PENDING',
          },
          {
            id: '1000-1',
            productCode: 'f230fh0g3',
            date: '2020-05-14',
            amount: 130,
            quantity: 2,
            customer: 'Leon Rodrigues',
            status: 'DELIVERED',
          },
          {
            id: '1000-2',
            productCode: 'f230fh0g3',
            date: '2019-01-04',
            amount: 65,
            quantity: 1,
            customer: 'Juan Alejandro',
            status: 'RETURNED',
          },
          {
            id: '1000-3',
            productCode: 'f230fh0g3',
            date: '2020-09-13',
            amount: 195,
            quantity: 3,
            customer: 'Claire Morrow',
            status: 'CANCELLED',
          },
        ],
      },
      {
        id: '1001',
        code: 'nvklal433',
        name: 'Black Watch',
        description: 'Product Description',
        image: 'black-watch.jpg',
        price: 72,
        category: 'Accessories',
        quantity: 61,
        inventoryStatus: 'INSTOCK',
        rating: 4,
        orders: [
          {
            id: '1001-0',
            productCode: 'nvklal433',
            date: '2020-05-14',
            amount: 72,
            quantity: 1,
            customer: 'Maisha Jefferson',
            status: 'DELIVERED',
          },
          {
            id: '1001-1',
            productCode: 'nvklal433',
            date: '2020-02-28',
            amount: 144,
            quantity: 2,
            customer: 'Octavia Murillo',
            status: 'PENDING',
          },
        ],
      },
    ];
  }

  getCoupons() {
    return [
      {
        id: 'TG34BGU2HQ',
        voucherType: {
          id: 'TG34BGU2HQ',
          name: 'Dinâmico',
          normalizedName: 'DINÂMICO',
        },
        category: {
          id: 'fe368912-1290-4ed1-ac77-3937d603018e',
          name: 'Médicos',
          normalizedName: 'MÉDICOS',
          urlImage:
            'https://estacaodoolhodev.blob.core.windows.net/dev/im_sem_imagem.png',
          createdAt: '2024-04-05T15:25:18.2223662',
          updatedAt: '2024-04-05T15:25:18.2223663',
        },
        partner: null,
        value: 20.0,
        valueServiceVoucher: 35.0,
        validity: 6,
        serviceType: 'Serviço do tipo Médico Sem parceiro.',
        description: 'Descrição completa do cupom com os detalhes de uso.',
        utilizationRules: 'Descrição completa da utilização do cupom.',
        cancelRules: 'Descrição completa da utilização do cupom.',
        active: true,
        createdAt: '2024-04-08T18:55:34.9493297',
        updatedAt: '2024-04-08T18:55:34.9493303',
      },
      {
        id: 'TG34BGU2HQ',
        voucherType: {
          id: 'TG34BGU2HQ',
          name: 'Consulta',
          normalizedName: 'CONSULTA',
        },
        category: {
          id: 'fe368912-1290-4ed1-ac77-3937d603018e',
          name: 'Ótica',
          normalizedName: 'ÓTICA',
          urlImage:
            '/assets/img/icons/ic_otica.png',
          createdAt: '2024-04-05T15:25:18.2223662',
          updatedAt: '2024-04-05T15:25:18.2223663',
        },
        partner: null,
        value: 20.0,
        valueServiceVoucher: 35.0,
        validity: 6,
        serviceType: 'Serviço do tipo Médico Sem parceiro.',
        description: 'Descrição completa do cupom com os detalhes de uso.',
        utilizationRules: 'Descrição completa da utilização do cupom.',
        cancelRules: 'Descrição completa da utilização do cupom.',
        active: true,
        createdAt: '2024-04-08T18:55:34.9493297',
        updatedAt: '2024-04-08T18:55:34.9493303',
      },
    ];
  }

  getCupons() {
    return Promise.resolve(this.getCoupons());
  }

  getProductsMini() {
    return Promise.resolve(this.getProductsData().slice(0, 5));
  }

  getProductsSmall() {
    return Promise.resolve(this.getProductsData().slice(0, 10));
  }

  getVouchers() {
    return Promise.resolve(this.getVouchersData().slice(0, 10));
  }

  getProducts() {
    return Promise.resolve(this.getProductsData());
  }

  getProductsWithOrdersSmall() {
    return Promise.resolve(this.getProductsWithOrdersData().slice(0, 10));
  }

  getProductsWithOrders() {
    return Promise.resolve(this.getProductsWithOrdersData());
  }
}
