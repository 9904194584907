import { MyCouponService } from './../../services/my-coupon.service';
import { AnamneseService } from './../../services/anamnese.service';
import { Subscription } from 'rxjs';
import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { Router, RouterLink } from '@angular/router';

// PrimeNG
import { ButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card';
import { InputNumberModule } from 'primeng/inputnumber';
import { RegisterComponent } from '../register/register.component';
import { IncomeComponent } from '../register/income/income.component';
import { AnamneseComponent } from '../register/anamnese/anamnese.component';
import { AddressComponent } from '../register/address/address.component';
import { RadioButtonModule } from 'primeng/radiobutton';
import { LoadingService } from '../../services/loading.service';
import { AddressService } from '../../services/address.service';
import { InputMask, InputMaskModule } from 'primeng/inputmask';
import { CustomerService } from '../../services/customer.service';
import { DropdownModule } from 'primeng/dropdown';
import { DialogModule } from 'primeng/dialog';
import { AvatarModule } from 'primeng/avatar';
import { AvatarGroupModule } from 'primeng/avatargroup';
import { AuthService } from '../../services/auth.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-my-account',
  standalone: true,
  imports: [
    ButtonModule,
    CardModule,
    InputNumberModule,
    FormsModule,
    CommonModule,
    RouterLink,
    RegisterComponent,
    IncomeComponent,
    AnamneseComponent,
    AddressComponent,
    FormsModule,
    ReactiveFormsModule,
    RadioButtonModule,
    InputMaskModule,
    DropdownModule,
    DialogModule,
    AvatarModule,
    AvatarGroupModule,
  ],
  templateUrl: './my-account.component.html',
  styleUrl: './my-account.component.scss',
})
export class MyAccountComponent {
  value1: number = 0;
  isMobile: boolean = false;
  isDesktop: boolean;
  arrColors: string[] = ['#ECEEF1', '#E4E6E7', '#78CDD1'];
  iconOpenClosed: string = 'pi pi-angle-right';
  isEdit: boolean = false;

  formDadosPessoais: FormGroup;
  formEndereco: FormGroup;
  formComplementares: FormGroup;
  subscriptions: any = [];
  demography: any = {};
  etnias: any = [];
  sexos: any = [];
  isSubscribed: boolean = false;
  user: any = {};
  confirmModal: boolean = false;
  modalSolicitacao: boolean = false;
  modalDesistencia: boolean = false;
  endereco: any = {};
  anamnese: any = [];
  answerAnamnese: any = [];
  cancelButtonClick: boolean = true
  labelCancelSubs: string = 'Cancelar assinatura';

  plan:any = []

  salary = [
    { salario: 'Até R$ 1.000,00', value: 1 },
    { salario: 'Até R$ 3.000,00', value: 2 },
    { salario: 'Até R$ 5.000,00', value: 3 },
    { salario: 'Acima de R$ 5.000,00', value: 4 },
  ];

  radioForm: any = [
    { name: 'Sim', value: true },
    { name: 'Não', value: false },
  ];

  sections: any = [
    {
      id: 0,
      isOpen: false,
      title: 'Dados Pessoais',
      icon: 'pi pi-angle-right',
      routerLink: '/cadastro',
      nameApp: 'app-register',
    },
    {
      id: 1,
      isOpen: false,
      title: 'Endereço',
      icon: 'pi pi-angle-right',
      routerLink: '/endereco',
      nameApp: 'app-address',
    },
    {
      id: 2,
      isOpen: false,
      title: 'Dados Complementares',
      icon: 'pi pi-angle-right',
      routerLink: '/dados-complementares',
      nameApp: 'app-income',
    },
    {
      id: 3,
      isOpen: false,
      title: 'Anamnese',
      icon: 'pi pi-angle-right',
      routerLink: '/anamnese',
      nameApp: 'app-anamnese',
    },
  ];

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private loadingService: LoadingService,
    private addressService: AddressService,
    private customerService: CustomerService,
    private authService: AuthService,
    private anamneseService: AnamneseService,
    private location: Location,
    private myCoupon : MyCouponService
  ) {
    this.createValidateForm();
    if (typeof window !== 'undefined') {
      this.checkWidth();
      window.addEventListener('resize', () => {
        this.checkWidth();
      });
    }
  }

  async ngOnInit() {
    this.loadingService.show();
    const sub = await this.getSubs();
    if (sub.length > 0) {
      this.isSubscribed = true;
      this.subscriptions = sub[0];
      this.labelCancelSubs = this.verifyCancelRequest(
        this.subscriptions?.statusCancel
      );
    }
    if (!this.isMobile) {
      this.user = await this.getLoggedUser();
      this.formDadosPessoais.patchValue(this.user);

      this.formDadosPessoais.patchValue({
        birthDate: this.formatDate(this.user.birthDate),
      });
      this.etnias = await this.getEtnias();
      this.sexos = await this.getListaSexos();
      this.anamnese = await this.getAnamnese();
      this.plan = await this.getInsuranceList();
      if(this.anamnese){
        this.onSelectAnamneseAnswer()
      }
      this.demography = await this.customerService.getCustomerDemographics();
      this.formComplementares.patchValue(this.demography[0]);
      this.formComplementares.patchValue({healthInsuranceId: this.demography[0].healthInsurance?.id});
      try {
        this.endereco = await this.getCustomerAddress();
        if (this.endereco) {
          this.formEndereco.patchValue(this.endereco[0]);
        } else {
          this.isEdit = false;
        }
      } catch (error) {
        console.error('Erro ao obter o endereço do cliente:', error);
      } finally {
        this.loadingService.hide();
      }
    }
    this.loadingService.hide();
  }

  goBack(): void {
    this.location.back();
  }

  onSelectAnamneseAnswer() {
    // for (let i = 0; i < this.anamnese.length; i++) {
    //   for (let j = 0; j < this.anamnese[i].itemAnamnesis.multipleChoiceTextsArray.length; j++) {
    //     if(this.anamnese[i].itemAnamnesis.multipleChoiceTextsArray[j] == this.anamnese[i].answer){
    //       this.answerAnamnese.push(this.anamnese[i].itemAnamnesis.multipleChoiceTextsArray[j])
    //     }
    //   }
    // }
    console.log(this.answerAnamnese);
  }

  createValidateForm() {
    this.formDadosPessoais = this.fb.group({
      name: ['', [Validators.required]],
      email: [
        JSON.parse(sessionStorage.getItem('user') || '{}').email,
        [Validators.required],
      ],
      phoneNumber: ['', [Validators.required]],
      birthDate: ['', [Validators.required]],
      cpf: ['', [Validators.required]],
    });
    this.formDadosPessoais.get('email')?.disable();
    this.formDadosPessoais.get('cpf')?.disable();
    const email = JSON.parse(sessionStorage.getItem('user') || '{}').email;
    this.formDadosPessoais.patchValue({ email: email });

    this.formEndereco = this.fb.group({
      zipCode: ['', [Validators.required]],
      street: ['', [Validators.required]],
      number: ['', [Validators.required]],
      complement: ['', [Validators.required]],
      neighborhood: ['', [Validators.required]],
      city: ['', [Validators.required]],
      state: ['', [Validators.required]],
    });

    this.formComplementares = this.fb.group({
      sex: ['', [Validators.required]],
      ethnicity: ['', [Validators.required]],
      householdIncome: ['', [Validators.required]],
      hasHealthInsurance: [false, [Validators.required]],
      healthInsuranceId: ['', [Validators.required]],
    });
  }

  handleOpenSection(id: number) {
    if (this.isMobile || id === 3) {
      this.router.navigate(['/editar' + this.sections[id].routerLink]);
    } else {
      if (this.sections[id].isOpen) {
        this.sections[id].icon = 'pi pi-angle-right';
        this.sections[id].isOpen = false;
      } else {
        this.sections[id].icon = 'pi pi-angle-down';
        this.sections[id].isOpen = true;
      }
    }
  }

  async submitFormRegister() {
    try {
      this.loadingService.show();
      this.formDadosPessoais.get('email')?.enable();
      this.formDadosPessoais.get('cpf')?.enable();
      let obj = this.formDadosPessoais.value;
      obj.birthDate = this.unformatDate(obj.birthDate);
      await this.authService
        .putUserInfo(obj, this.user?.id)
        .then((resp) => {
          this.loadingService.hide();
          this.loadingService.notify('Dados pessoais atualizado com sucesso!');
        })
        .catch((error) => {
          this.loadingService.notify('Erro ao atualizar', 'Erro interno');
          this.loadingService.hide();
        });
    } catch (error) {
      this.loadingService.hide();
      console.log(error);
    }
  }

  async SubmitFormAddress() {
    try {
      this.loadingService.show();
      await this.addressService
        .putCustomerAddress(this.formEndereco.value, this.endereco[0].id)
        .then((resp) => {
          this.loadingService.hide();
          this.loadingService.notify('Endereço atualizado com sucesso!');
        })
        .catch((error) => {
          this.loadingService.notify('Erro ao atualizar', 'Erro interno');
          this.loadingService.hide();
        });
    } catch (error) {
      this.loadingService.hide();
      console.log(error);
    }
  }

  async submitFormIncome() {
    try {
      this.loadingService.show();
      this.customerService
        .putCustomerDemographics(
          {
            ...this.formComplementares.value,
            customerId: JSON.parse(sessionStorage.getItem('user') || '{}')?.id,
          },
          this.demography[0].id
        )
        .then((resp) => {
          this.loadingService.notify(
            'Dados complementares atualizados com sucesso!'
          );
          this.loadingService.hide();
        })
        .catch((error) => {
          this.loadingService.hide();
          this.loadingService.notify('Erro ao atualizar', error);
        });
    } catch (error) {}
    this.loadingService.hide();
  }

  checkWidth() {
    if (typeof window !== 'undefined') {
      this.isMobile = window.innerWidth < 992;
    }
  }

  checkIfMobile() {
    const screenWidth = window.innerWidth;
    this.isMobile = screenWidth <= 768;
    this.isDesktop = !this.isMobile;
  }

  formatValue(value: number) {
    return value.toLocaleString('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    });
  }


  verifyCancelRequest(statusCancel: string | undefined) {
    if (statusCancel === 'Requerido') {
      this.cancelButtonClick = false;
      return 'Solicitação de cancelamento enviada';
    } else if (statusCancel === 'Deferido') {
      this.cancelButtonClick = false;
      return 'Assinatura cancelado';
    } else if (statusCancel === 'Indeferido') {
      this.cancelButtonClick = true;
      return 'Solicitação de cancelamento negada';
    }
    this.cancelButtonClick = true;
    return 'Cancelar Assinatura';
  }



  async getCustomerAddress() {
    try {
      return await this.addressService.getCustomerAddress();
    } catch (error) {
      console.log(error);
    }
  }

  async getEtnias() {
    try {
      return await this.customerService.getAllEtnias();
    } catch (error) {
      this.loadingService.notify('Erro ao obter as etnias');
      this.loadingService.hide();
    }
  }

  async getAnamnese() {
    try {
      return await this.anamneseService.getActiveAnamnesis();
    } catch (error) {
      this.loadingService.notify('Erro ao obter a anamnese');
      this.loadingService.hide();
    }
  }

  async getSubs() {
    try {
      return await this.customerService.getSubscriptions();
    } catch (error) {
      this.loadingService.hide();
    }
  }

  async getLoggedUser() {
    try {
      return await this.customerService.getCustomerId();
    } catch (error) {
      console.log(error);
    }
  }

  async getIncome() {
    try {
      return await this.customerService.getCustomerDemographics();
    } catch (error) {
      this.loadingService.notify('Erro ao obter a renda do cliente');
      this.loadingService.hide();
    }
  }

  async getListaSexos() {
    try {
      return await this.customerService.getSexos();
    } catch (error) {
      this.loadingService.notify('Erro ao obter os sexos');
      this.loadingService.hide();
    }
  }

  unformatDate(date: string) {
    const dateArray = date.split('/');
    return `${dateArray[2]}-${dateArray[1]}-${dateArray[0]}T00:00:00`;
  }

  formatDate(date: string) {
    const dateObj = new Date(date);
    const day = dateObj.getDate().toString().padStart(2, '0');
    const month = (dateObj.getMonth() + 1).toString().padStart(2, '0');
    const year = dateObj.getFullYear();
    const formattedDate = `${day}/${month}/${year}`;
    return formattedDate;
  }

  dayToDate(day: number): string {
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth() + 1;
    const currentYear = currentDate.getFullYear();
    const formattedDate = `${day.toString().padStart(2, '0')}/${currentMonth
      .toString()
      .padStart(2, '0')}/${currentYear}`;

    return formattedDate;
  }

  cancelar() {
    this.confirmModal = false;
  }

  closeAllModals() {
    this.modalSolicitacao = false;
    this.confirmModal = false;
    this.modalDesistencia = false;
  }

  async getInsuranceList() {
    try {
      return await this.customerService.getInsuranceList();
    } catch (error) {
      console.log(error);
    }
  }

  async getEnderecoCEP() {
    let cep = this.formEndereco.get('zipCode')?.value;

    const res: any = await this.addressService.cep(cep).toPromise();
    this.formEndereco.patchValue({
      state: res?.uf,
      city: res?.localidade,
      street: res?.logradouro,
      neighborhood: res?.bairro,
    });
  }

  createCancelBody() {
    return {
      idCustomer: this.user.id,
      customerVoucherId: null,
      customerSubscriptionId: this.subscriptions.id,
      statusId: "5e97b5d4-3c70-4aea-a16b-3de319ae430b",
      createdAt: new Date(),
      updatedAt: new Date(),
    };
  }

  async cancelarAssinatura(){
    try {
      const body = this.createCancelBody();
      const resp = await this.cancelCupom(body);
      if (resp) {
        this.modalSolicitacao = true;
        this.cancelButtonClick = false;
        this.labelCancelSubs = 'Solicitação de cancelamento enviada';
      }
    } catch (error) {
    }
  }

  async cancelCupom(body:any) {
    try {
      return await this.myCoupon.cancelCupomRequest(body);
    } catch (error) {
      this.loadingService.notify('Erro ao processar pagamento');
    }
  }

}
